/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  Radio,
  InputLabel,
  Typography
  // Checkbox,
  // FormGroup
} from '@mui/material';

import InputMask from 'react-input-mask';
import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

const addEditDeclarationHealth = (props) => {
  const [infosQuestion, setInfosQuestion] = useState({});
  const [responseQuestions, setResponseQuestions] = useState({});
  const [tempResponseQuestions, setTempResponseQuestions] = useState({});
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  let [mask, setMask] = useState('9.9');

  useEffect(() => {
    setInfosQuestion(props.infosAskQuestions ? props.infosAskQuestions : {});
  }, [props.rowSelected]);

  useEffect(() => {
    setResponseQuestions(props.responseQuestions ? props.responseQuestions : {});
  }, [props.responseQuestions]);

  function HandleInputChange(event, element, index) {
    if (element) {
      if (element.tpresposta === 'Peso') {
        let { value } = event.target;

        if (value.length === 2) {
          setMask('9.9');
        }
        if (value.length === 3) {
          setMask('9.99');
        }
        if (value.length === 4) {
          setMask('99.99');
        }
        if (value.length === 5) {
          setMask('999.9');
        }
        setWeight(event.target.value);
      }
      if (element.tpresposta === 'Altura') {
        setHeight(event.target.value);
      }
    }
    if (event.target.value === 'Sim' && event.nativeEvent.inputType !== 'insertText') {
      setTempResponseQuestions({
        ...tempResponseQuestions,
        [`${index + 1}-${event.target.name}`]: event.target.value
      });
      setResponseQuestions({
        ...responseQuestions,
        [`${index + 1}-${event.target.name}`]: ''
      });
      return;
    }
    if (event.target.value === 'Não') {
      setTempResponseQuestions({
        ...tempResponseQuestions,
        [`${index + 1}-${event.target.name}`]: ''
      });
    }
    setResponseQuestions({
      ...responseQuestions,
      [`${index + 1}-${event.target.name}`]: event.target.value
    });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    let degreeOfObesity;
    const imc = Number(weight) / (Number(height) * Number(height));
    if (imc < 30) {
      degreeOfObesity = 'Não';
    } else if (imc >= 30 && imc <= 34.9) {
      degreeOfObesity = 'Obesidade grau I';
    } else if (imc >= 35 && imc <= 39.9) {
      degreeOfObesity = 'Obesidade grau II';
    } else {
      degreeOfObesity = 'Obesidade grau III';
    }
    const lastKey = Object.keys(responseQuestions).length;
    const declaration = {
      ...responseQuestions,
      [`${lastKey + 1}-Possui algum grau de obesidade?`]: degreeOfObesity
    };
    props.rowSelected.controlamudancarencia = props.controlaMudancaCarencia;
    const infosDeclaration = {
      declaration: declaration,
      client: props.rowSelected
    };
    configAxios
      .post('/questions/addResponseDeclarationHealth', infosDeclaration)
      .then(() => {
        props.setOpenModal(false);
        setResponseQuestions({});
        setTempResponseQuestions({});
        props.functionGrid();
        NotificationManager.success('Declaração de saúde preenchida com sucesso!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para preencher a declaração de saúde, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleValidatedHeigth(event, element, index) {
    if (event.target.value) {
      if (event.target.value.length < 4) {
        NotificationManager.warning('A altura deve conter pelo menos 3 números!', 'Atenção');
        setResponseQuestions({
          ...responseQuestions,
          [`${index + 1}-${event.target.name}`]: ''
        });
        return;
      }
      if (height) {
        if (weight < 10 && event.target.value > 1) {
          NotificationManager.warning('Peso inválido para altura informada, verifique!', 'Atenção');
          setWeight();
          setResponseQuestions({
            ...responseQuestions,
            [`${index + 1}-${event.target.name}`]: ''
          });
          return;
        }
      }
    }
  }

  function HandleSetWeigth(event, element, index) {
    if (event.target.value) {
      // if (height) {
      if (height > 1 && event.target.value < 10) {
        NotificationManager.warning('Peso inválido para altura informada, verifique!', 'Atenção');
        setWeight();
        setResponseQuestions({
          ...responseQuestions,
          [`${index + 1}-${event.target.name}`]: ''
        });
        return;
      }
      // }
      setWeight(event.target.value);
      setResponseQuestions({
        ...responseQuestions,
        [`${index + 1}-${event.target.name}`]: event.target.value
      });
      return;
    }
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            setResponseQuestions({});
            setTempResponseQuestions({});
            props.setShowDeclaration(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          PaperProps={{
            sx: { borderRadius: 0 }
          }}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              setResponseQuestions({});
              setTempResponseQuestions({});
              props.setShowDeclaration(false);
              props.functionGrid();
            }}>
            {!props.showDeclaration && 'Preencher declaração de saúde'}
            {props.showDeclaration && 'Visualizar declaração de saúde'}
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              {!props.showDeclaration && (
                <CustomInfosForm>Perguntas obrigatórias possuem asterisco (*)</CustomInfosForm>
              )}

              {props.showDeclaration && (
                <CustomInfosForm>
                  Confira abaixo as respostas preenchidas na declaração de saúde do beneficiário:
                </CustomInfosForm>
              )}
              <Grid container spacing={2} sx={{ mb: 1, mt: 0.5 }}>
                {!props.showDeclaration &&
                  props.infosAskQuestions.map((element, index) => {
                    if (element.tpresposta === 'Boleana') {
                      return (
                        <Grid item xs={12} md={12} key={index} sx={{ ml: 0.5 }}>
                          <Typography id="label" sx={{ fontSize: '12px' }}>
                            {element.pergunta} *
                          </Typography>
                          <FormControl component="fieldset" variant="standard">
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    tempResponseQuestions[`${index + 1}-${element.pergunta}`] ===
                                    'Sim'
                                  }
                                  onChange={(event) => {
                                    HandleInputChange(event, null, index);
                                  }}
                                  value="Sim"
                                  required
                                  size="small"
                                  name={element.pergunta}
                                />
                              }
                              label="Sim"
                            />
                          </FormControl>
                          <FormControl component="fieldset" variant="standard">
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    responseQuestions[`${index + 1}-${element.pergunta}`] === 'Não'
                                  }
                                  onChange={(event) => {
                                    HandleInputChange(event, null, index);
                                  }}
                                  value="Não"
                                  required
                                  size="small"
                                  name={element.pergunta}
                                />
                              }
                              label="Não"
                            />
                          </FormControl>
                          {tempResponseQuestions[`${index + 1}-${element.pergunta}`] === 'Sim' && (
                            <Grid item xs={12} md={12} key={index} sx={{ mt: 2, ml: -1 }}>
                              <TextField
                                name={element.pergunta}
                                fullWidth
                                variant="outlined"
                                size="small"
                                required
                                multiline
                                placeholder="Por favor, descreva-os"
                                value={responseQuestions[`${index + 1}-${element.pergunta}`] || ''}
                                onChange={(event) => HandleInputChange(event, null, index)}
                              />
                            </Grid>
                          )}
                        </Grid>
                      );
                    }
                    if (element.tpresposta === 'Altura') {
                      return (
                        <Grid item xs={12} md={12} key={index}>
                          <InputLabel id="label" sx={{ fontSize: '13px' }}>
                            {element.pergunta} *
                          </InputLabel>
                          <InputMask
                            mask="9.99"
                            value={responseQuestions[`${index + 1}-${element.pergunta}`] || ''}
                            onChange={(event) => HandleInputChange(event, element, index)}
                            onBlur={(event) => HandleValidatedHeigth(event, element, index)}
                            maskChar="">
                            {() => (
                              <TextField
                                name={element.pergunta}
                                fullWidth
                                variant="outlined"
                                size="small"
                                required
                                placeholder="Digite sua resposta..."
                              />
                            )}
                          </InputMask>
                        </Grid>
                      );
                    }
                    if (element.tpresposta === 'Peso') {
                      return (
                        <Grid item xs={12} md={12} key={index}>
                          <InputLabel id="label" sx={{ fontSize: '13px' }}>
                            {element.pergunta} *
                          </InputLabel>
                          <InputMask
                            mask={mask}
                            value={responseQuestions[`${index + 1}-${element.pergunta}`] || ''}
                            onChange={(event) => HandleInputChange(event, element, index)}
                            onBlur={(event) => HandleSetWeigth(event, element, index)}
                            maskChar="">
                            {() => (
                              <TextField
                                name={element.pergunta}
                                fullWidth
                                variant="outlined"
                                size="small"
                                required
                                placeholder="Digite sua resposta..."
                              />
                            )}
                          </InputMask>
                        </Grid>
                      );
                    }
                    return (
                      <Grid item xs={12} md={12} key={index}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          {element.pergunta} *
                        </InputLabel>
                        <TextField
                          name={element.pergunta}
                          fullWidth
                          variant="outlined"
                          size="small"
                          required
                          placeholder="Digite sua resposta..."
                          value={responseQuestions[`${index + 1}-${element.pergunta}`] || ''}
                          onChange={(event) => HandleInputChange(event, element, index)}
                        />
                      </Grid>
                    );
                  })}
                {props.showDeclaration &&
                  props.responseQuestions.map((element, index) => {
                    return (
                      <Grid item xs={12} md={12} key={index} sx={{ flexWrap: 'wrap' }}>
                        <Typography id="label" sx={{ fontSize: '12px' }}>
                          {element.pergunta}
                        </Typography>
                        <Typography variant="subtitle3">R: {element.resposta}</Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            </DialogContent>
            {!props.showDeclaration && (
              <DialogActions>
                <Button
                  color="success"
                  variant="contained"
                  type="submit"
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosQuestion.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  onClick={() => {
                    props.setOpenModal(false);
                    props.functionGrid();
                    setResponseQuestions({});
                    setTempResponseQuestions({});
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </DialogActions>
            )}
            {props.showDeclaration && (
              <DialogActions>
                <Button
                  onClick={() => {
                    props.setOpenModal(false);
                    props.functionGrid();
                    props.setShowDeclaration(false);
                    setResponseQuestions({});
                    setTempResponseQuestions({});
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Fechar
                </Button>
              </DialogActions>
            )}
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default addEditDeclarationHealth;
