export function getDifferenceByArrayAndObjects(array1, array2) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return (
        object1.mesref == object2.mesref &&
        (object1.faixa1 === object2.faixa1 || object1.faixa2 === object2.faixa2)
      );
    });
  });
}

export function getDifferenceByArrayAndObjectsProfiles(array1, array2) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.name === object2.name;
    });
  });
}
