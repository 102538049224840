import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import loginReducer from './login/loginSlice';
import loadingReducer from './loading/loadingSlice';
import clientsReducer from './clients/clientsSlice';

const rootReducer = combineReducers({
  loading: loadingReducer,
  login: loginReducer,
  clients: clientsReducer
});

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['loading']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});
