import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import Picture from '../../../assets/images/multiples/logo.png';

Logo.propTypes = {
  disabledLink: PropTypes.bool
};

export default function Logo({ disabledLink = false }) {
  const img = Picture;
  const isLogoTemp = useMemo(() => img, []);
  const isLogo = <Box component="img" sx={{ width: '68%', ml: 4, mt: 1 }} src={`${isLogoTemp}`} />;
  const logo = isLogo;
  if (disabledLink) {
    return <>{logo}</>;
  }
  return <RouterLink to="/app/dashboard">{logo}</RouterLink>;
}
