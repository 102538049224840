/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { BootstrapDialog } from '../../../assets/style/globalStyle';
import { IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import PdfViewer from './PdfViewer';

const ShowDocuments = (props) => {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          PaperProps={{
            sx: { borderRadius: 0 }
          }}
          maxWidth={'md'}
          scroll="paper"
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
            }}>
            {props.nome ? props.nome : 'Visualizar documento'}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <PdfViewer path={props.url} />
          </DialogContent>
          <DialogActions sx={{ p: 5 }}></DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ShowDocuments;
