/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { StyledMenu, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import ShowDocuments from '../../../components/multiplesComponents/ShowDocuments';

const downloadDocumentsSigning = (props) => {
  const [OpenModalShowDocument, setOpenModalShowDocument] = useState(false);
  const user = useSelector((state) => state.login.authenticatedUser);
  let tpbenef = useSelector((state) => state.login.type);

  const [pdf, setpdf] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    getDocumentsForDownload();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getDocumentsForDownload() {
    const titbenef = user.userLoggedIn[0].titbenef ? user.userLoggedIn[0].titbenef : 'undefined';
    const id_dep = user.userLoggedIn[0].id;
    const id_client = user.userLoggedIn[0].id_client;
    const codplano = user.userLoggedIn[0].codplano;
    const tpcontrat = user.userLoggedIn[0].tpcontrat;

    if (tpcontrat) {
      let clientForSignatureDocuments = {};

      clientForSignatureDocuments = {
        tpbenef,
        tpcontrat,
        id_dep,
        id_client,
        titbenef,
        codplano,
        included_resp: 'N'
      };

      if (user.userLoggedIn[0].cpf_cnpj === user.userLoggedIn[0].cpf_cnpj_resp) {
        clientForSignatureDocuments = {
          tpbenef,
          tpcontrat,
          id_dep,
          id_client,
          titbenef,
          codplano,
          included_resp: 'S'
        };
      }
      configAxios
        .post(`/signing/getDocumentsForDownload`, clientForSignatureDocuments)
        .then((result) => {
          setDocuments(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos para download, tente novamente!',
            'Atenção'
          );
        });
    } else {
      let clientForSignatureDocuments = {};

      clientForSignatureDocuments = {
        tpcontrat: props.infosClients.tpcontrat,
        id_client: props.infosClients.id,
        id_profile: user.userLoggedIn[0].id_profile,
        codplano: props.infosClients.codplano
      };

      configAxios
        .post(`/signing/getDocumentsForDownload`, clientForSignatureDocuments)
        .then((result) => {
          setDocuments(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos para download, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function handleDownloadDocument(id_doc) {
    setpdf();

    const download = documents.filter((element) => {
      if (element.id_doc === id_doc) {
        return element;
      }
    });

    const tipo = props.tipo;

    const codcontrato = props.infosClients.codcontrato_plano
      ? props.infosClients.codcontrato_plano
      : user.userLoggedIn[0].codcontrato_plano;

    configAxios
      .get(`/signing/downloadDocument/${download[0].id_doc}/${codcontrato}/${tipo}`, {
        responseType: 'blob'
      })
      .then((result) => {
        const blob = URL.createObjectURL(result.data);
        setpdf(blob);
        setOpenModalShowDocument(true);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para gerar o documento. Tente novamente!',
          'Atenção'
        );
      });
  }

  function RenderDocumentsForDownload() {
    let renderDocuments = [];
    let item;

    documents.map((element, key) => {
      item = (
        <div key={key}>
          <MenuItem disableRipple>
            <div>
              <CustomInfosForm onClick={() => handleDownloadDocument(element.id_doc)}>
                {element.nome}
              </CustomInfosForm>
            </div>
          </MenuItem>
        </div>
      );
      renderDocuments.push(item);
    });
    return renderDocuments;
  }

  return (
    <>
      <ShowDocuments
        openModal={OpenModalShowDocument}
        url={pdf}
        setOpenModal={setOpenModalShowDocument}
      />

      <Button
        id="customized-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="ligth"
        onClick={handleClick}
        sx={isMobile ? { textTransform: 'none', ml: 1 } : { ml: 1, textTransform: 'none' }}
        startIcon={
          <Iconify icon={props.formClient ? 'eva:cloud-download-outline' : 'eva:printer-outline'} />
        }
        endIcon={<KeyboardArrowDownIcon />}>
        {props.formClient ? 'Download' : 'Imprimir documentos'}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button'
        }}
        sx={{
          p: 0,
          mt: 1,
          width: 350
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}>
        <RenderDocumentsForDownload />
      </StyledMenu>
    </>
  );
};

export default downloadDocumentsSigning;
