import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ImageBackground from '../../assets/images/multiples/background.jpg';

const MainStyle = styled('div')(() => ({
  backgroundSize: '100% auto;',
  backgroundPosition: 'right top',
  backgroundAttachment: 'fixed',
  borderRadius: '5px'
}));

export default function LogoOnlyLayout() {
  const image = useMemo(() => ImageBackground, []);

  return (
    <MainStyle sx={{ backgroundImage: `url(${image})` }}>
      <Outlet />
    </MainStyle>
  );
}
