import React from 'react';
import { Grid } from '@mui/material';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Search() {
  const navigation = useNavigate();
  const permissions = useSelector((state) => state.login.permissions);

  const handleOnSelect = (item) => {
    navigation(item.path);
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left', cursor: 'pointer' }}>{item.name}</span>
      </>
    );
  };

  return (
    <>
      <Grid item xs={12} md={12} sx={{ p: 1 }}>
        <div style={{ width: 270 }}>
          <ReactSearchAutocomplete
            styling={{
              borderRadius: '24px',
              height: '42px',
              hoverBackgroundColor: '#F5F5F5'
            }}
            items={permissions}
            placeholder="Pesquisar..."
            onSelect={handleOnSelect}
            formatResult={formatResult}
          />
        </div>
      </Grid>
    </>
  );
}
