/* eslint-disable react/prop-types */
import React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

import ptBr from '@react-pdf-viewer/locales/lib/pt_PT.json';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function CustomizedDialogs(props) {
  const renderToolbar = (Toolbar) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar
  });

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      return props.name ? `${props.name.trim()}.pdf` : `document.pdf`;
    }
  });
  const { DownloadButton } = getFilePluginInstance;

  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    ShowPropertiesMenuItem: () => <></>,
    Download: () => (
      <>
        <DownloadButton />
      </>
    )
  });

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <div>
      <Viewer
        fileUrl={props.path}
        options={{ workerSrc: pdfjsWorker }}
        plugins={[defaultLayoutPluginInstance, getFilePluginInstance]}
        localization={ptBr}
      />
    </div>
  );
}
