import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  notification: false,
  client: []
};
export const clients = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    changeNotification: (state) => {
      state.notification = !state.notification;
    },
    client: (state, action) => {
      state.client = action.payload;
    }
  }
});
export const { changeNotification, client } = clients.actions;
export const selectNotification = (state) => state.clients.notification;
export const selectNotificationClient = (state) => state.clients.client;
export default clients.reducer;
