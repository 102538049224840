import React, { useEffect } from 'react';
import { ContentStyle } from '../../../../assets/style/globalStyle';
import { Typography, Card, Stack, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../store/login/loginSlice';

import RenderPage from '../../../layouts/RenderPage';
import LoginForm from '../../../sections/auth/login';
import imglogo from '../../../../assets/images/multiples/logo.png';

export default function Login() {
  const logoOperator = imglogo;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <RenderPage title="Login">
      <ContentStyle>
        <Card variant="outlined" sx={{ padding: '10%', backgroundColor: '#2E2E2E' }}>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
            <Box component="img" src={`${logoOperator}`} />
          </Stack>
          <Typography sx={{ color: 'text.primary', mb: 2 }}>
            Utilize as suas credencias para acessar
          </Typography>
          <LoginForm />
        </Card>
      </ContentStyle>
    </RenderPage>
  );
}
