/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { login, permissions } from '../../../../store/login/loginSlice';
import { Stack, IconButton, InputAdornment, TextField, Button, Box } from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';

const LoginForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const [infosLogin, setInfosLogin] = useState({
    email: '',
    password: ''
  });

  function HandleSubmit(event) {
    event.preventDefault();
    configAxios
      .post('/auth/authUser', { ...infosLogin })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            dispatch(permissions(response.data.permissions));
            dispatch(login(response.data));
            NotificationManager.success(
              `Bem vindo(a), ${response.data.userLoggedIn[0].nome}, esteja pronto para explorar!`,
              'Sucesso'
            );
            navigate('/app/dashboard');
          } else {
            NotificationManager.warning(
              'Usuário ou senha inválidos, por favor, verifique!',
              'Atenção'
            );
          }
        } else {
          NotificationManager.warning(
            'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
            'Atenção'
          );
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event) {
    if (event.target.name === 'password') {
      event.target.value = event.target.value.trim();
    }
    setInfosLogin({ ...infosLogin, [event.target.name]: event.target.value });
  }
  return (
    <>
      {!props.urlClient && (
        <form onSubmit={HandleSubmit} className="formLogin">
          <Stack spacing={3}>
            <TextField
              name="email"
              id="email"
              label="Endereço de e-mail"
              onChange={(event) => HandleInputChange(event)}
              value={infosLogin.email}
              color="primary"
              type="email"
              required
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="password"
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              onChange={(event) => HandleInputChange(event)}
              value={infosLogin.password}
              inputProps={{ maxLength: 50 }}
              color="primary"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>
          <Box mt={3}>
            <Button
              id="login"
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
              type="submit"
              startIcon={<Iconify icon="eva:log-in-fill" />}>
              Fazer login
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};

export default LoginForm;
