import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { Stack, Typography, Button, Card, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { formatDate } from '../../../../@core/utils/utilsFunctions';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

const allowedPageSizes = [5, 10, 20, 30];

const Alerts = (props) => {
  const [infosWarnings, setInfosWarnings] = useState({});

  useEffect(() => {
    getWarnings();
  }, []);

  function getWarnings() {
    configAxios
      .get('/warnings/getWarnings')
      .then((response) => {
        setInfosWarnings(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar os avisos, tente novamente!',
          'Atenção'
        );
      });
  }

  function FormatDateInitial(infos) {
    let dateFormated = formatDate(infos.data.data.initial_date);
    return dateFormated;
  }

  function FormatDateEnd(infos) {
    let dateFormated = formatDate(infos.data.data.end_date);
    return dateFormated;
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/warnings/deleteWarning',
          deleteItem: 'o aviso',
          router: 'editWarning',
          functionGrid: getWarnings,
          otherInfos: item
        }}
      />
    ));
  }

  return (
    <RenderPage title="Avisos">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de avisos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar os avisos cadastrados
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/addWarning"
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Novo
            </Button>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getWarnings}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <DataGrid
              dataSource={infosWarnings}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              noDataText={'Não há avisos cadastrados'}>
              <Column dataField="id" />
              <Column dataField="direction" caption="Direção" />
              <Column dataField="warning" caption="Aviso" width={500} />
              <Column
                dataField="initial_date"
                caption="Data inicial"
                cellRender={(data) => <FormatDateInitial data={data} />}
              />
              <Column
                dataField="end_date"
                caption="Data final"
                cellRender={(data) => <FormatDateEnd data={data} />}
              />
              <Column
                dataField="Ações"
                width={100}
                allowSorting={false}
                cellRender={(data) => <ActionsButtons data={data} />}
              />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Alerts;
