const permissions = () => [
  {
    ordem: 0,
    name: 'Dashboard',
    path: '/app/dashboard',
    typeUser: 'parceiros'
  },
  {
    ordem: 1,
    name: 'Minha conta',
    path: '/app/myAccount',
    typeUser: 'parceiros'
  },
  {
    ordem: 2,
    name: 'Usuários',
    path: '/app/users',
    typeUser: 'parceiros'
  },
  {
    ordem: 3,
    name: 'Permissões padrões',
    path: '/app/permissions',
    typeUser: 'gestão'
  },
  {
    ordem: 4,
    name: 'Associados',
    path: '/app/clients',
    typeUser: 'gestão'
  },
  {
    ordem: 5,
    name: 'Parceiros',
    path: '/app/partners',
    typeUser: 'gestão'
  },
  {
    ordem: 6,
    name: 'Empresas',
    path: '/app/companies',
    typeUser: 'gestão'
  },
  {
    ordem: 7,
    name: 'Avisos',
    path: '/app/warnings',
    typeUser: 'gestão'
  },
  {
    ordem: 8,
    name: 'Formulários',
    path: '/app/forms',
    typeUser: 'gestão'
  },
  {
    ordem: 9,
    name: 'Produtos',
    path: '/app/products',
    typeUser: 'gestão'
  },
  {
    ordem: 10,
    name: 'Tabelas',
    path: '/app/tables',
    typeUser: 'gestão'
  },
  {
    ordem: 11,
    name: 'Reajustes',
    path: '/app/readjustments',
    typeUser: 'gestão'
  },
  {
    ordem: 12,
    name: 'Contas a Pagar',
    path: '/app/billsToPay',
    typeUser: 'gestão'
  },
  {
    ordem: 13,
    name: 'Contas a receber',
    path: '/app/billsToReceive',
    typeUser: 'gestão'
  },
  {
    ordem: 14,
    name: 'Competências',
    path: '/app/competence',
    typeUser: 'gestão'
  },
  {
    ordem: 15,
    name: 'Gerar Faturamento',
    path: '/app/generateBilling',
    typeUser: 'gestão'
  },
  {
    ordem: 16,
    name: 'Manutenção de Faturamento',
    path: '/app/maintenanceBilling',
    typeUser: 'gestão'
  },
  {
    ordem: 17,
    name: 'SAC',
    path: '/app/openSAC',
    typeUser: 'gestão'
  }
];

export default permissions;
