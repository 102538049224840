/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { getDifferenceByArrayAndObjects } from '../../../../@core/utils/tablesFunctions';
import { BootstrapDialog, CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

const CopyItems = (props) => {
  const [infosTableItems, setInfosTableItems] = useState({});
  const [InfosTableLabel, setInfosTableLabel] = useState([]);
  const [infosTableSelected, setInfosTableSelected] = useState({});
  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    getTablesLabel();
  }, []);

  function getTablesLabel() {
    configAxios
      .get('tables/getTablesLabel')
      .then((response) => {
        setInfosTableLabel(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function FormatDate(infos) {
    let str = infos.data.data.mesref.toString();
    let year = str.substring(0, 4).toString();
    let month = str.substring(4, 6).toString();
    let dateFormated = month + '/' + year;
    return dateFormated;
  }

  function HandleInputCodTab(event) {
    setInfosTableSelected({ codtab: event.codtab, label: event.label });
    getCopyItems(event.codtab);
  }

  function HandleInputChange(event) {
    if (event.target.value > 100) {
      NotificationManager.warning('Selecione um valor de reajuste até 100%', 'Atenção');
      return;
    }
    setInfosTableSelected({ ...infosTableSelected, [event.target.name]: event.target.value });
  }

  function getCopyItems(codtab) {
    configAxios
      .get(`/itemsValPlan/getCopyItems/${codtab}`)
      .then((response) => {
        setInfosTableItems(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os itens das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    let itemsCopy = [];
    let contentItemsTemp = [];
    let contentItems = [];
    let valuesInsertCopy = [];

    if (infosTableSelected.codtab === props.otherInfos.codtab) {
      NotificationManager.warning('Não é possível copiar os itens da própria tabela!', 'Atenção');
      return;
    }
    props.infosItensValPlan.filter((element) => {
      infosTableItems.filter((item) => {
        if (
          element.mesref === item.mesref &&
          (element.faixa1 === item.faixa1 || element.faixa2 === item.faixa2)
        ) {
          contentItemsTemp.push(element);
        }
      });
    });
    contentItems.push(getDifferenceByArrayAndObjects(infosTableItems, contentItemsTemp));
    itemsCopy = itemsCopy.concat(contentItems[0]);
    let uniqueItemsCopy = [...new Set(itemsCopy)];
    if (!contentItems.length || !uniqueItemsCopy.length) {
      NotificationManager.warning('Não encontramos itens para serem copiados!', 'Atenção');
      return;
    }
    if (infosTableSelected.readjustment) {
      uniqueItemsCopy.map((element) => {
        let valueReadjustment = (element.valor * infosTableSelected.readjustment) / 100;
        delete element.id;
        valuesInsertCopy.push({
          ...element,
          codtab: props.otherInfos.codtab,
          valor: parseFloat(valueReadjustment) + parseFloat(element.valor)
        });
      });
    } else {
      uniqueItemsCopy.map((element) => {
        delete element.id;
        valuesInsertCopy.push({
          ...element,
          codtab: props.otherInfos.codtab
        });
      });
    }
    configAxios
      .post('/itemsValPlan/insertCopyItems', { valuesInsertCopy })
      .then(() => {
        NotificationManager.success('Itens copiados com sucesso!', 'Sucesso');
        props.setOpenModal(false);
        props.functionGrid();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para copiar os itens, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            Copiar itens de outra tabela
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomInfosForm>
                Atenção só serão copiados itens que ainda não estiverem na tabela de destino
              </CustomInfosForm>
              <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={InfosTableLabel}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputCodTab(value)}
                    value={infosTableSelected.label || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione a tabela"
                        name="selectedTable"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="readjustment"
                    fullWidth
                    label="Valor de reajuste %"
                    helperText="Aplique um valor de reajuste na cópia caso necessário (opcional)"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={infosTableSelected.readjustment || ''}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
              </Grid>
              <CustomGrid sx={{ mt: 1 }}>
                <DataGrid
                  dataSource={infosTableItems}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}>
                  <Column dataField="id" caption="id" />
                  <Column dataField="codtab" caption="Código da tabela" />
                  <Column
                    dataField="mesref"
                    alignment="left"
                    cellRender={(data) => <FormatDate data={data} />}
                  />
                  <Column dataField="faixa1" caption="Idade inicial" alignment="left" />
                  <Column dataField="faixa2" caption="Idade final" alignment="left" />
                  <Column dataField="valor" caption="Valor R$" alignment="right" />
                  <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                  {!isMobile && (
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                  )}
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser
                    enabled={true}
                    title={'Escolher uma coluna'}
                    emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                  />
                  <ColumnFixing enabled={true} />
                  <HeaderFilter visible={true} allowSearch={true} />
                </DataGrid>
              </CustomGrid>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:copy-fill" />}>
                Copiar
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CopyItems;
