import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { Link as RouterLink } from 'react-router-dom';
import {
  CustomGrid,
  CustomInfosForm,
  TextareaAutosize
} from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { viaCep, validCep } from '../../../../@core/utils/utilsFunctions';
import { validateCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Tab,
  Alert
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import palette from '../../../../@core/theme/palette';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import InputMask from 'react-input-mask';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const Config = (props) => {
  const [infosConfig, setInfosConfig] = useState({});
  const [values, setValues] = useState('1');
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    getConfig();
  }, []);

  function getConfig() {
    configAxios
      .get('/config/getConfig')
      .then((response) => {
        setInfosConfig(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades buscar as configurações no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosConfig({ ...infosConfig, cnpj: '' });
        return;
      }
      setInfosConfig({ ...infosConfig, [event.target.name]: event.target.value });
    }
  }

  async function HandleUploadImage(event) {
    if (event) {
      const formData = new FormData();

      formData.append('file', event.target.files[0]);

      configAxios
        .post('/config/updateLogo', formData)
        .then(() => {
          NotificationManager.success('Imagem atualizada com sucesso!', 'Sucesso');
          getConfig();
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades atualizar a imagem, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function HandleInputCep(event) {
    if (event.target.value) {
      if (validCep(event.target.value)) {
        let infosCep;
        infosCep = await viaCep(event.target.value);
        if (infosCep) {
          HandleInputChange({ ...infosCep }, true);
          return;
        }
        NotificationManager.danger(
          'Tivemos dificuldades para buscar as informações do endereço, tente novamente!',
          'Atenção'
        );
      } else {
        NotificationManager.warning('Cep inválido, verifique e tente novamente!', 'Atenção');
      }
    }
  }

  function HandleInputChange(event, cep) {
    if (cep) {
      setInfosConfig({ ...infosConfig, ...event });
      return;
    }
    setInfosConfig({ ...infosConfig, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = '/config/updateConfig';
    configAxios
      .post(router, infosConfig)
      .then(() => {
        NotificationManager.success('Configurações atualizadas com sucesso!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function handleTabsChange(event, newValue) {
    setValues(newValue);
  }

  return (
    <RenderPage title="Configurações" id="configs">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Configurações gerais
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo editar as configurações e informações do sistema
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <form onSubmit={HandleSubmit}>
            <CustomGrid>
              <Box sx={{ width: '100%' }}>
                <TabContext value={values}>
                  <Box>
                    <TabList
                      onChange={handleTabsChange}
                      aria-label="ClientsTabs"
                      variant="scrollable"
                      scrollButtons="auto">
                      <Tab label="Configurações" value="1" />
                      <Tab label="Parâmetros" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Box sx={{ width: '100%', border: 1, borderColor: 'grey.200', p: 1 }}>
                      <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3.5}>
                          <TextField
                            name="razaosocial"
                            fullWidth
                            label="Razão social"
                            variant="outlined"
                            size="small"
                            required
                            value={infosConfig.razaosocial || ''}
                            onChange={(event) => HandleInputChange(event)}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3.5}>
                          <TextField
                            name="nomefantasia"
                            fullWidth
                            label="Nome fantasia"
                            variant="outlined"
                            size="small"
                            required
                            value={infosConfig.nomefantasia || ''}
                            onChange={(event) => HandleInputChange(event)}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={2.3}>
                          <InputMask
                            mask="99.999.999/0009-99"
                            value={infosConfig.cnpj || ''}
                            onChange={(event) => HandleInputChange(event)}
                            onBlur={(event) => validCNPJ(event)}>
                            {() => (
                              <TextField
                                name="cnpj"
                                fullWidth
                                label="CNPJ"
                                required
                                variant="outlined"
                                size="small"
                              />
                            )}
                          </InputMask>
                        </Grid>
                        <Grid item xs={12} md={2.7}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Regime tributário *
                          </InputLabel>
                          <Select
                            name="id_regime"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.id_regime || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'1'}>Micro empreendedor Individual</MenuItem>
                            <MenuItem value={'2'}>Simples Nacional</MenuItem>
                            <MenuItem value={'3'}>Lucro Presumido</MenuItem>
                            <MenuItem value={'4'}>Lucro Real</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                        <Grid item xs={12} md={2}>
                          <InputMask
                            mask="99999-999"
                            onChange={(event) => HandleInputChange(event)}
                            onBlur={(event) => HandleInputCep(event)}
                            value={infosConfig.cep || ''}>
                            {() => (
                              <TextField
                                name="cep"
                                fullWidth
                                label="CEP"
                                variant="outlined"
                                size="small"
                                required
                              />
                            )}
                          </InputMask>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            name="rua"
                            fullWidth
                            label="Rua"
                            disabled
                            required
                            size="small"
                            variant="outlined"
                            value={infosConfig.rua || ''}
                            onChange={(event) => HandleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            name="numero"
                            fullWidth
                            label="Número"
                            value={infosConfig.numero || ''}
                            required
                            size="small"
                            variant="outlined"
                            type="number"
                            onChange={(event) => HandleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            name="complemento"
                            fullWidth
                            label="Complemento"
                            size="small"
                            value={infosConfig.complemento || ''}
                            variant="outlined"
                            onChange={(event) => HandleInputChange(event)}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            name="bairro"
                            fullWidth
                            label="Bairro"
                            required
                            size="small"
                            variant="outlined"
                            value={infosConfig.bairro || ''}
                            inputProps={{ maxLength: 100 }}
                            onChange={(event) => HandleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            name="cidade"
                            fullWidth
                            label="Cidade"
                            disabled
                            variant="outlined"
                            size="small"
                            required
                            value={infosConfig.cidade || ''}
                            onChange={(event) => HandleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            name="estado"
                            fullWidth
                            label="Estado"
                            disabled
                            required
                            size="small"
                            variant="outlined"
                            value={infosConfig.estado || ''}
                            onChange={(event) => HandleInputChange(event)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={2} sx={{ mt: 5 }}>
                          {infosConfig.id && (
                            <Box
                              component="img"
                              sx={isMobile ? { width: '65%', mt: 1 } : { width: '80%', mt: 1 }}
                              src={`${infosConfig.logo}??${new Date().getTime()}`}
                            />
                          )}
                          <Button
                            variant="outlined"
                            size="medium"
                            component="label"
                            sx={{ mt: 2, textTransform: 'none' }}>
                            Alterar imagem
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={HandleUploadImage}
                              onClick={(event) => (event.target.value = null)}
                            />
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={2} sx={isMobile ? { mt: 3 } : { mt: 8 }}>
                          {infosConfig.id && (
                            <TextField
                              name="corprimaria"
                              fullWidth
                              label="Cor primária"
                              required
                              size="small"
                              type="color"
                              variant="outlined"
                              value={infosConfig.corprimaria || ''}
                              onChange={(event) => HandleInputChange(event)}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel value="2">
                    <Alert severity="error">
                      Informações sensíveis, tenha absoluta certeza antes de alterar, alterações
                      indevidas podem impactar as rotinas do sistema!
                    </Alert>
                    <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 1 }}>
                      <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Tipo de operação *
                          </InputLabel>
                          <Select
                            name="tipooperacao"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.tipooperacao || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Operadora'}>Operadora de saúde</MenuItem>
                            <MenuItem value={'Administradora'}>Administradora de planos</MenuItem>
                            <MenuItem value={'Cartão'}>Cartão de benefícios</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={1.5}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Tipo *
                          </InputLabel>
                          <Select
                            name="tipo"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.tipo || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Simples'}>Simples</MenuItem>
                            <MenuItem value={'Complexo'}>Complexo</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Controla código de contrato *
                          </InputLabel>
                          <Select
                            name="controlacontrato"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.controlacontrato || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Sim'}>Sim</MenuItem>
                            <MenuItem value={'Não'}>Não</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Modalidade de contratos *
                          </InputLabel>
                          <Select
                            name="modalidadecontrato"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.modalidadecontrato || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            {/* <MenuItem value={'Contrato único'}>Contrato único</MenuItem> */}
                            <MenuItem value={'Contrato por plano'}>Contrato por plano</MenuItem>
                            <MenuItem value={'Contrato por tipo de contratação'}>
                              Contrato por modalidade
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Controla subestipulante *
                          </InputLabel>
                          <Select
                            name="controlasubestipulante"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.controlasubestipulante || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Sim'}>Sim</MenuItem>
                            <MenuItem value={'Não'}>Não</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Controla mudança de carência *
                          </InputLabel>
                          <Select
                            name="controlamudancacarencia"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.controlamudancacarencia || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Sim'}>Sim</MenuItem>
                            <MenuItem value={'Não'}>Não</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 3, mb: 1 }}>
                        <Grid item xs={12} md={1.7}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Controla associação *
                          </InputLabel>
                          <Select
                            name="controlaassociacao"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.controlaassociacao || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Sim'}>Sim</MenuItem>
                            <MenuItem value={'Não'}>Não</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={1.7}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Controla comissão *
                          </InputLabel>
                          <Select
                            name="controlacomissao"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.controlacomissao || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Sim'}>Sim</MenuItem>
                            <MenuItem value={'Não'}>Não</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={1.7}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Controla código gerente *
                          </InputLabel>
                          <Select
                            name="controlagerente"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.controlagerente || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Sim'}>Sim</MenuItem>
                            <MenuItem value={'Não'}>Não</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={1.7}>
                          <InputLabel
                            id="label"
                            sx={
                              isMobile
                                ? { fontSize: '13px', mt: 0 }
                                : { fontSize: '13px', mt: -2.2 }
                            }>
                            Tipo de integração *
                          </InputLabel>
                          <Select
                            name="tipointegracao"
                            fullWidth
                            size="small"
                            required
                            value={infosConfig.tipointegracao || ''}
                            onChange={(event) => HandleInputChange(event)}>
                            <MenuItem value={'Própria'}>Própria</MenuItem>
                            <MenuItem value={'Terceiros'}>Terceiros</MenuItem>
                            <MenuItem value={'Não possui'}>Não possui</MenuItem>
                          </Select>
                        </Grid>
                        {infosConfig.tipointegracao !== 'Não possui' && (
                          <>
                            <Grid item xs={12} md={2}>
                              <InputLabel
                                id="label"
                                sx={
                                  isMobile
                                    ? { fontSize: '13px', mt: 0 }
                                    : { fontSize: '13px', mt: -2.2 }
                                }>
                                Status da integração
                              </InputLabel>
                              <Select
                                name="statusintegracao"
                                fullWidth
                                size="small"
                                required
                                value={infosConfig.statusintegracao || ''}
                                onChange={(event) => HandleInputChange(event)}>
                                <MenuItem value={'Aberta'}>Aberta</MenuItem>
                                <MenuItem value={'Análise'}>Análise</MenuItem>
                                <MenuItem value={'Aguardando cliente'}>Aguardando cliente</MenuItem>
                                <MenuItem value={'Aguardando corretora'}>
                                  Aguardando corretora
                                </MenuItem>
                                <MenuItem value={'Aguardando operadora'}>
                                  Aguardando operadora
                                </MenuItem>
                                {infoState.tipo === 'Complexo' && (
                                  <MenuItem value={'Aguardando administradora'}>
                                    Aguardando administradora
                                  </MenuItem>
                                )}
                                <MenuItem value={'Aprovada'}>Aprovada</MenuItem>
                                <MenuItem value={'Negada'}>Negada</MenuItem>
                              </Select>
                            </Grid>

                            <Grid item xs={12} md={3.2}>
                              <TextField
                                name="rotapostback"
                                fullWidth
                                label="Rota de postaback"
                                required
                                size="small"
                                variant="outlined"
                                value={infosConfig.rotapostback || ''}
                                onChange={(event) => HandleInputChange(event)}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.3 }}>
                        {infosConfig.tipointegracao !== 'Não possui' && (
                          <Grid item xs={12} md={12}>
                            <InputLabel id="label" sx={{ fontSize: '13px', ml: 0.3 }}>
                              Token de autenticação *
                            </InputLabel>
                            <TextareaAutosize
                              name="tokenpostback"
                              label="Token de autenticação"
                              required
                              size="small"
                              variant="outlined"
                              minRows={2}
                              value={infosConfig.tokenpostback || ''}
                              onChange={(event) => HandleInputChange(event)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mr: 2.5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosConfig.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/dashboard"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </CustomGrid>
          </form>
        </Card>
      </>
    </RenderPage>
  );
};

export default Config;
