import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientLogin: '',
  authenticatedUser: [],
  permissions: [],
  type: ''
};
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, action) => {
      state.authenticatedUser = action.payload;
    },
    typeClient: (state, action) => {
      state.type = action.payload;
    },
    permissions: (state, action) => {
      state.permissions = action.payload;
    },
    nameUser: (state, action) => {
      state.authenticatedUser.userLoggedIn[0].nome = action.payload;
    },
    avatar: (state, action) => {
      state.authenticatedUser.userLoggedIn[0].avatar = action.payload;
    },
    logout: (state) => {
      state.authenticatedUser = [];
      state.permissions = [];
      state.type = '';
    }
  }
});

export const { client, login, permissions, nameUser, avatar, logout, typeClient } =
  loginSlice.actions;

export const selectLogin = (state) => state.login.authenticatedUser;
export const selectClient = (state) => state.login.clientLogin;
export const selectPermissions = (state) => state.login.permissions;
export const selectTypeClient = (state) => state.login.type;
export default loginSlice.reducer;
