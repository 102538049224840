import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BootstrapDialog } from '../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../store/login/loginSlice';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../../@core/theme/Iconify';
import configAxios from '../../../services/configAxios';
import palette from 'src/@core/theme/palette';

export default function MoreMenu(props) {
  const infosMoreMenu = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const handleOpen = () => {
    if (infosMoreMenu.infos.statusvenda) {
      if (infoState.id_type_user === 1) {
        if (infosMoreMenu.infos.statusvenda === 'Aprovada') {
          NotificationManager.warning(
            `Não é possível excluir quando a venda está aprovada!`,
            'Atenção'
          );
          return;
        }
      }
      if (infoState.id_type_user === 2) {
        if (infosMoreMenu.infos.statusvenda !== 'Aberta') {
          NotificationManager.warning(
            `Não é possível excluir quando o status da venda é diferente de aberto!`,
            'Atenção'
          );
          return;
        }
      }
    }
    setModalIsOpen(true);
  };

  const handleClose = () => setModalIsOpen(false);

  function handleModalEdit(event) {
    infosMoreMenu.infos.functionModal(event);
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  function deleteItem() {
    configAxios
      .delete(`${infosMoreMenu.infos.deleteRouter}/${infosMoreMenu.infos.id}`)
      .then(() => {
        NotificationManager.success('Informação excluída com sucesso!', 'Sucesso');
        infosMoreMenu.infos.functionGrid();
      })
      .catch(() => {
        NotificationManager.error(
          `Tivemos dificuldades para excluir o ${infosMoreMenu.infos.deleteItem}, tente novamente!`,
          'Atenção'
        );
        infosMoreMenu.infos.functionGrid();
      });
  }

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={modalIsOpen}>
          <BootstrapDialogTitle
            style={{ color: palette.primary.contrastText }}
            id="customized-dialog-title"
            onClose={handleClose}>
            Confirmação de exclusão
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography
              style={{ color: palette.primary.contrastText }}
              gutterBottom
              variant="subtitle2">
              Deseja realmente excluir {infosMoreMenu.infos.deleteItem} ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={deleteItem}
              color="success"
              variant="contained"
              startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
              Sim
            </Button>
            <Button
              onClick={handleClose}
              color="error"
              variant="contained"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Não
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" id="iconEdit" width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {infosMoreMenu.infos.functionModal && (
          <MenuItem
            onClick={() => {
              handleModalEdit(infosMoreMenu.infos.id);
            }}
            sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Editar"
              id="btnEdit"
              sx={{ color: 'text.secondary' }}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {!infosMoreMenu.infos.functionModal && !infosMoreMenu.infos.disabledEdit && (
          <MenuItem
            component={RouterLink}
            to={`/app/${infosMoreMenu.infos.router}/${infosMoreMenu.infos.id}`}
            state={{ infosEdit: infosMoreMenu.infos.otherInfos }}
            sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Editar"
              id="btnEdit"
              sx={{ color: 'text.secondary' }}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {infosMoreMenu.infos.deleteRouter && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              handleOpen();
              setIsOpen(false);
            }}>
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Deletar"
              id="btnDelete"
              sx={{ color: 'text.secondary' }}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
