/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  BootstrapDialog,
  TextareaAutosize,
  CustomInfosForm
} from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Tab,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const allowedPageSizes = [5, 10, 20, 30];
import configAxios from '../../../../services/configAxios';

const MaintenanceClients = (props) => {
  const [completedDeclaration, setCompletedDeclaration] = useState({});
  const [completedSign, setCompletedSign] = useState({});
  const [rowsSelectedDeclarations, setRowsSelectedDeclarations] = useState([]);
  const [rowsSelectedDocuments, setRowsSelectedDocuments] = useState([]);
  const [infosMaintenance, setInfosMaintenance] = useState({});
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [value, setValue] = useState('1');
  const [modalDeleteDocumentIsOpen, setModalDeleteDocumentIsOpen] = useState(false);
  const [modalDeleteDeclarationsIsOpen, setModalDeleteDeclarationsIsOpen] = useState(false);
  const status = ['Aberta'];

  useEffect(() => {
    if (!status.includes(props.infosClients.statusvenda)) {
      if (props.infosClients.id) {
        getCompletedHealthDeclaration();
        getCompletedSigningDocuments();
      }
    }
  }, []);

  async function getCompletedHealthDeclaration() {
    const routerCompleted =
      props.infosClients.id_type_client === 1
        ? `/clients/completedDeclarationHealth/${props.infosClients.id}/true`
        : `/clients/completedDeclarationHealth/${props.infosClients.id}/false`;

    await configAxios
      .get(routerCompleted)
      .then((result) => {
        setCompletedDeclaration(result.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para atender a busca das declarações de saúde, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getCompletedSigningDocuments() {
    configAxios
      .get(
        `/clients/completedSigningDocuments/${props.infosClients.id}/null/${props.infosClients.id_type_client}/true`
      )
      .then((result) => {
        setCompletedSign(result.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para atender a busca dos documentos, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function selectedRowsDeclarations(event) {
    setRowsSelectedDeclarations(event.selectedRowsData);
  }

  function selectedRowsDocuments(event) {
    setRowsSelectedDocuments(event.selectedRowsData);
  }

  function RenderTit(infos) {
    if (infos.data.data.tpbenef) {
      return infos.data.data.tpbenef;
    }
    if (props.infosClients.id_type_client === 1) return 'Titular';
    else return 'Responsável jurídico';
  }

  function RenderidTit(infos) {
    if (infos.data.data.id_tit) {
      return infos.data.data.id_tit;
    }
    return '-';
  }

  function HandleTabsChange(event, newValue) {
    setValue(newValue);
    setRowsSelectedDocuments([]);
    setRowsSelectedDeclarations([]);
  }

  function HandleInputChange(event) {
    setInfosMaintenance({ ...infosMaintenance, [event.target.name]: event.target.value });
    return;
  }

  function UpdateClient(event) {
    event.preventDefault();

    const infosUpdate = {
      id: props.infosClients.id,
      user_id: infoState.id,
      id_type_user: infoState.id_type_user,
      ...infosMaintenance
    };
    configAxios
      .post('/clients/updateclientMaintenance', infosUpdate)
      .then(() => {
        NotificationManager.success('Informações atualizadas com sucesso!', 'Sucesso');
        getCompletedHealthDeclaration();
        getCompletedSigningDocuments();
        props.getHistorySales();
        props.getInfosClient();
        props.getCountDocumentsSigning();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar as informações, tente novamente!',
          'Atenção'
        );
      });
  }

  function deleteDeclarations() {
    configAxios
      .post('/clients/deleteDeclarationsCompleted', rowsSelectedDeclarations)
      .then(() => {
        NotificationManager.success('Informações excluídas com sucesso!', 'Sucesso');
        getCompletedHealthDeclaration();
        getCompletedSigningDocuments();
        props.getHistorySales();
        props.getInfosClient();
        props.getCountDocumentsSigning();
        handleCloseDeclarations();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar as informações, tente novamente!',
          'Atenção'
        );
      });
  }

  function deleteDocuments() {
    configAxios
      .post('/clients/deleteDocumentsCompleted', rowsSelectedDocuments)
      .then(() => {
        NotificationManager.success('Informações excluídas com sucesso!', 'Sucesso');
        getCompletedHealthDeclaration();
        getCompletedSigningDocuments();
        props.getHistorySales();
        props.getInfosClient();
        props.getCountDocumentsSigning();
        handleCloseDocuments();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar as informações, tente novamente!',
          'Atenção'
        );
      });
  }

  const handleCloseDocuments = () => setModalDeleteDocumentIsOpen(false);

  const handleCloseDeclarations = () => setModalDeleteDeclarationsIsOpen(false);

  function ModalHandleDeleteDocuments() {
    return (
      <BootstrapDialog
        onClose={handleCloseDocuments}
        aria-labelledby="customized-dialog-title"
        open={modalDeleteDocumentIsOpen}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDocuments}>
          Confirmação de exclusão
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom variant="subtitle2">
            Deseja realmente excluir os itens selecionados?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteDocuments}
            color="success"
            variant="contained"
            startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
            Sim
          </Button>
          <Button
            onClick={handleCloseDocuments}
            color="error"
            variant="contained"
            startIcon={<Iconify icon="eva:close-circle-fill" />}>
            Não
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  }

  function ModalHandleDeleteDeclarations() {
    return (
      <BootstrapDialog
        onClose={handleCloseDeclarations}
        aria-labelledby="customized-dialog-title"
        open={modalDeleteDeclarationsIsOpen}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDeclarations}>
          Confirmação de exclusão
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom variant="subtitle2">
            Deseja realmente excluir os itens selecionados?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteDeclarations}
            color="success"
            variant="contained"
            startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
            Sim
          </Button>
          <Button
            onClick={handleCloseDeclarations}
            color="error"
            variant="contained"
            startIcon={<Iconify icon="eva:close-circle-fill" />}>
            Não
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };
  return (
    <>
      <ModalHandleDeleteDocuments />
      <ModalHandleDeleteDeclarations />
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            setInfosMaintenance({});
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              setInfosMaintenance({});
            }}>
            Manutenção da venda
          </BootstrapDialogTitle>
          <form onSubmit={UpdateClient}>
            <DialogContent dividers>
              <TabContext value={value}>
                <TabList onChange={HandleTabsChange} aria-label="SendEmailContract">
                  <Tab label="Declarações de saúde" value="1" sx={{ textTransform: 'none' }} />
                  <Tab label="Documentos assinados" value="2" sx={{ textTransform: 'none' }} />
                  <Tab label="Alteração de status" value="3" sx={{ textTransform: 'none' }} />
                </TabList>
                <TabPanel value="1" variant="fullWidth">
                  <Stack direction="row" alignItems="center" justifyContent="end">
                    <Grid container>
                      <Grid item>
                        <CustomInfosForm>
                          Visualize abaixo os beneficiários que preencheram a declaração de saúde
                        </CustomInfosForm>
                      </Grid>
                    </Grid>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 1 }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setModalDeleteDeclarationsIsOpen(true)}
                        disabled={rowsSelectedDeclarations.length ? false : true}
                        sx={{ mr: 1 }}
                        startIcon={<Iconify icon="eva:trash-2-outline" />}>
                        Excluir
                      </Button>
                    </Stack>
                  </Stack>
                  <DataGrid
                    dataSource={completedDeclaration}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onSelectionChanged={selectedRowsDeclarations}
                    noDataText={'Não foram encontradas declarações preenchidas'}>
                    <Selection mode="multiple" />
                    <Column dataField="id" caption="Id" />
                    <Column dataField="nome" caption="Nome" width={240} />
                    <Column
                      dataField="tpbenef"
                      cellRender={(data) => <RenderTit data={data} />}
                      caption="Tipo de beneficiário"
                    />
                    {props.infosClients.id_type_client === 2 && (
                      <Column
                        dataField="id_tit"
                        caption="Id. titular"
                        cellRender={(data) => <RenderidTit data={data} />}
                      />
                    )}
                    <Column dataField="cpf_cnpj" caption="Cpf" />
                    <SearchPanel visible={true} width={250} placeholder="Procurar..." />
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                    <Scrolling rowRenderingMode="virtual"></Scrolling>
                    <Paging defaultPageSize={5} />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <ColumnChooser
                      enabled={true}
                      title={'Escolher uma coluna'}
                      emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                    />
                    <ColumnFixing enabled={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                  </DataGrid>
                </TabPanel>
                <TabPanel value="2" variant="fullWidth">
                  <Stack direction="row" alignItems="center" justifyContent="end">
                    <Grid container>
                      <Grid item>
                        <CustomInfosForm>
                          Visualize abaixo os beneficiários que assinaram os documentos
                        </CustomInfosForm>
                      </Grid>
                    </Grid>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 1 }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setModalDeleteDocumentIsOpen(true)}
                        disabled={rowsSelectedDocuments.length ? false : true}
                        sx={{ mr: 1 }}
                        startIcon={<Iconify icon="eva:trash-2-outline" />}>
                        Excluir
                      </Button>
                    </Stack>
                  </Stack>
                  <DataGrid
                    dataSource={completedSign}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onSelectionChanged={selectedRowsDocuments}
                    noDataText={'Nenhum documento assinado'}>
                    <Selection mode="multiple" />
                    <Column dataField="id" caption="Id" />
                    <Column dataField="nome" caption="Nome" width={240} />
                    <Column
                      dataField="tpbenef"
                      cellRender={(data) => <RenderTit data={data} />}
                      caption="Tipo de beneficiário"
                    />
                    {props.infosClients.id_type_client === 2 && (
                      <Column
                        dataField="id_tit"
                        caption="Id. titular"
                        cellRender={(data) => <RenderidTit data={data} />}
                      />
                    )}
                    <Column dataField="cpf_cnpj" caption="Cpf" />
                    <SearchPanel visible={true} width={250} placeholder="Procurar..." />
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                    <Scrolling rowRenderingMode="virtual"></Scrolling>
                    <Paging defaultPageSize={5} />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <ColumnChooser
                      enabled={true}
                      title={'Escolher uma coluna'}
                      emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                    />
                    <ColumnFixing enabled={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                  </DataGrid>
                </TabPanel>
                <TabPanel value="3" variant="fullWidth">
                  <Grid item xs={12} sx={{ mt: -2, mb: 1 }}>
                    <CustomInfosForm>Realize a alteração do status da venda</CustomInfosForm>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <InputLabel id="label" sx={{ fontSize: '13px' }}>
                        Status da venda *
                      </InputLabel>
                      <Select
                        name="statusvenda"
                        fullWidth
                        size="small"
                        required
                        value={infosMaintenance.statusvenda || props.infosClients.statusvenda}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'Aberta'}>Aberta</MenuItem>
                        <MenuItem value={'Análise'}>Análise</MenuItem>
                        {infoState.tipo !== 'Complexo' && (
                          <MenuItem value={'Rejeitada'}>Rejeitada</MenuItem>
                        )}
                        <MenuItem value={'Aguardando cliente'}>Aguardando cliente</MenuItem>
                        <MenuItem value={'Aguardando corretora'}>Aguardando corretora</MenuItem>
                        <MenuItem value={'Aguardando operadora'}>Aguardando operadora</MenuItem>
                        {infoState.tipo === 'Complexo' && (
                          <MenuItem value={'Aguardando administradora'}>
                            Aguardando administradora
                          </MenuItem>
                        )}
                        <MenuItem value={'Aprovada'}>Aprovada</MenuItem>
                        <MenuItem value={'Negada'}>Negada</MenuItem>
                      </Select>
                    </Grid>
                    {infosMaintenance.statusvenda === 'Negada' && (
                      <Grid item xs={12} md={6}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Motivo da negativa *
                        </InputLabel>
                        <TextareaAutosize
                          name="motivo_negativa"
                          label="Motivo da negativa"
                          required
                          size="small"
                          variant="outlined"
                          minRows={2}
                          value={infosMaintenance.motivo_negativa || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              </TabContext>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                disabled={value !== '3' ? true : false}
                startIcon={<Iconify icon="eva:save-fill" />}>
                Atualizar
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  setInfosMaintenance({});
                  setValue('1');
                }}
                color="error"
                startIcon={<Iconify icon="eva:close-circle-fill" />}
                variant="contained">
                Fechar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default MaintenanceClients;
