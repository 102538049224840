import { useRef, useState } from 'react';
import { BootstrapDialog } from '../../../assets/style/globalStyle';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { selectClient } from '../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../../@core/theme/Iconify';
import configAxios from '../../../services/configAxios';

export default function MoreMenuDocuments(props) {
  const URL_AWS_S3 = 'https://mplansistemas-vendaonline.s3.sa-east-1.amazonaws.com';

  const infosMoreMenuDocuments = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [url] = useState(URL_AWS_S3);

  const company = useSelector(selectClient);

  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);

  function handleViewFile(event) {
    const tpbenef = event.id_type_client === 1 ? 'fisico' : 'juridico';

    if (infosMoreMenuDocuments.infos.form) {
      window.open(`${url}/${company}/assets/templates/${event.fileName}`, '_blank');
    } else {
      window.open(
        `${url}/${event.company}/${tpbenef}/${event.id_client}/${event.fileName}`,
        '_blank'
      );
    }
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  function deleteItem() {
    let router;

    if (!infosMoreMenuDocuments.infos.form) {
      if (infosMoreMenuDocuments.infos.statusvenda === 'Aprovada') {
        NotificationManager.warning(
          'Não é possível realizar a exclusão de documentos em vendas aprovadas!',
          'Atenção'
        );
        return;
      }
      const tpbenef = infosMoreMenuDocuments.infos.id_type_client === 1 ? 'fisico' : 'juridico';

      router = `${infosMoreMenuDocuments.infos.deleteRouter}/?id=${infosMoreMenuDocuments.infos.id}&id_client=${infosMoreMenuDocuments.infos.id_client}
      &tpbenef=${tpbenef}&fileName=${infosMoreMenuDocuments.infos.fileName}`;
    } else {
      router = `${infosMoreMenuDocuments.infos.deleteRouter}/?id=${infosMoreMenuDocuments.infos.id_form}&fileName=${infosMoreMenuDocuments.infos.fileName}`;
    }

    configAxios
      .delete(router)
      .then(() => {
        NotificationManager.success('Documento excluído com sucesso!', 'Sucesso');
        infosMoreMenuDocuments.infos.functionGrid();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para excluir o documento, tente novamente!',
          'Atenção'
        );
        if (!infosMoreMenuDocuments.infos.form) {
          infosMoreMenuDocuments.infos.functionGrid();
        }
      });
  }

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={modalIsOpen}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Confirmação de exclusão
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom variant="subtitle2">
              Deseja realmente excluir o documento selecionado?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={deleteItem}
              color="success"
              variant="contained"
              startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
              Sim
            </Button>
            <Button
              onClick={handleClose}
              color="error"
              variant="contained"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Não
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" id="iconEdit" width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem
          onClick={() => {
            handleViewFile(infosMoreMenuDocuments.infos);
            setIsOpen(false);
          }}
          sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify
              icon={
                infosMoreMenuDocuments.infos.form ? 'eva:cloud-download-outline' : 'eva:eye-outline'
              }
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={infosMoreMenuDocuments.infos.form ? 'Download' : 'Visualizar'}
            id="btnEdit"
            sx={{ color: 'text.primary' }}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleOpen();
            setIsOpen(false);
          }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Deletar"
            id="btnDelete"
            sx={{ color: 'text.primary' }}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
