import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false
};
export const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startAndStop: (state, action) => {
      state.loading = action.payload;
    }
  }
});
export const { startAndStop } = loading.actions;
export const selectLoading = (state) => state.loading.loading;
export default loading.reducer;
