import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import Iconify from '../../../@core/theme/Iconify';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const isActiveRootChildren = active(item.children ? item.children.path : '');
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  const [openChildren, setOpenChildren] = useState(isActiveRootChildren);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOpenChildren = () => {
    setOpenChildren((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item, index) => {
              const { title, path } = item;
              const isActiveSub = active(path);
              if (item.children) {
                return (
                  <span key={index}>
                    <ListItemStyle
                      key={index}
                      onClick={handleOpenChildren}
                      sx={{
                        ...(isActiveRoot && activeRootStyle)
                      }}>
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main'
                            })
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                      {info && info}
                      <Iconify
                        icon={
                          openChildren
                            ? 'eva:arrow-ios-downward-fill'
                            : 'eva:arrow-ios-forward-fill'
                        }
                        sx={{ width: 16, height: 16, ml: 1 }}
                      />
                    </ListItemStyle>
                    <Collapse key={item.title} in={openChildren} timeout="auto" unmountOnExit>
                      <List key={item.title} component="div" disablePadding>
                        {item.children.map((items) => {
                          const { title, path } = items;
                          const isActiveSub = active(path);
                          return (
                            <ListItemStyle
                              key={title}
                              component={RouterLink}
                              to={path}
                              sx={{
                                ...(isActiveSub && activeSubStyle)
                              }}>
                              <ListItemIconStyle>
                                <Box
                                  component="span"
                                  sx={{
                                    width: 4,
                                    height: 4,
                                    display: 'flex',
                                    borderRadius: '50%',
                                    marginLeft: '80%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'text.disabled',
                                    transition: (theme) => theme.transitions.create('transform'),
                                    ...(isActiveSub && {
                                      transform: 'scale(2)',
                                      bgcolor: 'primary.main'
                                    })
                                  }}
                                />
                              </ListItemIconStyle>
                              <ListItemText sx={{ ml: 2 }} disableTypography primary={title} />
                            </ListItemStyle>
                          );
                        })}
                      </List>
                    </Collapse>
                  </span>
                );
              }
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}>
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}>
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item, index) => (
          <NavItem key={index} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
