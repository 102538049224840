import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { Stack, Typography, Card, Button, TextField, Grid, Autocomplete, Box } from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const addEditTables = (props) => {
  const [InfosTableLabel, setInfosTableLabel] = useState([]);
  const [infosTables, setInfosTables] = useState({});
  const [infosGrid, setInfosGrid] = useState([]);
  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    getTablesLabel();
  }, []);

  function getTablesLabel() {
    configAxios
      .get('tables/getTablesLabel')
      .then((response) => {
        setInfosTableLabel(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getTablesReadjustments() {
    configAxios
      .get(`/tables/getTablesImport/?to=${infosTables.mesref}&codtab=${infosTables.codtab}`)
      .then((response) => {
        if (!response.data.length) {
          NotificationManager.warning(
            'Não há itens para o mês de referência informado!',
            'Atenção'
          );
          setInfosGrid([]);
          return;
        }
        setInfosGrid(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosTables({ ...infosTables, label: event.label, codtab: event.codtab });
        return;
      }
      return;
    }
    setInfosTables({ ...infosTables, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    configAxios
      .post('/tables/readjustmentsTables', infosTables)
      .then(() => {
        NotificationManager.success('Itens reajustados com sucesso!', 'Sucesso');
        return;
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Tabelas" id="table">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Reajustar tabelas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para reajustar as tabelas
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/tables"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="mesref"
                      fullWidth
                      label="Mês de referência:"
                      variant="outlined"
                      size="small"
                      type="month"
                      required
                      value={infosTables.mesref || ''}
                      onChange={(event) => HandleInputChange(event)}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="readjustment"
                      fullWidth
                      label="Valor do reajuste em %"
                      variant="outlined"
                      size="small"
                      type="number"
                      required
                      value={infosTables.readjustment || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={InfosTableLabel}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange(value, true)}
                      value={infosTables.label || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione a tabela"
                          name="selectedTable"
                          fullWidth
                          size="small"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {!isMobile && (
                    <Grid item xs={12} md={2}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => getTablesReadjustments()}
                        startIcon={<Iconify icon="eva:funnel-outline" />}
                        sx={{ mr: 2 }}>
                        Filtrar
                      </Button>
                    </Grid>
                  )}
                  {isMobile && (
                    <Grid item xs={12} md={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                        sx={{ mb: 1 }}>
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          onClick={() => getTablesReadjustments()}
                          startIcon={<Iconify icon="eva:funnel-outline" />}
                          sx={{ mr: 2 }}>
                          Filtrar
                        </Button>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
                {/* <CustomGrid sx={{ mt: 1 }}> */}
                <DataGrid
                  dataSource={infosGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  noDataText={'Não foram encontrados registros'}>
                  <Column dataField="id" caption="Id" />
                  <Column dataField="codtab" caption="Código da tabela" />
                  <Column dataField="mesref" caption="Mês de referência" alignment="left" />
                  <Column dataField="faixa1" caption="Idade inicial" alignment="left" />
                  <Column dataField="faixa2" caption="Idade final" alignment="left" />
                  <Column dataField="valor" caption="Valor R$" alignment="right" />
                  <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                  {!isMobile && (
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                  )}
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser
                    enabled={true}
                    title={'Escolher uma coluna'}
                    emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                  />
                  <ColumnFixing enabled={true} />
                  <HeaderFilter visible={true} allowSearch={true} />
                </DataGrid>
                {/* </CustomGrid> */}
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  Reajustar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/tables"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditTables;
