import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import { ContentStyle } from '../../../../assets/style/globalStyle';
import { useSelector } from 'react-redux';

import RenderPage from '../../../layouts/RenderPage';
import imageillustrations from '../../../../assets/images/illustrations/illustration_404.svg';

export default function Page404() {
  const permissions = useSelector((state) => state.login.permissions);
  let router = '/app/dashboard';

  if (permissions.length > 0 && permissions[0].id_type_user === 3) {
    router = '/app/signature';
  }

  return (
    <RenderPage title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Desculpe, a pagina solicitada não foi encontrada!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Não encontramos a página solicitada, digite outra URL e tente novamente. Caso o erro
            persista, entre em contato conosco!
          </Typography>
          <Box
            component="img"
            src={imageillustrations}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />
          <Button to={router} size="large" variant="contained" component={RouterLink}>
            Voltar
          </Button>
        </ContentStyle>
      </Container>
    </RenderPage>
  );
}
