import { BaseOptionChartStyle } from './views/components/chart/BaseOptionChart';
import { useSelector } from 'react-redux';
import { selectLoading } from './store/loading/loadingSlice';
import { NotificationContainer } from 'react-notifications';
import { useRoutes } from 'react-router-dom';
import { CircularLoaging, TypographyCirularLoading } from '../src/assets/style/globalStyle';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'react-notifications/lib/notifications.css';
import '../src/assets/style/globalStyle.css';

import routes from './routes/routes';
import ThemeProvider from './@core/theme';
import Scrollbar from './views/components/multiplesComponents/Scrollbar';

export default function App() {
  const loading = useSelector(selectLoading);
  const isLoggedIn = useSelector((state) => state.login.authenticatedUser.token);
  const permissions = useSelector((state) => state.login.permissions);

  const routing = useRoutes(routes(isLoggedIn, permissions));

  function RenderLoading() {
    if (loading) {
      return (
        <div className="cover">
          <CircularLoaging disableShrink />
          <TypographyCirularLoading>Aguarde...</TypographyCirularLoading>
        </div>
      );
    }
  }
  return (
    <ThemeProvider>
      <BaseOptionChartStyle />
      <RenderLoading />
      <NotificationContainer id="notification" />
      <Scrollbar>
        <>{routing}</>
      </Scrollbar>
    </ThemeProvider>
  );
}
