import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const Email = (props) => {
  const [infosConfig, setInfosConfig] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getConfigSmtp();
  }, []);

  function getConfigSmtp() {
    configAxios
      .get('/config/getSmtpConfig')
      .then((response) => {
        setInfosConfig(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades buscar as configurações no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event) {
    setInfosConfig({ ...infosConfig, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = '/config/updateSmtpConfig';
    configAxios
      .post(router, infosConfig)
      .then(() => {
        NotificationManager.success('Configurações de email atualizadas com sucesso!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Configurações" id="configs">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Configurações do email (smtp)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo editar as configurações e informações do email
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={2.5}>
                    <TextField
                      name="nome"
                      fullWidth
                      label="Nome"
                      variant="outlined"
                      size="small"
                      required
                      value={infosConfig.nome || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="small"
                      required
                      type="email"
                      value={infosConfig.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <TextField
                      name="password"
                      fullWidth
                      label="Senha"
                      type={showPassword ? 'text' : 'password'}
                      onChange={(event) => HandleInputChange(event)}
                      value={infosConfig.password || ''}
                      inputProps={{ maxLength: 50 }}
                      required
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="host"
                      fullWidth
                      label="Host"
                      variant="outlined"
                      size="small"
                      required
                      value={infosConfig.host || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="port"
                      fullWidth
                      label="Porta"
                      variant="outlined"
                      size="small"
                      required
                      value={infosConfig.port || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosConfig.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/dashboard"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Email;
