/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { CustomInfosForm } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Alert
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  GroupPanel
} from 'devextreme-react/data-grid';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const allowedPageSizes = [5, 10, 20, 30];

const pendingHealthDeclaration = (props) => {
  function RenderTit(infos) {
    if (infos.data.data.tpbenef) {
      return infos.data.data.tpbenef;
    }
    if (props.pendingInfos[0].id_type_client === 1) return 'Titular';
    else return 'Responsável jurídico';
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid ?? props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          PaperProps={{
            sx: { borderRadius: 0 }
          }}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid ?? props.functionGrid();
            }}>
            {props.headerModalPending}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {/* <CustomInfosForm sx={{ mb: 2 }}>
              Acompanhe os beneficiários com pendências
            </CustomInfosForm> */}
            <Grid container sx={{ mb: 0, mt: 0 }}>
              <DataGrid
                dataSource={props.pendingInfos}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                noDataText={'Não foram encontradas pendências'}>
                <Column dataField="nome" caption="Nome" width={200} />
                <Column
                  dataField="tpbenef"
                  cellRender={(data) => <RenderTit data={data} />}
                  caption="Tipo de beneficiário"
                  width={200}
                />
                {!isMobile && <Column dataField="cpf_cnpj" caption="CPF" />}
                <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                {!isMobile && (
                  <GroupPanel
                    visible={true}
                    emptyPanelText="Arraste as colunas aqui para agrupar"
                  />
                )}
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={5} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.setOpenModal(false);
                props.functionGrid ?? props.functionGrid();
              }}
              color="error"
              variant="contained"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Fechar
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default pendingHealthDeclaration;
