/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

const ImportItems = (props) => {
  const [InfosTableLabel, setInfosTableLabel] = useState([]);
  const [infosTableSelected, setInfosTableSelected] = useState({});

  useEffect(() => {
    getTablesLabel();
  }, []);

  function getTablesLabel() {
    configAxios
      .get('tables/getTablesLabel')
      .then((response) => {
        setInfosTableLabel(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputCodTab(event) {
    setInfosTableSelected({ ...infosTableSelected, codtab: event.codtab, label: event.label });
  }

  function HandleInputChange(event) {
    setInfosTableSelected({ ...infosTableSelected, [event.target.name]: event.target.value });
  }

  async function HandleSubmit(event) {
    event.preventDefault();

    if (infosTableSelected.from >= infosTableSelected.to) {
      NotificationManager.warning(
        'A data de destino deve ser maior que a data de origem!',
        'Atenção'
      );
      return;
    }
    try {
      const infosImportToTables = await configAxios.get(
        `tables/getTablesImport/?from=${infosTableSelected.from}&codtab=${infosTableSelected.codtab}`
      );
      if (infosImportToTables.status === 500) {
        NotificationManager.error(
          'Tivemos dificuldades para importar os itens, por favor, tente novamente!',
          'Atenção'
        );
        return;
      }
      const objectImport = {
        infosTableSelected,
        infosImportToTables: infosImportToTables.data
      };
      configAxios
        .post('tables/importTables', objectImport)
        .then((response) => {
          if (response.status === 201) {
            NotificationManager.warning('Não encontramos registros para importar!', 'Atenção');
            return;
          }
          NotificationManager.success('Registros importados com sucesso!', 'Sucesso');
          props.setOpenModal(false);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para importar os registros! Tente novamente!',
            'Atenção'
          );
        });
    } catch {
      NotificationManager.error(
        'Tivemos dificuldades para importar os registros! Tente novamente!',
        'Atenção'
      );
    }
  }
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            Importação de tabelas
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="from"
                    fullWidth
                    label="De:"
                    variant="outlined"
                    size="small"
                    type="month"
                    required
                    value={infosTableSelected.from || ''}
                    onChange={(event) => HandleInputChange(event)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="to"
                    fullWidth
                    label="Para"
                    variant="outlined"
                    size="small"
                    type="month"
                    required
                    value={infosTableSelected.to || ''}
                    onChange={(event) => HandleInputChange(event)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                  <Autocomplete
                    options={InfosTableLabel}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputCodTab(value)}
                    value={infosTableSelected.label || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione a tabela"
                        name="selectedTable"
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:repeat-fill" />}>
                Importar
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ImportItems;
