import React, { useEffect, useState } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // Typography,
  // Grid,
  Box
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
// import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';
const allowedPageSizes = [5, 10, 20, 30];

const historySales = (props) => {
  const [infosHistorySales, setInfosHistorySales] = useState([]);

  useEffect(() => {
    setInfosHistorySales(props.infosHistorySales);
  }, [props.infosHistorySales]);

  function ChangedDate(date) {
    return date.data.data.created_at.replaceAll('-', ':');
  }

  function NameUser(infos) {
    if (infos.data.data.nome) {
      return infos.data.data.nome;
    }
    return '-';
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
            }}>
            Histórico de movimentações
          </BootstrapDialogTitle>
          {/* <form onSubmit={HandleSubmit}> */}
          <Box sx={{ width: '100%' }}>
            <DialogContent dividers>
              {/* <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Acompanhe abaixo o histórico de movimentações da venda
                </Typography>
              </Grid> */}
              <DataGrid
                dataSource={infosHistorySales}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                noDataText={'Não há movimentações'}>
                <Column
                  dataField="nome"
                  caption="Usuário"
                  width={190}
                  cellRender={(data) => <NameUser data={data} />}
                />
                <Column dataField="status" width={200} caption="Status" />
                <Column
                  dataField="created_at"
                  caption="Data e hora"
                  // width={100}
                  cellRender={(data) => <ChangedDate data={data} />}
                />
                <SearchPanel visible={true} width={220} placeholder="Procurar..." />
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={5} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Fechar
              </Button>
            </DialogActions>
          </Box>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default historySales;
