/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  Column,
  ColumnFixing
} from 'devextreme-react/data-grid';

const allowedPageSizes = [5, 10, 20, 30];

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const CopyItems = (props) => {
  const [infosItem, setInfosItem] = useState({});
  const [openModalDuplicates, setOpenModalDuplicates] = useState(false);
  const [contentDuplicateState, setContentDuplicateState] = useState([]);

  const [inputFields, setInputFields] = useState([
    { mesref: '', faixa1: '', faixa2: '', valor: '' }
  ]);

  useEffect(() => {
    if (props.infosItemEdit.length > 0) {
      let mesref = props.infosItemEdit[0].mesref;
      let year = mesref.toString().slice(0, 4);
      let month = mesref.toString().slice(4, 6);
      setInfosItem({
        id: props.infosItemEdit[0].id,
        mesref: year + '-' + month,
        valor: props.infosItemEdit[0].valor,
        faixa1: props.infosItemEdit[0].faixa1,
        faixa2: props.infosItemEdit[0].faixa2
      });
    }
  }, [props.infosItemEdit]);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { mesref: inputFields[0].mesref, faixa1: '', faixa2: '', valor: '' }
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    if (index !== 0) {
      values.splice(index, 1);
    }
    setInputFields(values);
  };

  function HandleInputChangeCreate(index, event) {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  }

  function HandleInputChangeEdit(event) {
    setInfosItem({ ...infosItem, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    let valuesAddEdit;

    if (props.infosItemEdit.length == 0) {
      let contentDuplicate = [];
      props.infosValPlan.filter((valplan) => {
        inputFields.map((input) => {
          let formatMesref = input.mesref.replace('-', '');

          if (valplan.mesref == formatMesref) {
            if (valplan.faixa1 == input.faixa1 || valplan.faixa2 == input.faixa2) {
              contentDuplicate.push(input);
            }
          }
        });
      });
      if (contentDuplicate.length > 0) {
        setOpenModalDuplicates(true);
        setContentDuplicateState(contentDuplicate);
        NotificationManager.warning(
          'Já existe itens para os meses de referências e faixas etárias, por favor, remova-os!',
          'Atenção'
        );
        return;
      }
      let valuesAdd = [];
      inputFields.map((input) => {
        valuesAdd.push({
          ...input,
          codtab: props.otherInfos.codtab,
          mesref: input.mesref.replace('-', '')
        });
      });
      valuesAddEdit = valuesAdd;
    } else {
      let formatMesRef = '';
      formatMesRef = infosItem.mesref.replace('-', '');
      valuesAddEdit = {
        ...infosItem,
        mesref: formatMesRef,
        codtab: props.otherInfos.codtab
      };
      let contentDuplicate = props.infosValPlan.filter(function (element) {
        return (
          element.mesref == formatMesRef &&
          (element.faixa1 == valuesAddEdit.faixa1 || element.faixa2 == valuesAddEdit.faixa2)
        );
      });
      if (contentDuplicate.length > 0) {
        if (valuesAddEdit.id !== contentDuplicate[0].id) {
          NotificationManager.warning(
            'Já existe um item para esse mês de referência e faixa etária, por favor, faça a edição do mesmo!',
            'Atenção'
          );
          return;
        }
      }
    }
    let route = valuesAddEdit.id ? '/itemsValPlan/updateItem' : '/itemsValPlan/insertItem';
    let message = valuesAddEdit.id ? 'Item editado com sucesso!' : 'Item inserido com sucesso!';
    configAxios
      .post(route, { valuesAddEdit })
      .then(() => {
        props.setOpenModal(false);
        props.functionGrid();
        setInfosItem({});
        setInputFields([{ mesref: '', faixa1: '', faixa2: '', valor: '' }]);
        NotificationManager.success(message, 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function FormatDate(infos) {
    let str = infos.data.data.mesref.toString();
    let year = str.substring(0, 4).toString();
    let month = str.substring(5, 7).toString();
    let dateFormated = month + '/' + year;
    return dateFormated;
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        {/* <Modal Duplicate> */}
        <BootstrapDialog
          onClose={() => {
            setOpenModalDuplicates(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={openModalDuplicates}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              setOpenModalDuplicates(false);
            }}>
            Itens duplicados na tabela de valores
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomInfosForm>
                Os itens abaixo já existem na tabela de valores, por favor, remova-os
              </CustomInfosForm>
              <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
                <CustomGrid>
                  <DataGrid
                    dataSource={contentDuplicateState}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}>
                    <Column
                      dataField="mesref"
                      alignment="left"
                      cellRender={(data) => <FormatDate data={data} />}
                      caption="Mês de referência"
                    />
                    <Column dataField="faixa1" caption="Idade inicial" width={150} />
                    <Column dataField="faixa2" caption="Idade final" />
                    <Column dataField="valor" caption="R$ Valor" />
                    <Scrolling rowRenderingMode="virtual"></Scrolling>
                    <Paging defaultPageSize={10} />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <ColumnFixing enabled={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                  </DataGrid>
                </CustomGrid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenModalDuplicates(false);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Fechar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
        {/* <Modal AddEditItem> */}
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
            setInfosItem({});
            setInputFields([{ mesref: '', faixa1: '', faixa2: '', valor: '' }]);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
              setInfosItem({});
              setInputFields([{ mesref: '', faixa1: '', faixa2: '', valor: '' }]);
            }}>
            {props.infosItemEdit.length == 0
              ? 'Adicionar itens na tabela de valores'
              : 'Editar item da tabela de valores'}
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
              {props.infosItemEdit.length == 0 &&
                inputFields.map((inputField, index) => (
                  <div key={index}>
                    <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="mesref"
                          fullWidth
                          label="Mês de referência"
                          variant="outlined"
                          size="small"
                          required
                          type="month"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={inputField.mesref || ''}
                          onChange={(event) => HandleInputChangeCreate(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.3}>
                        <TextField
                          name="faixa1"
                          fullWidth
                          label="Idade Inicial"
                          variant="outlined"
                          size="small"
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
                          type="number"
                          required
                          value={inputField.faixa1 === 0 ? 0 : inputField.faixa1 || ''}
                          onChange={(event) => HandleInputChangeCreate(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.3}>
                        <TextField
                          name="faixa2"
                          fullWidth
                          label="Idade Final"
                          variant="outlined"
                          size="small"
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
                          type="number"
                          required
                          value={inputField.faixa2 === 0 ? 0 : inputField.faixa2 || ''}
                          onChange={(event) => HandleInputChangeCreate(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.4}>
                        <TextField
                          name="valor"
                          fullWidth
                          label="Valor R$"
                          variant="outlined"
                          size="small"
                          type="number"
                          required
                          value={inputField.valor || ''}
                          onChange={(event) => HandleInputChangeCreate(index, event)}
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} sx={{ ml: 0 }}>
                        <IconButton onClick={() => handleRemoveFields(index)}>
                          <RemoveIcon />
                        </IconButton>
                        <IconButton onClick={() => handleAddFields()} sx={{ ml: 1 }}>
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              {props.infosItemEdit.length > 0 && (
                <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="mesref"
                      fullWidth
                      label="Mês de referência"
                      variant="outlined"
                      size="small"
                      required
                      type="month"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={infosItem.mesref || ''}
                      onChange={(event) => HandleInputChangeEdit(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="faixa1"
                      fullWidth
                      label="Idade Inicial"
                      variant="outlined"
                      size="small"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
                      type="number"
                      required
                      value={infosItem.faixa1 === 0 ? 0 : infosItem.faixa1 || ''}
                      onChange={(event) => HandleInputChangeEdit(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="faixa2"
                      fullWidth
                      label="Idade Final"
                      variant="outlined"
                      size="small"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
                      type="number"
                      required
                      value={infosItem.faixa2 === 0 ? 0 : infosItem.faixa2 || ''}
                      onChange={(event) => HandleInputChangeEdit(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="valor"
                      fullWidth
                      label="Valor R$"
                      variant="outlined"
                      size="small"
                      type="number"
                      required
                      value={infosItem.valor || ''}
                      onChange={(event) => HandleInputChangeEdit(event)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-fill" />}>
                {infosItem.id ? 'Atualizar' : 'Salvar'}
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                  setInfosItem({});
                  setInputFields([{ mesref: '', faixa1: '', faixa2: '', valor: '' }]);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CopyItems;
