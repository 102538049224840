/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import useWebSocket from 'react-use-websocket';
import PdfViewer from '../../../components/multiplesComponents/PdfViewer';
import FinishSigningSuccess from './FinishSigningSuccess';

const signingDocuments = (props) => {
  const user = useSelector((state) => state.login.authenticatedUser);
  let tpbenef = useSelector((state) => state.login.type);

  const [qtdDocumentSigning, setQtdDocumentsSigning] = useState('');
  const [qtdDocumentsForSignature, setQtdDocumentsForSignature] = useState('');
  const [modalAlertOpen, setModalAlertOpen] = useState(false);
  const [disabledNotification, setDisabledNotification] = useState(false);

  const wsURL =
    process.env.NODE_ENV === 'production' ? 'wss://app.phorm.com.br:3001' : 'ws://localhost:3001';

  let queryParams = {
    'x-access-token': user.token,
    eventType: 'message',
    direction: 'operadora',
    id_client: props.id_client
  };

  if (user.userLoggedIn[0].id_type_user === 1) {
    queryParams = {
      'x-access-token': user.token,
      eventType: 'message',
      direction: 'corretora',
      id_vend: props.infosClients.id_user,
      id_client: props.id_client
    };
  }

  const { sendMessage } = useWebSocket(wsURL, {
    queryParams,
    share: true
  });

  useEffect(() => {
    setQtdDocumentsSigning(props.qtdDocumentSigning + 1);
    setQtdDocumentsForSignature(props.qtdDocumentsForSignature);
  }, []);

  function HandleSubmit() {
    let infoSignedDocuments = {};
    // let userLoggedIn = { ...user.userLoggedIn[0] };
    let userLoggedIn = {};

    if (tpbenef) {
      if (userLoggedIn.tpcontrat === '2-COLETIVO EMPRESARIAL') {
        if (userLoggedIn.included_resp === 'S') {
          if (userLoggedIn.cpf_cnpj === userLoggedIn.cpf_cnpj_resp) {
            userLoggedIn.signaturehash = userLoggedIn.signaturehash_resp;
          }
        }
      }
      infoSignedDocuments = {
        userLoggedIn: userLoggedIn,
        documentsForSignature: props.documentsForSignature,
        tpbenef: tpbenef
      };
    } else {
      infoSignedDocuments = {
        // userLoggedIn: { ...user.userLoggedIn[0] },
        userLoggedIn: {},
        documentsForSignature: props.documentsForSignature,
        id_profile: props.id_profile,
        id_client: props.id_client,
        statusvenda: props.statusvenda,
        infosClients: props.infosClients
      };
    }
    configAxios
      .post('/signing/signedDocuments', infoSignedDocuments)
      .then((response) => {
        NotificationManager.success('Documento assinado com sucesso!', 'Sucesso');
        props.getDocumentsForSinagature();
        props.getDocumentsSigning();
        props.functionGrid();
        props.RenderDocumentsForSignature();
        props.setOpenModal(false);
        setQtdDocumentsSigning(qtdDocumentSigning + 1);
        if (tpbenef) {
          props.pGetDocumentsSigning();
          setDisabledNotification(true);
        }
        if (!tpbenef) {
          props.getInfosClient();
        }
        if (response.data.changedStatus === true) {
          sendMessage();
        }
        if (qtdDocumentsForSignature === qtdDocumentSigning) {
          if (tpbenef) {
            setModalAlertOpen(true);
            updatedSignCompleted(infoSignedDocuments);
          }
        }
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para efetivar a assinatura do documento, tente novamente!',
          'Atenção'
        );
      });
  }

  function updatedSignCompleted(infoSignedDocuments) {
    configAxios.post('/clients/updatedSignCompleted', infoSignedDocuments);
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <FinishSigningSuccess
        modalAlertOpen={modalAlertOpen}
        setModalAlertOpen={setModalAlertOpen}
        id_client={props.id_client ? props.id_client : props.infosClients[0].id}
        infosClients={props.infosClients}
        disabledNotification={disabledNotification}
      />
      <BootstrapDialog
        onClose={() => {
          props.setOpenModal(false);
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 0 }
        }}
        maxWidth={'md'}
        scroll="paper"
        open={props.openModal}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.setOpenModal(false);
          }}>
          {props.documentsForSignature.nome}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <PdfViewer path={props.pdf} name={props.documentsForSignature.nome} />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={HandleSubmit}
            startIcon={<Iconify icon="eva:edit-fill" />}>
            Assinar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default signingDocuments;
