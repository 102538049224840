/* eslint-disable react/prop-types */
import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const BreadcrumbsNav = (props) => {
  function CreateRenderBreadCrumb(element) {
    if (element.active) {
      return <Typography color="text.primary">{element.name}</Typography>;
    }
    return (
      <Link
        color="inherit"
        component={RouterLink}
        to={element.path}
        sx={{ textDecoration: 'none' }}>
        {element.name}
      </Link>
    );
  }
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {props.breadcrumb.map((element, index) => {
        return <CreateRenderBreadCrumb key={index} {...element} />;
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
