import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { viaCep, validCep } from '../../../../@core/utils/utilsFunctions';
import { isMobile } from 'react-device-detect';
import { validateCPF } from '../../../../@core/utils/utilsFunctions';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import {
  Stack,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete
} from '@mui/material';

import palette from '../../../../@core/theme/palette';
import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import configAxios from '../../../../services/configAxios';

/**
 * id_type_client === 1 - Físico
 * id_type_client === 2 - Júridico
 *
 */

const addClientsDependents = (props) => {
  const [infosDeps, setInfosDeps] = useState({});
  const [infosDepsList, setInfosDepsList] = useState([]);
  const [infosWaitingPeriod, setInfosWaitingPeriod] = useState([]);
  const [infosAdressTit, setAdressTit] = useState({});
  const [infosOrgaEmissor, setInfosOrgaEmissor] = useState([]);
  const [salesValue, setSalesValue] = useState(infosDeps.valclient || '');
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    getInfosOrgaoEmissor();
    getWaitingPeriod();
    if (props.otherInfos.id_type_client === 2) {
      getDepsHolder();
    }
  }, []);

  useEffect(() => {
    setInfosDeps(props.infosDepsEdit[0] ? props.infosDepsEdit[0] : {});
    setAdressTit(
      props.infosDepsTit[0]
        ? {
            ...props.infosDepsTit[0],
            labelDeps: props.infosDepsTit[0].id + ' - ' + props.infosDepsTit[0].nome
          }
        : {}
    );
  }, [props.infosDepsEdit, props.infosDepsTit]);

  function getInfosOrgaoEmissor() {
    configAxios
      .get('utilsInfos/getInfosOrgaoEmissor')
      .then((response) => {
        setInfosOrgaEmissor(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do RG! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getDepsHolder() {
    configAxios
      .get(`/clients/getInfosHolder/${props.otherInfos.id}`)
      .then((response) => {
        setInfosDepsList(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para listar os titulares para seleção, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getWaitingPeriod() {
    await configAxios
      .get(`/waitiginPeriod/getWaitingPeriod`)
      .then((response) => {
        setInfosWaitingPeriod(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleAdressTit(event) {
    const values = event;
    setInfosDeps({
      ...infosDeps,
      id_tit: values.id,
      labelDeps: values.label
    });
    setAdressTit({ ...infosAdressTit, ...event });
  }

  function HandleInputChange(event, params = {}) {
    if (params.autocomplete) {
      if (event) {
        setInfosDeps({ ...infosDeps, label: event.label, id_orgao: event.id });
        return;
      }
      return;
    }
    if (params.cep) {
      setInfosDeps({ ...infosDeps, ...params.cep });
      return;
    }
    if (params.codcarencia) {
      setInfosDeps({ ...infosDeps, labelcarencia: event.label, codcarencia: event.codcarencia });
      return;
    }

    if (event.target.name === 'dtnascimento') {
      infosDeps.valclient = '';
      setSalesValue('');
    }
    if (event.target.name === 'tpbenef') {
      delete infosDeps.id_tit;
      setAdressTit('');
      infosDeps.labelDeps = '';
    }

    if (event.target.name === 'email') {
      event.target.value = event.target.value.toLowerCase();
    }

    setInfosDeps({ ...infosDeps, [event.target.name]: event.target.value });
  }

  async function HandleInputCep(event) {
    if (event) {
      if (event.target.value) {
        if (validCep(event.target.value)) {
          let infosCep;
          infosCep = await viaCep(event.target.value);
          if (infosCep) {
            HandleInputChange(event, { cep: infosCep });
            return;
          }
          NotificationManager.danger(
            'Tivemos dificuldades para buscar as informações do endereço, tente novamente!',
            'Atenção'
          );
        } else {
          NotificationManager.warning('Cep inválido, verifique e tente novamente!', 'Atenção');
        }
      }
    }
  }

  function CalculeValuesClient() {
    if (!infosDeps.dtnascimento) {
      NotificationManager.warning(
        'Por favor, preencha a data de nascimento para calcular!',
        'Atenção'
      );
      return;
    }
    const infosSales = {
      codtab: props.otherInfos.codtab,
      datanascimento: infosDeps.dtnascimento
    };
    configAxios
      .post('utilsInfos/calculeSales', infosSales)
      .then((response) => {
        infosDeps.valclient = response.data;
        setSalesValue(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para calcular o plano no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function validCPF(event) {
    if (event.target.value) {
      const cpfIsValid = validateCPF(event.target.value);
      if (!cpfIsValid) {
        NotificationManager.warning('CPF inválido, por favor verifique!', 'Atenção');
        setInfosDeps({ ...infosDeps, cpf_cnpj: '' });
        return;
      }
      setInfosDeps({ ...infosDeps, [event.target.name]: event.target.value });
    }
  }

  function validDtnascimento() {
    if (infosDeps.dtnascimento > new Date().toLocaleDateString().split('/').reverse().join('-')) {
      NotificationManager.warning('Por favor, escolha uma data de nascimento válida!', 'Atenção');
      infosDeps.dtnascimento = '';
    }
    return;
  }

  function HandleSubmit(event) {
    event.preventDefault();

    if (props.otherInfos.statusvenda === 'Aguardando cliente' && props.ountSigningDocuments > 0) {
      NotificationManager.warning(
        'Não é possível fazer isso em uma venda em que as assinaturas já tenham sido iniciadas!!',
        'Atenção'
      );
      return;
    }

    if (!infosDeps.valclient) {
      NotificationManager.warning('Por favor, faça o cálculo do valor do beneficiário!', 'Atenção');
      return;
    }

    infosDeps.id_client = props.otherInfos.id;
    infosDeps.typeCad = props.otherInfos.id_type_client === 2 ? 'jurídico' : 'físico';
    const router = infosDeps.id ? 'clients/updateClientDependent' : 'clients/addClientDependent';
    configAxios
      .post(router, { infosDeps })
      .then((response) => {
        if (response.status === 202) {
          NotificationManager.warning(
            'Só é permitido um titular por contrato pessoa física!',
            'Atenção;'
          );
          return;
        }
        NotificationManager.success(
          infosDeps.id ? 'Cliente atualizado com sucesso!' : 'Cadastro realizado com sucesso!',
          'Sucesso'
        );
        props.setOpenModal(false);
        props.functionGrid();
        setSalesValue('');
        setInfosDeps({});
        if (infosDeps.typeCad === 'jurídico') {
          getDepsHolder();
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
            setInfosDeps({});
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
              setInfosDeps({});
            }}>
            {props.otherInfos.id_type_client === 1
              ? infosDeps.id
                ? 'Atualizar dependente'
                : 'Cadastrar dependente'
              : infosDeps.id
              ? 'Atualizar beneficiário'
              : 'Cadastrar beneficiário'}
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Tipo de beneficiário *
                  </InputLabel>
                  <Select
                    name="tpbenef"
                    disabled={props.otherInfos.id_type_client === 2 && infosDeps.id ? true : false}
                    fullWidth
                    size="small"
                    required
                    value={infosDeps.tpbenef || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'Titular'}>Titular</MenuItem>
                    {props.otherInfos.id_type_client === 1 && (
                      <MenuItem value={'Titular menor'}>Titular menor</MenuItem>
                    )}
                    <MenuItem value={'Dependente'}>Dependente</MenuItem>
                  </Select>
                </Grid>
                {props.otherInfos.id_type_client === 2 && infosDeps.tpbenef == 'Dependente' && (
                  <Grid item xs={12} md={4} sx={{ mt: 1 }}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Selecione o titular *
                    </InputLabel>
                    <Autocomplete
                      options={infosDepsList}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleAdressTit(value)}
                      value={infosDeps.labelDeps || infosAdressTit.labelDeps || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="id_tit"
                          fullWidth
                          size="small"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="nome"
                    fullWidth
                    label="Nome completo"
                    variant="outlined"
                    size="small"
                    required
                    value={infosDeps.nome || ''}
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="nomesocial"
                    fullWidth
                    label="Nome social"
                    variant="outlined"
                    size="small"
                    value={infosDeps.nomesocial || ''}
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="dtnascimento"
                    label="Data de nascimento"
                    fullWidth
                    type="date"
                    required
                    variant="outlined"
                    size="small"
                    value={infosDeps.dtnascimento || ''}
                    onChange={(event) => HandleInputChange(event)}
                    onBlur={() => validDtnascimento()}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                <Grid item xs={12} md={4}>
                  <InputMask
                    mask="999.999.999-99"
                    value={infosDeps.cpf_cnpj || ''}
                    onChange={(event) => HandleInputChange(event)}
                    onBlur={(event) => validCPF(event)}>
                    {() => (
                      <TextField
                        name="cpf_cnpj"
                        fullWidth
                        label="CPF"
                        required
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    name="rg"
                    label="RG"
                    fullWidth
                    placeholder="Ex: 99.999.999-9"
                    required
                    variant="outlined"
                    size="small"
                    value={infosDeps.rg || ''}
                    inputProps={{ maxLength: 20 }}
                    onChange={(event) => HandleInputChange(event)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={infosOrgaEmissor}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, { autocomplete: true })}
                    value={infosDeps.label || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Orgão emissor"
                        name="orgao_emissor"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                <Grid
                  item
                  xs={12}
                  md={props.otherInfos.tpcontrat_client === '3-COLETIVO POR ADESAO' ? 3 : 4}>
                  <InputMask
                    mask="(99)99999-9999"
                    value={infosDeps.celular || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    {() => (
                      <TextField
                        name="celular"
                        fullWidth
                        label="Celular"
                        variant="outlined"
                        size="small"
                        required
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={props.otherInfos.tpcontrat_client === '3-COLETIVO POR ADESAO' ? 3 : 4}>
                  <InputMask
                    mask="(99)9999-9999"
                    onChange={(event) => HandleInputChange(event)}
                    value={infosDeps.telefone || ''}>
                    {() => (
                      <TextField
                        name="telefone"
                        fullWidth
                        label="Telefone residencial"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={props.otherInfos.tpcontrat_client === '3-COLETIVO POR ADESAO' ? 3 : 4}>
                  <TextField
                    name="email"
                    fullWidth
                    label="Email"
                    required
                    size="small"
                    type="email"
                    variant="outlined"
                    value={infosDeps.email || ''}
                    inputProps={{ maxLength: 50, style: { textTransform: 'lowerCase' } }}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                {props.otherInfos.tpcontrat_client === '3-COLETIVO POR ADESAO' && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="profissao_dep"
                      fullWidth
                      required
                      label="Profissão"
                      size="small"
                      variant="outlined"
                      inputProps={{ maxLength: 100 }}
                      value={infosDeps.profissao_dep || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="nomedamae"
                    fullWidth
                    label="Nome da mãe"
                    size="small"
                    variant="outlined"
                    required
                    value={infosDeps.nomedamae || ''}
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="cns"
                    fullWidth
                    label="Cartão nacional do SUS"
                    size="small"
                    variant="outlined"
                    required
                    value={infosDeps.cns || ''}
                    inputProps={{ maxLength: 15 }}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="decnascidovivo"
                    fullWidth
                    label="Declaração de nascido vivo"
                    size="small"
                    variant="outlined"
                    value={infosDeps.decnascidovivo || ''}
                    inputProps={{ maxLength: 15 }}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0.1 }}>
                {infosDeps.tpbenef === 'Dependente' && props.otherInfos.id_type_client !== 1 && (
                  <Grid item xs={12} md={2}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Parentesco *
                    </InputLabel>
                    <Select
                      name="parentesco"
                      fullWidth
                      size="small"
                      required
                      value={infosDeps.parentesco || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'Pai'}>Pai</MenuItem>
                      <MenuItem value={'Mãe'}>Mãe</MenuItem>
                      <MenuItem value={'Filho(a)'}>Filho(a)</MenuItem>
                      <MenuItem value={'Conjuge'}>Conjuge</MenuItem>
                      <MenuItem value={'Enteado'}>Enteado(a)</MenuItem>
                      <MenuItem value={'Dep. Especial'}>Dep. especial</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                  </Grid>
                )}
                {infosDeps.tpbenef === 'Dependente' && props.otherInfos.id_type_client == 1 && (
                  <Grid item xs={12} md={2}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Parentesco *
                    </InputLabel>
                    <Select
                      name="parentesco"
                      fullWidth
                      size="small"
                      required
                      value={infosDeps.parentesco || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'Pai'}>Pai</MenuItem>
                      <MenuItem value={'Mãe'}>Mãe</MenuItem>
                      <MenuItem value={'Filho(a)'}>Filho(a)</MenuItem>
                      <MenuItem value={'Conjuge'}>Conjuge</MenuItem>
                      <MenuItem value={'Enteado'}>Enteado(a)</MenuItem>
                      <MenuItem value={'Dep. Especial'}>Dep. especial</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={
                    infosDeps.tpbenef === 'Dependente' && props.otherInfos.id_type_client !== 1
                      ? 3
                      : 3
                  }>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Sexo *
                  </InputLabel>
                  <Select
                    name="sexo"
                    fullWidth
                    size="small"
                    required
                    value={infosDeps.sexo || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'Masculino'}>Masculino</MenuItem>
                    <MenuItem value={'Feminino'}>Feminino</MenuItem>
                    <MenuItem value={'Outros'}>Outros</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={
                    infosDeps.tpbenef === 'Dependente' && props.otherInfos.id_type_client !== 1
                      ? 3
                      : 3
                  }>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Estado civil *
                  </InputLabel>
                  <Select
                    name="estadocivil"
                    fullWidth
                    size="small"
                    required
                    value={infosDeps.estadocivil || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'Solteiro(a)'}>Solteiro(a)</MenuItem>
                    <MenuItem value={'Casado(a)'}>Casado(a)</MenuItem>
                    <MenuItem value={'Divorciado(a)'}>Divorciado(a)</MenuItem>
                    <MenuItem value={'Viuvo(a)'}>Viuvo(a)</MenuItem>
                    {infoState.tipo !== 'Complexo' && (
                      <MenuItem value={'Companheiro(a)'}>Companheiro(a)</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={12} md={props.otherInfos.id_type_client === 1 ? 4 : 4}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Produto/Plano *
                  </InputLabel>
                  <TextField
                    name="codplano"
                    fullWidth
                    disabled
                    required
                    size="small"
                    variant="outlined"
                    value={props.otherInfos.labelplans || ''}
                  />
                </Grid>
                <Grid item xs={12} md={props.otherInfos.id_type_client === 1 ? 4 : 4}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Tabela *
                  </InputLabel>
                  <TextField
                    name="codtab"
                    fullWidth
                    disabled
                    required
                    size="small"
                    variant="outlined"
                    value={props.otherInfos.labeltables || ''}
                  />
                </Grid>

                <Grid item xs={12} md={props.otherInfos.id_type_client === 1 ? 3 : 4}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Carência do beneficiário *
                  </InputLabel>
                  <Autocomplete
                    options={infosWaitingPeriod}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, { codcarencia: true })}
                    value={infosDeps.labelcarencia || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="codcarencia"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={props.otherInfos.id_type_client === 1 ? 3 : 2}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Valor *
                  </InputLabel>
                  <TextField
                    name="valclient"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={`R$ ${infosDeps.valclient || salesValue}`}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                {isMobile && (
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="end">
                      <Button
                        sx={!isMobile ? { mt: 2.7 } : { mt: 1 }}
                        variant="contained"
                        color="success"
                        onClick={CalculeValuesClient}
                        startIcon={<Iconify icon="ant-design:calculator-outlined" />}>
                        Calcular
                      </Button>
                    </Stack>
                  </Grid>
                )}
                {!isMobile && (
                  <Grid item xs={2} md={2}>
                    <Button
                      sx={!isMobile ? { mt: 2.7 } : { mt: 1 }}
                      variant="contained"
                      color="success"
                      onClick={CalculeValuesClient}
                      startIcon={<Iconify icon="ant-design:calculator-outlined" />}>
                      Calcular
                    </Button>
                  </Grid>
                )}
              </Grid>
              {props.otherInfos.id_type_client === 2 && (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                    <Grid item xs={12} md={2}>
                      <InputMask
                        mask="99999-999"
                        disabled={infosDeps.tpbenef === 'Dependente' ? true : false}
                        onChange={(event) => HandleInputChange(event)}
                        onBlur={(event) => HandleInputCep(event)}
                        value={infosDeps.cep || infosAdressTit.cep || ''}>
                        {() => (
                          <TextField
                            name="cep"
                            fullWidth
                            disabled={infosDeps.tpbenef === 'Dependente' ? true : false}
                            label="CEP"
                            variant="outlined"
                            size="small"
                            required
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="rua"
                        fullWidth
                        label="Rua"
                        required
                        size="small"
                        disabled={infosDeps.tpbenef === 'Dependente' ? true : false}
                        variant="outlined"
                        onChange={(event) => HandleInputChange(event)}
                        value={infosDeps.rua || infosAdressTit.rua || ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        name="numero"
                        fullWidth
                        label="Número"
                        value={infosDeps.numero || infosAdressTit.numero || ''}
                        required
                        disabled={infosDeps.tpbenef === 'Dependente' ? true : false}
                        size="small"
                        variant="outlined"
                        type="text"
                        onChange={(event) => HandleInputChange(event)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        name="complemento"
                        fullWidth
                        disabled={infosDeps.tpbenef === 'Dependente' ? true : false}
                        label="Complemento"
                        size="small"
                        value={infosDeps.complemento || infosAdressTit.complemento || ''}
                        variant="outlined"
                        onChange={(event) => HandleInputChange(event)}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="bairro"
                        fullWidth
                        label="Bairro"
                        disabled={infosDeps.tpbenef === 'Dependente' ? true : false}
                        required
                        size="small"
                        variant="outlined"
                        onChange={(event) => HandleInputChange(event)}
                        value={infosDeps.bairro || infosAdressTit.bairro || ''}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="cidade"
                        fullWidth
                        label="Cidade"
                        disabled={
                          infoState.id_type_user === 1 && infosDeps.tpbenef !== 'Dependente'
                            ? false
                            : true
                        }
                        variant="outlined"
                        size="small"
                        required
                        value={infosDeps.cidade || infosAdressTit.cidade || ''}
                        onChange={(event) => HandleInputChange(event)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="estado"
                        fullWidth
                        label="Estado"
                        disabled={
                          infoState.id_type_user === 1 && infosDeps.tpbenef !== 'Dependente'
                            ? false
                            : true
                        }
                        required
                        size="small"
                        variant="outlined"
                        value={infosDeps.estado || infosAdressTit.estado || ''}
                        onChange={(event) => HandleInputChange(event)}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions sx={{ mt: 2 }}>
              <Button
                color="success"
                variant="contained"
                type="submit"
                disabled={
                  (infoState.id_type_user === 2 && props.otherInfos.statusvenda !== 'Aberta') ||
                  props.otherInfos.statusvenda === 'Aprovada' ||
                  props.countSigningDocuments > 0
                    ? true
                    : false
                }
                startIcon={<Iconify icon="eva:save-fill" />}>
                {infosDeps.id ? 'Atualizar' : 'Salvar'}
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  setInfosDeps({});
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default addClientsDependents;
