import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { selectLogin } from '../../../../store/login/loginSlice';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { validateCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Box
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';
import { viaCep, validCep } from '../../../../@core/utils/utilsFunctions';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import InputMask from 'react-input-mask';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import LinkPlans from './LinkPlans';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import palette from 'src/@core/theme/palette';

const AddEditAssociation = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const [infosAssociation, setInfosAssociation] = useState(state ? { ...state.infosEdit } : {});
  const [infosPlans, setInfosPlans] = useState({});
  const [openModalPlans, setOpenModalPlans] = useState(false);
  const [value, setValue] = useState('1');

  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editAssociation')
      [navigation('/app/associations')];
    if (infosAssociation.id) {
      getPlans();
    }
  }, []);

  function getPlans() {
    configAxios
      .get(`/association/listPlansAssociation/${infosAssociation.id}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function LinktemsModalPlans() {
    setOpenModalPlans(true);
  }

  function ActionsButtonsPlans(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/association/deletePlansAssociation',
          deleteItem: 'o plano da associação',
          functionGrid: getPlans,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  async function HandleInputCep(event) {
    if (event.target.value) {
      if (validCep(event.target.value)) {
        let infosCep;
        infosCep = await viaCep(event.target.value);
        if (infosCep) {
          HandleInputChange({ ...infosCep }, true);
          return;
        }
        NotificationManager.danger(
          'Tivemos dificuldades para buscar as informações do endereço, tente novamente!',
          'Atenção'
        );
      } else {
        NotificationManager.warning('Cep inválido, verifique e tente novamente!', 'Atenção');
      }
    }
  }

  function HandleInputChange(event, cep, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosAssociation({
          ...infosAssociation,
          categoria: event.label,
          codcategory: event.codcategory
        });
        return;
      }
      return;
    }
    if (cep) {
      const data = {
        endereco: event.rua,
        bairro: event.bairro,
        cidade: event.cidade,
        uf: event.estado
      };
      setInfosAssociation({ ...infosAssociation, ...data });
      return;
    }

    setInfosAssociation({ ...infosAssociation, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosAssociation.id
      ? '/association/updateAssociation'
      : '/association/addAssociation';
    configAxios
      .post(router, infosAssociation)
      .then((response) => {
        const message = infosAssociation.id
          ? 'Associação atualizada com sucesso!'
          : 'Associação inserida com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        setInfosAssociation({
          ...infosAssociation,
          id: response.data.id
        });
        const nav = infosAssociation.id
          ? `/app/editAssociations/${infosAssociation.id}`
          : `/app/editAssociations/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosAssociation,
              id: infosAssociation.id ? infosAssociation.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosAssociation({ ...infosAssociation, cnpj: '' });
        return;
      }
      setInfosAssociation({ ...infosAssociation, [event.target.name]: event.target.value });
    }
  }

  function handleTabsChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <RenderPage title="Associações">
      <>
        <LinkPlans
          openModal={openModalPlans}
          setOpenModal={setOpenModalPlans}
          functionGrid={getPlans}
          otherInfos={infosAssociation}
        />
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de associações
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar as associações
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/associations"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box
                sx={{
                  color: palette.primary.contrastText,
                  width: '100%',
                  mt: 1,
                  border: 1,
                  borderColor: 'grey.200',
                  p: 2
                }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>

                <Grid
                  container
                  spacing={1}
                  sx={{ mt: 2, input: { color: palette.primary.contrastText } }}>
                  {infosAssociation.id && (
                    <Grid item xs={2} md={1}>
                      <TextField
                        name="id"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosAssociation.id || ''}
                        disabled
                      />
                    </Grid>
                  )}

                  {infosAssociation.codigoEmpresa && (
                    <Grid item xs={2} md={2}>
                      <TextField
                        name="codigoEmpresa"
                        fullWidth
                        label="Cod. Empresa"
                        variant="outlined"
                        size="small"
                        required
                        value={infosAssociation.codigoEmpresa || ''}
                        disabled
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="status"
                      fullWidth
                      size="small"
                      required
                      value={infosAssociation.status || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'ATIVO'}>
                        Ativo
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'INATIVO'}>
                        Inativo
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="99/99/9999"
                      value={infosAssociation.dtInicio || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="dtInicio"
                          fullWidth
                          label="Data início"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  {infosAssociation.status === 'INATIVO' ? (
                    <Grid item xs={12} md={2}>
                      <InputMask
                        mask="99/99/9999"
                        value={infosAssociation.dtFim || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        {() => (
                          <TextField
                            name="dtFim"
                            fullWidth
                            label="Data Fim"
                            required
                            variant="outlined"
                            size="small"
                          />
                        )}
                      </InputMask>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>

                {/* Dados da Empresa */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Dados da empresa
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="nomeEmpresa"
                      fullWidth
                      label="Nome fantasia"
                      variant="outlined"
                      size="small"
                      required
                      value={infosAssociation.nomeEmpresa || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="razaoSocial"
                      fullWidth
                      label="Razão social"
                      variant="outlined"
                      size="small"
                      required
                      value={infosAssociation.razaoSocial || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={4}>
                    <InputMask
                      mask="99.999.999/0009-99"
                      value={infosAssociation.cnpj || ''}
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => validCNPJ(event)}>
                      {() => (
                        <TextField
                          name="cnpj"
                          fullWidth
                          label="CNPJ"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputMask
                      mask="999.999.999.999"
                      value={infosAssociation.ie || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="ie"
                          fullWidth
                          label="Inscrição Estadual"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputMask
                      mask="999.999.999-99"
                      value={infosAssociation.cpfresp || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="cpfresp"
                          fullWidth
                          label="CPF Responsável"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                {/* contato da Empresa */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Contatos da empresa
                </CustomInfosForm>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)9999-9999"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.telefone || ''}>
                      {() => (
                        <TextField
                          name="telefone"
                          fullWidth
                          label="Telefone"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)99999-9999"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.celular || ''}>
                      {() => (
                        <TextField
                          name="celular"
                          fullWidth
                          label="Celular"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)99999-9999"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.fax || ''}>
                      {() => (
                        <TextField
                          name="fax"
                          fullWidth
                          label="Fax / Celular2"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Endereço da empresa
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="99999-999"
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => HandleInputCep(event)}
                      value={infosAssociation.cep || ''}>
                      {() => (
                        <TextField
                          name="cep"
                          fullWidth
                          label="CEP"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="endereco"
                      fullWidth
                      label="Endereço"
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.endereco || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="numero"
                      fullWidth
                      label="Número"
                      value={infosAssociation.numero || ''}
                      required
                      size="small"
                      variant="outlined"
                      type="number"
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="complemento"
                      fullWidth
                      label="Complemento"
                      size="small"
                      value={infosAssociation.complemento || ''}
                      inputProps={{ maxLength: 100 }}
                      variant="outlined"
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="bairro"
                      fullWidth
                      label="Bairro"
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.bairro || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="cidade"
                      fullWidth
                      label="Cidade"
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      value={infosAssociation.cidade || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="uf"
                      fullWidth
                      label="Estado"
                      disabled
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.uf || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>

                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Dados fiscais da empresa
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Regime tributário *
                    </InputLabel>
                    <Select
                      name="categoriaEmpresa"
                      fullWidth
                      size="small"
                      required
                      sx={{ color: palette.primary.contrastText }}
                      value={infosAssociation.categoriaEmpresa || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'1'}>
                        Micro empreendedor Individual
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'2'}>
                        Simples Nacional
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'3'}>
                        Lucro Presumido
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'4'}>
                        Lucro Real
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Modalidade de recebimento*
                    </InputLabel>
                    <Select
                      name="tipoConta"
                      fullWidth
                      size="small"
                      required
                      sx={{ color: palette.primary.contrastText }}
                      value={infosAssociation.tipoConta || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'1'}>
                        Individual
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'2'}>
                        Boleto Bancário
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Dia Pagto *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="diaPagto"
                      fullWidth
                      size="small"
                      required
                      value={infosAssociation.diaPagto || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'5'}>
                        5
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'10'}>
                        10
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'15'}>
                        15
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'20'}>
                        20
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'25'}>
                        25
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      name="pis"
                      fullWidth
                      label="PIS"
                      variant="outlined"
                      size="small"
                      value={infosAssociation.pis || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Gera NF *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="geraNf"
                      fullWidth
                      size="small"
                      required
                      value={infosAssociation.geraNf || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'true'}>
                        Sim
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'false'}>
                        Não
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="9.99"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.ir || ''}>
                      {() => (
                        <TextField
                          name="ir"
                          fullWidth
                          label="% I.R."
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="9.99"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.cofins || ''}>
                      {() => (
                        <TextField
                          name="cofins"
                          fullWidth
                          label="% Cofins"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="9.99"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.iss || ''}>
                      {() => (
                        <TextField
                          name="iss"
                          fullWidth
                          label="% iss"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="9.99"
                      onChange={(event) => HandleInputChange(event)}
                      value={infosAssociation.csl || ''}>
                      {() => (
                        <TextField
                          name="csl"
                          fullWidth
                          label="% csl"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Outras informações da empresa
                </CustomInfosForm>
                <Grid
                  container
                  spacing={1}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="obs"
                      fullWidth
                      label="Observações sobre a associação"
                      variant="outlined"
                      size="small"
                      value={infosAssociation.obs || ''}
                      inputProps={{ maxLength: 300 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
              </Box>

              {infosAssociation.id && (
                <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                  <TabContext value={value}>
                    <Box sx={{ mt: 3, p: 1 }}>
                      <TabList onChange={handleTabsChange} aria-label="AssociationTabs">
                        <Tab label="Planos" value="1" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm>Adicionar e/ou remover planos</CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinktemsModalPlans}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getPlans}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinktemsModalPlans}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosPlans}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há planos vinculados'}>
                        <Column dataField="id" width={70} />
                        <Column dataField="codplano" caption="Código do plano" />
                        <Column dataField="descplano" caption="Descrição do plano" />
                        <Column dataField="tpcontrat" caption="Tipo de contratação" />
                        <Column dataField="status" caption="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsPlans data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosAssociation.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/association"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};
export default AddEditAssociation;
