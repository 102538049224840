/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import download from 'js-file-download';
import {
  CustomGrid,
  CustomInfosForm,
  TextareaAutosize
} from '../../../../assets/style/globalStyle';
import palette from '../../../../@core/theme/palette';
import { validateCNPJ, validateCPF } from '../../../../@core/utils/utilsFunctions';
import { NotificationManager } from 'react-notifications';
import { DropzoneArea } from 'mui-file-dropzone';
import { useSelector } from 'react-redux';
import { selectLogin, selectClient } from '../../../../store/login/loginSlice';
import { isMobile } from 'react-device-detect';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import {
  selectNotification,
  selectNotificationClient,
  client
} from '../../../../store/clients/clientsSlice';
import { formatDate, removeAccents, viaCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  InputAdornment,
  IconButton,
  Tab,
  Box,
  Badge,
  Alert
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';
import { startAndStop } from '../../../../store/loading/loadingSlice';
import configAxios from '../../../../services/configAxios';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InputMask from 'react-input-mask';
import LockResetIcon from '@mui/icons-material/LockReset';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import useWebSocket from 'react-use-websocket';
import ReactShortcut from 'react-shortcut';
import Iconify from '../../../../@core/theme/Iconify';
import RenderPage from '../../../layouts/RenderPage';
import AddEditClientsDependents from './AddEditClientsDependents';
import DiscussionClients from './DiscussionClients';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import MoreMenuDocuments from '../../../components/multiplesComponents/MoreMenuDocuments';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import SendEmailDocuments from './SendEmailDocuments';
import HistorySales from './HistorySales';
import IntegrationClients from './IntegrationClients';
import AddEditDeclarationHealth from '../signature/AddDeclarationHealth';
import DocumentsForSignature from '../signature/DocumentsForSignature';
import DownloadDocumentsSigning from '../signature/DownloadDocumentsSigning';
import AddEditAddress from './AddEditAddress';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PendingHealthDeclaration from '../signature/PendingHealthDeclaration';
import MaintenanceClients from './MaintenanceClients';

/**
 * id_type_client === 1 - Físico
 * id_type_client === 2 - Júridico
 *
 */

const AddEditClients = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const allowedPageSizes = [5, 10, 20, 30];
  const dispatch = useDispatch();
  const [infosClients, setInfosClients] = useState(state ? { ...state.infosEdit } : {});
  const [initialInfosClients] = useState(state ? { ...state.infosEdit } : {});
  const [infosDeps, setInfosDeps] = useState([]);
  const [infosAddress, setInfosAddress] = useState({});
  const [infosAdressEdit, setInfosAddressEdit] = useState({});
  const [infosDisabledStatusVenda] = useState([
    'Rejeitada',
    'Aguardando cliente',
    'Aguardando corretora',
    'Aguardando operadora',
    'Aguardando administradora',
    'Aprovada',
    'Negada'
  ]);
  const [infosDepsTit, setInfosDepsTit] = useState({});
  const [infosDepsEdit, setInfosDepEdit] = useState({});
  const [infosOrgaEmissor, setInfosOrgaEmissor] = useState([]);
  const [infosPlans, setInfosPlans] = useState([]);
  const [infosTables, setInfosTables] = useState([]);
  const [infosTablesTemp, setInfosTablesTemp] = useState([]);
  const [infosWaitingPeriod, setInfosWaitingPeriod] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAddress, setOpenModalAddress] = useState(false);
  const [openModalDiscussions, setOpenModalDiscussion] = useState(false);
  const [salesValue, setSalesValue] = useState(infosClients.valclient || '');
  const infoStateTemp = useSelector(selectLogin);
  const notification = useSelector(selectNotification);
  const selectNotificationsClient = useSelector(selectNotificationClient);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const infosClient = useSelector(selectClient);
  const [infoSelectVend, setInfoSelectVend] = useState([]);
  const [infosUploads, setInfosUploads] = useState([]);
  const [infosAssociations, setInfosAssociation] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const key = useRef('');
  const [value] = useDebounce(key.current, 1000);
  const keys = useRef(1);
  const [valueKeys] = useDebounce(keys.current, 1000);
  const [values, setValues] = useState('1');
  const [countMessages, setCountMessages] = useState([]);
  const [countSigningDocuments, setCountSigningDocuments] = useState(
    infosClients.id && infoState.id_type_user === 1 ? 1 : 0
  );
  const [openModalSendEmailDocuments, setOpenModalSendEmailDocuments] = useState(false);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [openModalIntegration, setOpenModalIntegration] = useState(false);
  const [infosHistoryEmail, setInfosHistoryEmail] = useState([]);
  const [infosHistorySales, setInfosHistorySales] = useState([]);
  const [infosHistoryIntegration, setHistoryIntegration] = useState([]);
  const [resultIntegration, setResultIntegration] = useState([]);
  const [infosContract, setInfosContract] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [openModalDeclarationHealth, setOpenModalDeclarationHealth] = useState(false);
  const [showDeclaration, setShowDeclaration] = useState(false);
  const [infosQuestions, setInfosQuestions] = useState([]);
  const [responseQuestions, setResponseQuestions] = useState([]);
  const [openModalPendingHealth, setOpenModalPendingHealth] = useState(false);
  const [pendingInfosDeclarationHealth, setPendingInfosDeclarationHealth] = useState([]);
  const [headerModalPending, setHeaderModalPending] = useState('');
  const [openMaintenance, setOpenMaintenance] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const company = useSelector(selectClient);

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editClients') [navigation('/app/clients')];

    if (pathNameSplice[2] === 'addClients' && infoState.id_type_user !== 2)
      [navigation('/app/clients')];

    if (infosClients.tpcontrat_client !== '3-COLETIVO POR ADESAO') {
      getPlans(infosClients.tpcontrat_client);
      getTables();
    } else {
      getPlansAssociation(infosClients.id_association, infosClients.id_broker);
      getTablesAssociation(infosClients.id_association);
    }

    if (infosClients.id) {
      Promise.all([
        getDependents(),
        getCountMessages(),
        getHistoryEmails(),
        getHistorySales(),
        getHistoryIntegration(),
        getAddress(),
        getCountDocumentsSigning()
      ]);
    }

    if (
      infosClients.statusvenda === 'Aguardando corretora' ||
      infosClients.statusvenda === 'Aguardando operadora' ||
      infosClients.statusvenda === 'Aguardando administradora' ||
      infosClients.statusvenda === 'Aprovada'
    ) {
      getInfosContract();
      getInfosQuestions();
    }

    Promise.all([
      getInfoSelectVend(),
      getInfosOrgaoEmissor(),
      getFilesUploads(),
      getWaitingPeriod(),
      getAssociations()
    ]);
  }, [infosClients.id]);

  useEffect(() => {
    if (Object.keys(selectNotificationsClient).length) {
      setInfosClients({ ...selectNotificationsClient });
      dispatch(client({}));
      keys.current = Math.random();
    }
  }, [notification]);

  const wsURL =
    process.env.NODE_ENV === 'production' ? 'wss://app.phorm.com.br:3001' : 'ws://localhost:3001';

  /** parâmetros para disparar notificação */
  let queryParams = {
    'x-access-token': infoStateTemp.token,
    eventType: 'message',
    direction: 'corretora',
    id_vend: infosClients.id_user
  };

  if (infoState.id_type_user === 2) {
    queryParams = {
      'x-access-token': infoStateTemp.token,
      eventType: 'message',
      direction: 'operadora'
    };
  }
  /** dispara a notificação */
  const { sendMessage } = useWebSocket(wsURL, {
    queryParams,
    share: true
  });

  /** parâmetros para ouvir a notificação e atualizar o status da venda */
  let queryParamsConnection = {
    'x-access-token': infoStateTemp.token,
    eventType: 'connection',
    type: 'operadora'
  };

  if (infoState.id_type_user === 2) {
    queryParamsConnection = {
      'x-access-token': infoStateTemp.token,
      eventType: 'connection',
      type: 'corretora',
      id_vend: infoState.id
    };
  }

  /** ouve a notificação e chama as funções para atualizar as informações da venda */
  useWebSocket(wsURL, {
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      if (infosClients.id == data.id_client) {
        getInfosClient();
        getCountDocumentsSigning();
        getHistorySales();
      }
    },
    queryParams: queryParamsConnection,
    shouldReconnect: () => true,
    reconnectInterval: 3000
  });

  async function getHistoryEmails() {
    await configAxios
      .get(`/sendEmail/getSendEmailsDocuments/${infosClients.id}`)
      .then((result) => {
        setInfosHistoryEmail(result.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os emails enviados, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getHistorySales() {
    const id_client = infosClients.id;
    if (infoState.id_type_user === 1 && id_client) {
      configAxios
        .get(`/clients/getHistorySales/${id_client}`)
        .then((result) => {
          setInfosHistorySales(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar o histórico de movimentações, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function getHistoryIntegration() {
    const id_client = infosClients.id;
    if (infoState.id_type_user === 1 && id_client) {
      configAxios
        .get(`/integration/listHistoryIntegration/${id_client}`)
        .then((result) => {
          const data = result.data;
          setResultIntegration(data.filter((element) => element.status === 'Integrada'));
          setHistoryIntegration(data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar o histórico de integrações, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function getInfosClient() {
    configAxios
      .get(`/clients/getClientById/${infosClients.id}`)
      .then((response) => {
        let valuesSalesDeps = 0;
        if (Object.keys(infosDeps).length) {
          infosDeps.map((element) => {
            valuesSalesDeps += +element.valclient;
          });
        }

        setInfosClients({
          ...response.data,
          valueTotal: (Number(valuesSalesDeps) + Number(infosClients.valclient)).toFixed(2)
        });

        key.current = Math.random();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do cliente, tente novamente!',
          'Atenção'
        );
      });
  }

  function getCountMessages() {
    const userCount = infoState.id_type_user === 1 ? 'operadora' : 'corretora';
    configAxios
      .get(`/clients/getCountMessages/${infosClients.id}/${userCount}`)
      .then((result) => {
        setCountMessages(result.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as mensagens, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getCountDocumentsSigning() {
    if (infoState.id_type_user === 1) {
      await configAxios
        .get(`/clients/getCountDocumentsSigning/${infosClients.id}`)
        .then((result) => {
          setCountSigningDocuments(Number(result.data.count));
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos assinados, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function getDependents() {
    await configAxios
      .get(`/clients/infosClientsDependents/${infosClients.id}`)
      .then((response) => {
        setInfosDeps(response.data);
        calculateValuesForSales(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os dependentes, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getInfosContract() {
    const router =
      infosClients.id_type_client === 1
        ? `/clients/getInfosSignaturePF/${infosClients.id}`
        : `/clients/getInfosSignaturePJ/${infosClients.id}/null/resp_jur`;

    await configAxios
      .get(router)
      .then((result) => {
        setInfosContract(result.data.health);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as informações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function getInfoSelectVend() {
    if (infosClients.id) {
      let idGetVend = infosClients.id_broker ? infosClients.id_broker : infoState.id_broker;
      configAxios
        .get(`/brokers/listVendLabel/${idGetVend}`)
        .then((response) => {
          setInfoSelectVend(response.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar a lista de vendedores! Tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function getInfosOrgaoEmissor() {
    configAxios
      .get('utilsInfos/getInfosOrgaoEmissor')
      .then((response) => {
        setInfosOrgaEmissor(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do RG! Tente novamente!',
          'Atenção'
        );
      });
  }

  async function getPlans(tpcontrat = undefined) {
    let id_broker = infosClients.id_broker ? infosClients.id_broker : infoState.id_broker;
    let codcontratotpcontrat;

    if (tpcontrat) {
      codcontratotpcontrat = '01';
      if (tpcontrat === '2-COLETIVO EMPRESARIAL') {
        codcontratotpcontrat = '02';
      }
    }
    await configAxios
      .get(`products/getPlansLabel/${id_broker}/${codcontratotpcontrat}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do Plano! Tente novamente!',
          'Atenção'
        );
      });
  }

  async function getTables() {
    let id_broker = infosClients.id_broker ? infosClients.id_broker : infoState.id_broker;

    await configAxios
      .get(`/tables/getTablesLabel/${id_broker}`)
      .then((response) => {
        setInfosTables(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getPlansAssociation(id_association, id_broker) {
    const idBroker = id_broker ? id_broker : infoState.id_broker;

    if (id_association) {
      configAxios
        .get(`products/getPlansAssociationLabel/${id_association}/${idBroker}`)
        .then((response) => {
          setInfosPlans(response.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar as informações do Plano! Tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function getTablesAssociation(id_association) {
    if (id_association) {
      await configAxios
        .get(`/tables/getTablesAssociationLabel/${id_association}`)
        .then((response) => {
          setInfosTables(response.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function getWaitingPeriod() {
    await configAxios
      .get(`/waitiginPeriod/getWaitingPeriod`)
      .then((response) => {
        setInfosWaitingPeriod(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das carências! Tente novamente!',
          'Atenção'
        );
      });
  }

  async function getAssociations() {
    let id_broker = infosClients.id_broker ? infosClients.id_broker : infoState.id_broker;
    await configAxios
      .get(`/association/listAssociationLabel/${id_broker}`)
      .then((response) => {
        setInfosAssociation(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das associações! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getFilesUploads() {
    if (infosClients.id) {
      configAxios
        .get(`/uploads/listUploads/${infosClients.id}`)
        .then((response) => {
          setInfosUploads(response.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os uploads, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function getAddress() {
    configAxios
      .get(`/address/listAddress/null/${infosClients.id}`)
      .then((response) => {
        setInfosAddress(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os endereços no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function AddAddressModal(id) {
    if (id) {
      const valuesEdit = infosAddress.filter((element) => element.id === id);
      setInfosAddressEdit(valuesEdit);
    }
    setOpenModalAddress(true);
  }

  function calculateValuesForSales(infosDepsValues) {
    let valuesSalesDeps = 0;
    if (Object.keys(infosDepsValues).length) {
      infosDepsValues.map((element) => {
        valuesSalesDeps += +element.valclient;
      });
    }
    setInfosClients({
      ...infosClients,
      valueTotal: (Number(valuesSalesDeps) + Number(infosClients.valclient)).toFixed(2)
    });
  }

  function HandleGetSimulation(event) {
    if (event.target.value) {
      configAxios
        .get(
          `/simulations/getSimulationByCodSimulation/${event.target.value}/${infoState.id_broker}/${infoState.id}`
        )
        .then((result) => {
          if (!result.data.length) {
            NotificationManager.warning(
              'Simulação não encontrada ou já está em uso, tente novamente!',
              'Atenção'
            );
            infosClients.codsimulation = '';
            return;
          }
          delete result.data[0].id;
          setInfosClients({ ...infosClients, valueTotal: 0, ...result.data[0] });
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar a simulação, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function validCPF(event) {
    if (event.target.value) {
      const cpfIsValid = validateCPF(event.target.value);
      if (infosClients.id_type_client === 1) {
        if (!cpfIsValid) {
          NotificationManager.warning('CPF inválido, por favor verifique!', 'Atenção');
          setInfosClients({ ...infosClients, cpf_cnpj: '' });
          return;
        }
        setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
      } else {
        if (!cpfIsValid) {
          NotificationManager.warning('CPF inválido, por favor verifique!', 'Atenção');
          setInfosClients({ ...infosClients, cpf_cnpj_resp: '' });
          return;
        }
        setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
      }
    }
  }

  async function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosClients({ ...infosClients, cpf_cnpj: '' });
        return;
      }
      let infosCNPJ = false;
      if (!infosClients.nome && !infosClients.nomefantasia) {
        infosCNPJ = await viaCNPJ(event.target.value);
      }
      if (infosCNPJ) {
        setInfosClients({
          ...infosClients,
          ['nome']: infosCNPJ.nome,
          ['nomefantasia']: infosCNPJ.nomefantasia,
          [event.target.name]: event.target.value
        });
        return;
      }
      setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
    }
  }

  function showDeclarationHealthModal() {
    if (!rowSelected) {
      NotificationManager.warning(
        'Selecione o beneficiário para visualizar a declaração de saúde!',
        'Atenção'
      );
      return;
    }

    if (rowSelected.completedhealthdeclaration === false) {
      NotificationManager.warning(
        'Declaração ainda não preenchida, selecione o botão preencher para preencher a declaração!',
        'Atenção'
      );
      return;
    }
    getResponsesQuestions();
    setShowDeclaration(true);
    setOpenModalDeclarationHealth(true);
  }

  function selectedRow(event) {
    setRowSelected(event.selectedRowsData[0]);
  }

  async function getResponsesQuestions() {
    const flag = rowSelected.flag ? 'tb_clients' : 'tb_clients_dep';
    const id = flag === 'tb_clients' ? infosClients.id : rowSelected.id;

    const router =
      infosClients.id_type_client === 1
        ? `/questions/getResponsesQuestionsPF/${id}/${flag}`
        : `/questions/getResponsesQuestionsPJ/${rowSelected.id}`;

    await configAxios
      .get(router)
      .then((response) => {
        setResponseQuestions(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as informações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getInfosQuestions() {
    await configAxios
      .get('/questions/listQuestions')
      .then((response) => {
        setInfosQuestions(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as informações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, params = {}) {
    if (params.autocomplete) {
      if (event) {
        setInfosClients({ ...infosClients, label: event.label, id_orgao: event.id });
        return;
      }
      return;
    }
    if (params.plan) {
      if (event) {
        const infosTablesTemp = infosTables.filter(
          (element) => element.codplano === event.codplano
        );

        setInfosTablesTemp([]);
        infosTablesTemp.unshift({ id: '', label: '' });
        setInfosTablesTemp(infosTablesTemp);
        infosClients.labeltables = '';
        setSalesValue('');
        infosClients.codtab = '';

        setInfosClients({
          ...infosClients,
          labelplans: event.label,
          codplano: event.codplano
        });

        if (infosClients.id) {
          NotificationManager.warning(
            'Ao alterar o plano e salvar, os planos dos titulares e dependentes serão recalculados com base no novo plano/tabela!',
            'Atenção'
          );
        }
        return;
      }
      return;
    }
    if (params.table) {
      if (event) {
        setInfosClients({ ...infosClients, labeltables: event.label, codtab: event.codtab });
        setSalesValue('');
        if (infosClients.id) {
          NotificationManager.warning(
            'Ao alterar a tabela e salvar, os planos dos titulares e dependentes serão recalculados com base no novo plano/tabela!',
            'Atenção'
          );
        }
        return;
      }
      return;
    }
    if (params.cep) {
      setInfosClients({ ...infosClients, ...params.cep });
      return;
    }
    if (params.vend) {
      setInfosClients({ ...infosClients, labelvend: event.label, id_user: event.id });
      return;
    }
    if (params.waitingPeriod) {
      if (event) {
        setInfosClients({
          ...infosClients,
          labelcarencia: event.label,
          codcarencia: event.codcarencia
        });
        return;
      }
      return;
    }

    if (params.association) {
      if (event) {
        clearPlansAndTables();

        setInfosClients({
          ...infosClients,
          labelassociation: event.label,
          id_association: event.id_association
        });

        getPlansAssociation(event.id_association, infosClients.id_broker);
        getTablesAssociation(event.id_association);

        return;
      }
      return;
    }

    if (event.target.name === 'dtnascimento') {
      setSalesValue('');

      if (event.target.value > new Date()) {
        NotificationManager.warning('Data de nascimento inválida, verifique!', 'Atenção');
        return;
      }
    }

    if (event.target.name === 'titbenef') {
      delete infosClients.valclient;
      setSalesValue('');
    }

    if (event.target.name === 'tpcontrat_client') {
      clearPlansAndTables();
      infosClients.titbenef = '';

      if (event.target.value === '3-COLETIVO POR ADESAO') {
        if (infosClients.id_type_client !== 1) {
          NotificationManager.warning(
            'Só é possível vincular o tipo de contratação coletivo por adesão a clientes pessoa física!',
            'Atenção'
          );
          return;
        }
        infosClients.titbenef = 'S';

        getPlansAssociation(infosClients.id_association, infosClients.id_broker);
        getTablesAssociation(infosClients.id_association);

        setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
      } else {
        if (infosClients.id_type_client !== 1 && event.target.value === '1-INDIVIDUAL/FAMILIAR') {
          NotificationManager.warning(
            'Só é possível vincular o tipo de contratação invidual/familiar a clientes pessoa física!',
            'Atenção'
          );
          return;
        }

        if (infosClients.id_type_client === 1 && event.target.value === '2-COLETIVO EMPRESARIAL') {
          NotificationManager.warning(
            'Só é possível vincular o tipo de contratação coletivo empresarial a clientes pessoa jurídica!',
            'Atenção'
          );
          return;
        }

        if (event.target.name === 'email' || event.target.name === 'email_contato') {
          event.target.value = event.target.value.toLowerCase();
        }
        setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
        getTables();
        getPlans(event.target.value);
      }
      return;
    }

    if (event.target.name === 'id_type_client') {
      infosClients.tpcontrat_client = '';
    }

    if (event.target.name === 'statusvenda') {
      if (event.target.value === 'Aguardando cliente') {
        if (
          infoState.tipointegracao !== 'Não possui' &&
          infosClients.statusvenda === infoState.statusintegracao &&
          !resultIntegration.length
        ) {
          NotificationManager.warning('É necessário integrar a venda para prosseguir', 'Atenção');
          return;
        } else if (
          infoState.tipointegracao !== 'Não possui' &&
          infoState.controlacontrato === 'Sim' &&
          !infosClients.codcontrato &&
          infosClients.statusvenda === infoState.statusintegracao
        ) {
          NotificationManager.warning('Por favor, preencha o código do contrato!', 'Atenção');
          return;
        } else {
          HandleInputStatusSalesChange(event);
        }
      }
    }

    if (event.target.name === 'statusvenda' && infoState.id_type_user === 1) {
      if (!infosClients.senha) {
        NotificationManager.warning('Por favor, gere a senha!', 'Atenção');
        return;
      }
    }

    if (event.target.name === 'email' || event.target.name === 'email_contato') {
      event.target.value = event.target.value.toLowerCase();
    }

    setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
  }

  function clearPlansAndTables() {
    setInfosTablesTemp([]);
    setInfosTables([]);
    setSalesValue('');
    setInfosPlans([]);

    infosClients.codtab = '';
    infosClients.labeltables = '';
    infosClients.labelplans = '';
    infosClients.codplano = '';
  }

  async function PendingHealthDeclarationAndSigning() {
    const routerPending =
      infosClients.id_type_client === 1
        ? `/clients/pendingDeclarationHealth/${infosClients.id}/true`
        : `/clients/pendingDeclarationHealth/${infosClients.id}/false`;

    await configAxios.get(routerPending).then((result) => {
      if (result.data.length > 0) {
        setPendingInfosDeclarationHealth(result.data);
        setHeaderModalPending('Declarações de saúde pendentes');
        setOpenModalPendingHealth(true);
      } else {
        configAxios
          .get(
            `/clients/pendingSigningDocuments/${infosClients.id}/null/${infosClients.id_type_client}/true`
          )
          .then((result) => {
            setPendingInfosDeclarationHealth(result.data);
            setHeaderModalPending('Assinaturas de documentos pendentes');
            setOpenModalPendingHealth(true);
          })
          .catch(() => {
            NotificationManager.warning(
              'Tivemos dificuldades para buscar as pendências, por favor, tente novamente!',
              'Atenção'
            );
          });
      }
    });
  }

  function HandleInputStatusSalesChange(event) {
    if (infoState.id_type_user === 1) {
      if (!infosClients.senha) {
        return;
      }
    }
    setInfosClients({ ...infosClients, [event.target.name]: event.target.value });
    const infosUpdate = {
      id: infosClients.id,
      statusvenda: event.target.value,
      user_id: infoState.id,
      id_type_user: infoState.id_type_user,
      id_vend: infosClients.id_user,
      senha: infoState.id_type_user === 1 ? infosClients.senha : null,
      codclient: infosClients.codclient,
      codcontrato: infosClients.codcontrato ? infosClients.codcontrato : null,
      dtvigencia: infosClients.dtvigencia
    };
    configAxios
      .post('/clients/updateStatusSales', infosUpdate)
      .then(() => {
        NotificationManager.success('Status atualizado com sucesso!', 'Sucesso');
        sendMessage();
        if (
          infoState.tipo === 'Complexo' &&
          infosUpdate.statusvenda === 'Aguardando cliente' &&
          infoState.id_type_user === 1
        ) {
          setOpenModalSendEmailDocuments(true);
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar o status, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleChangeCodContract(event) {
    if (event && initialInfosClients.codcontrato !== event.target.value) {
      const infosUpdate = {
        id: infosClients.id,
        codcontrato: event.target.value,
        user_id: infoState.id
      };
      configAxios
        .post('/clients/updateCodContract', infosUpdate)
        .then(() => {
          NotificationManager.success('Código do contrato atualizado com sucesso!', 'Sucesso');
          setInfosClients({ ...infosClients, codcontrato: event.target.value });
          initialInfosClients.codcontrato = event.target.value;
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para atualizar o status, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function validDtnascimento() {
    if (
      infosClients.dtnascimento > new Date().toLocaleDateString().split('/').reverse().join('-')
    ) {
      NotificationManager.warning('Por favor, escolha uma data de nascimento válida!', 'Atenção');
      infosClients.dtnascimento = '';
    }
    return;
  }

  function HandleSubmitDocument(event) {
    const formData = new FormData();

    if (event.length) {
      key.current = Math.random();

      for (let i = 0; i < event.length; i++) {
        formData.append(event[i].name, event[i], removeAccents(event[i].name));
      }

      if (infosClients.statusvenda === 'Aprovada') {
        NotificationManager.warning(
          'Não é possível realizar o upload de documentos em vendas aprovadas!',
          'Atenção'
        );
        return;
      }

      formData.append('infos', JSON.stringify(infosClients));

      configAxios
        .post('/uploads/createUploads', formData)
        .then(() => {
          getFilesUploads();
          NotificationManager.success('Upload realizado com sucesso!', 'Sucesso');
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para realizar o upload, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  /**
   * Foi feito dessa forma para submeter
   * somente as mudanças que de fato ocorreram no cadastro
   */
  function HandleSubmit(event) {
    event.preventDefault();
    const infosAddEdit = {
      ...infosClients
    };
    let infosEdit = {};

    if (infoState.id_type_user === 1) {
      if (infosClients.statusvenda === 'Aprovada') {
        NotificationManager.warning('Não é possível editar uma venda aprovada!', 'Atenção');
        return;
      }
    }

    if (infoState.id_type_user === 2) {
      if (infosClients.statusvenda !== 'Aberta') {
        NotificationManager.warning(
          'Não é possível editar uma venda que não esteja aberta!',
          'Atenção'
        );
        return;
      }
    }

    if (infosClients.statusvenda === 'Aguardando cliente' && countSigningDocuments > 0) {
      NotificationManager.warning(
        'Não é possível editar uma venda em que as assinaturas já tenham sido iniciadas!!',
        'Atenção'
      );
      return;
    }

    if (!salesValue && infosClients.titbenef == 'S' && infosClients.id_type_client !== 2) {
      NotificationManager.warning('Por favor, faça o cálculo do valor do beneficiário!', 'Atenção');
      return;
    }

    infosClients.id_user = infosClients.id_user ? infosClients.id_user : infoState.id;
    infosAddEdit.id_user = infosClients.id_user ? infosClients.id_user : infoState.id;

    if (!infosClients.id_user) {
      NotificationManager.warning('Por favor, selecione um vendedor', 'Atenção');
      return;
    }

    if (infoState.id_type_user === 2) {
      infosClients.aceitesmsemail = 'S';
    }
    const router = infosClients.id ? 'clients/updateClient' : 'clients/addClient';

    if (infosClients.id) {
      for (let key in infosAddEdit) {
        if (initialInfosClients[key] === infosAddEdit[key]) {
          if (key !== 'id' && key !== 'codclient' && key !== 'id_user') delete infosAddEdit[key];
        }
      }
    }

    if (infosClients.statusvenda === 'Negada') {
      for (let key in infosAddEdit) {
        if (
          key !== 'id' &&
          key !== 'codclient' &&
          key !== 'id_user' &&
          key !== 'motivo_negativa' &&
          key !== 'statusvenda'
        )
          delete infosAddEdit[key];
      }
    }

    configAxios
      .post(router, { infosAddEdit, infoState })
      .then((response) => {
        if (response.status === 201) {
          NotificationManager.warning(
            'O cliente foi cadastrado com sucesso, mas tivemos dificuldades para buscar o vendedor, tente novamente!',
            'Atenção'
          );
        }
        NotificationManager.success(
          infosClients.id ? 'Cliente atualizado com sucesso!' : 'Cadastro realizado com sucesso!',
          'Sucesso'
        );
        if (infosClients.id) {
          for (let key in infosAddEdit) {
            if (initialInfosClients[key] !== infosAddEdit[key]) {
              initialInfosClients[key] = infosAddEdit[key];
            }
          }

          setInfosClients({
            ...initialInfosClients,
            codclient: response.data.codclient,
            labelvend: response.data.labelvend ? response.data.labelvend : infosClients.labelvend,
            id: response.data.id,
            tpcontrat: response.data.tpcontrat
          });

          infosEdit = {
            ...initialInfosClients,
            codclient: response.data.codclient,
            id: infosClients.id ? infosClients.id : response.data.id,
            tpcontrat: response.data.tpcontrat
          };
        } else {
          setInfosClients({
            ...infosClients,
            codclient: response.data.codclient,
            labelvend: response.data.labelvend ? response.data.labelvend : infosClients.labelvend,
            id: response.data.id,
            tpcontrat: response.data.tpcontrat
          });

          infosEdit = {
            ...infosClients,
            codclient: response.data.codclient,
            id: infosClients.id ? infosClients.id : response.data.id,
            tpcontrat: response.data.tpcontrat
          };
        }

        key.current = Math.random();

        if (router === 'clients/updateClient') {
          getDependents();
          if (response.data.changedStatus === true) {
            sendMessage();
          }
        }
        const nav = infosClients.id
          ? `/app/editClients/${infosClients.id}`
          : `/app/editClients/${response.data.id}`;
        navigation(nav, {
          state: { infosEdit }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function FormatDate(infos) {
    let dateFormated = formatDate(infos.data.data.datacadastro);
    return dateFormated;
  }

  function RenderidTit(infos) {
    if (infos.data.data.id_tit) {
      return infos.data.data.id_tit;
    }
    return '-';
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/clients/deleteClientDependent',
          deleteItem: infosClients.id_type_client === 1 ? 'o dependente' : 'o beneficiário',
          functionGrid: getDependents,
          functionModal: AddClientsDependentsModal,
          statusvenda: infosClients.statusvenda
        }}
      />
    ));
  }

  function ActionsButtonsUploads(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenuDocuments
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/uploads/deleteUploads',
          deleteItem: 'o documento',
          fileName: item.file,
          company: infosClient,
          id_type_client: infosClients.id_type_client,
          id_client: infosClients.id,
          statusvenda: infosClients.statusvenda,
          functionGrid: getFilesUploads
        }}
      />
    ));
  }

  function AddClientsDependentsModal(id) {
    if (id) {
      const valuesDeps = infosDeps.filter((element) => element.id === id);
      if (valuesDeps.length > 0) {
        const filterTit = infosDeps.filter((element) => element.id === valuesDeps[0].id_tit);
        setInfosDepsTit(filterTit);
      }
      setInfosDepEdit(valuesDeps);
    }
    setOpenModal(true);
  }

  function DiscussionsClientsModal() {
    setOpenModalDiscussion(true);
  }

  function HistorySalesModal() {
    setOpenModalHistory(true);
  }

  function IntegrationClientsModal() {
    setOpenModalIntegration(true);
  }

  function CalculeValuesClient() {
    if (!infosClients.dtnascimento) {
      NotificationManager.warning(
        'Por favor, preencha a data de nascimento para calcular!',
        'Atenção'
      );
      return;
    }
    if (!infosClients.codtab) {
      NotificationManager.warning('Por favor, escolha a tabela para calcular!', 'Atenção');
      return;
    }
    const infosSales = {
      codtab: infosClients.codtab,
      datanascimento: infosClients.dtnascimento
    };
    configAxios
      .post('utilsInfos/calculeSales', infosSales)
      .then((response) => {
        infosClients.valclient = response.data;
        setSalesValue(response.data);
        calculateValuesForSales(infosDeps);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para calcular o plano no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function GeneratePassword() {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz!@#$%&*()_+';
    let string_length = 8;
    let randomstring = '';
    for (let i = 0; i < string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    const infosUpdate = {
      id: infosClients.id,
      senha: randomstring
    };

    configAxios
      .post('/clients/updatePassword', infosUpdate)
      .then(() => {
        NotificationManager.success('Senha gerada com sucesso!', 'Sucesso');
        setInfosClients({ ...infosClients, senha: randomstring });
        key.current = Math.random();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para gerar a senha, tente novamente!',
          'Atenção'
        );
      });
  }

  function handleTabsChange(event, newValue) {
    setValues(newValue);
  }

  function ActionsButtonsAddress(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editClients',
          deleteRouter: '/address/deleteAddress',
          deleteItem: 'o endereço',
          functionGrid: getAddress,
          otherInfos: item,
          functionModal: AddAddressModal,
          statusvenda: infosClients.statusvenda
        }}
      />
    ));
  }

  function HandleMaintenanceSales() {
    if (infoState.id_type_user === 1 && infoState.id_profile === 1) {
      setOpenMaintenance(true);
    }
  }

  function selectedRows(event) {
    setRowsSelected(event.selectedRowsData);
  }

  async function downloadFilesUploads() {
    const tpbenef = infosClients.id_type_client === 1 ? 'fisico' : 'juridico';
    const URL_AWS_S3 = 'https://mplansistemas-vendaonline.s3.sa-east-1.amazonaws.com';
    const zip = new JSZip();
    dispatch(startAndStop(true));

    for (let element of rowsSelected) {
      let url = `${URL_AWS_S3}/${company}/${tpbenef}/${infosClients.id}/${element.file}`;
      await fetch(url, {
        method: 'GET'
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          var file = new File([blob], element.file);
          zip.file(element.file, file, { base64: false });
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para gerar o download dos arquivos, tente novamente!',
            'Atenção'
          );
        });
    }

    dispatch(startAndStop(false));

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const zipblob = new Blob([content]);
      download(zipblob, `${infosClients.nome} - uploads.zip`);
    });
  }

  return (
    <RenderPage title="Vendas">
      <>
        {infosClients.statusvenda === 'Aguardando cliente' && (
          <PendingHealthDeclaration
            openModal={openModalPendingHealth}
            setOpenModal={setOpenModalPendingHealth}
            pendingInfos={pendingInfosDeclarationHealth}
            functionGrid={getDependents}
            headerModalPending={headerModalPending}
          />
        )}

        <ReactShortcut keys="ctrl+M" onKeysPressed={HandleMaintenanceSales} />

        <AddEditAddress
          openModal={openModalAddress}
          otherInfos={infosClients}
          infosItemEdit={infosAdressEdit}
          countSigningDocuments={countSigningDocuments}
          setOpenModal={setOpenModalAddress}
          functionGrid={getAddress}
        />

        <AddEditClientsDependents
          openModal={openModal}
          otherInfos={infosClients}
          infosDepsEdit={infosDepsEdit}
          infosDepsTit={infosDepsTit}
          countSigningDocuments={countSigningDocuments}
          setOpenModal={setOpenModal}
          functionGrid={getDependents}
        />

        <SendEmailDocuments
          openModal={openModalSendEmailDocuments}
          setOpenModal={setOpenModalSendEmailDocuments}
          functionGetHistory={getHistoryEmails}
          infosClients={infosClients}
          infosDeps={infosDeps}
          infosHistoryEmail={infosHistoryEmail}
          infoState={infoState}
          infosClient={infosClient}
          key={value}
        />

        <HistorySales
          openModal={openModalHistory}
          setOpenModal={setOpenModalHistory}
          infosClients={infosClients}
          infosHistorySales={infosHistorySales}
        />

        <MaintenanceClients
          openModal={openMaintenance}
          setOpenModal={setOpenMaintenance}
          infosClients={infosClients}
          getInfosClient={getInfosClient}
          getHistorySales={getHistorySales}
          getCountDocumentsSigning={getCountDocumentsSigning}
        />

        {infosClients.statusvenda === infoState.statusintegracao && (
          <IntegrationClients
            openModal={openModalIntegration}
            setOpenModal={setOpenModalIntegration}
            infosClients={infosClients}
            infosDeps={infosDeps}
            infosAddress={infosAddress}
            infosHistoryIntegration={infosHistoryIntegration}
            getHistoryIntegration={getHistoryIntegration}
          />
        )}

        <DiscussionClients
          openModal={openModalDiscussions}
          key={valueKeys}
          otherInfos={infosClients}
          getCountMessages={getCountMessages}
          sendMessage={sendMessage}
          setOpenModal={setOpenModalDiscussion}
        />

        <AddEditDeclarationHealth
          openModal={openModalDeclarationHealth}
          setOpenModal={setOpenModalDeclarationHealth}
          showDeclaration={showDeclaration}
          setShowDeclaration={setShowDeclaration}
          responseQuestions={responseQuestions}
          functionGrid={getInfosContract}
          infosAskQuestions={infosQuestions}
          rowSelected={rowSelected}
          statusvenda={infosClients.statusvenda}
        />

        {isMobile && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
              <Grid container>
                <BreadcrumbsNav {...props} />
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography variant="h4" gutterBottom>
                    Cadastro de vendas
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Utilize as opções abaixo para inserir e editar as vendas
                  </Typography>
                </Grid>
                {infosClients.id && infosClients.statusvenda === 'Aberta' && (
                  <Alert severity="info">
                    Prezado(a), não se esqueça de mudar o status da venda para análise para que a
                    mesma possa prosseguir!
                  </Alert>
                )}
              </Grid>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="right" mb={2}>
              {infosClients.statusvenda === 'Aguardando cliente' &&
                infosClients.id &&
                infoState.id_type_user === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setOpenModalSendEmailDocuments(true);
                    }}
                    startIcon={<Iconify icon="eva:email-outline" />}>
                    Enviar
                  </Button>
                )}
              {infosClients.id && (
                <>
                  <Badge
                    color="error"
                    badgeContent={countMessages.count > 0 ? countMessages.count : null}
                    max={50}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}>
                    <Button
                      sx={{ mr: 1 }}
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      onClick={() => {
                        DiscussionsClientsModal();
                      }}
                      startIcon={<Iconify icon="eva:message-circle-fill" />}>
                      Discussões
                    </Button>
                  </Badge>
                  {!isMobile && infoState.id_type_user === 1 && (
                    <>
                      <Button
                        sx={{ mr: 1 }}
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        onClick={() => {
                          HistorySalesModal();
                        }}
                        startIcon={<ManageHistoryIcon />}>
                        Histórico
                      </Button>
                      {infosClients.statusvenda === 'Aguardando cliente' && (
                        <Button
                          sx={{ mr: 1 }}
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          onClick={() => {
                            PendingHealthDeclarationAndSigning();
                          }}
                          startIcon={<Iconify icon="eva:alert-triangle-outline" />}>
                          Pendências
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </Stack>
          </>
        )}
        {!isMobile && (
          <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
            <Grid container>
              <BreadcrumbsNav {...props} />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                  Cadastro de vendas
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Utilize as opções abaixo para inserir e editar as vendas
                </Typography>
              </Grid>
              {infosClients.statusvenda === infoState.statusintegracao &&
                infoState.id_type_user === 1 &&
                infoState.tipointegracao !== 'Não possui' && (
                  <Alert severity={resultIntegration.length ? 'success' : 'warning'}>
                    Status da integração: {resultIntegration.length ? 'integrada' : 'aguardando'}
                  </Alert>
                )}
              {infosClients.id && infosClients.statusvenda === 'Aberta' && (
                <Alert severity="info">
                  Prezado(a), não se esqueça de mudar o status da venda para análise para que a
                  mesma possa prosseguir!
                </Alert>
              )}
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {infosClients.statusvenda === 'Aguardando cliente' &&
                infosClients.id &&
                infoState.id_type_user === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setOpenModalSendEmailDocuments(true);
                    }}
                    startIcon={<Iconify icon="eva:email-outline" />}>
                    Enviar
                  </Button>
                )}
              {infosClients.id && (
                <>
                  <Badge
                    color="error"
                    badgeContent={countMessages.count > 0 ? countMessages.count : null}
                    max={50}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}>
                    <Button
                      sx={{ mr: 1 }}
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      onClick={() => {
                        DiscussionsClientsModal();
                      }}
                      startIcon={<Iconify icon="eva:message-circle-fill" />}>
                      Discussões
                    </Button>
                  </Badge>
                  {infoState.id_type_user === 1 && (
                    <>
                      <Button
                        sx={{ mr: 1 }}
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        onClick={() => {
                          HistorySalesModal();
                        }}
                        startIcon={<ManageHistoryIcon />}>
                        Histórico
                      </Button>
                      {infosClients.statusvenda === 'Aguardando cliente' && (
                        <Button
                          sx={{ mr: 1 }}
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          onClick={() => {
                            PendingHealthDeclarationAndSigning();
                          }}
                          startIcon={<Iconify icon="eva:alert-triangle-outline" />}>
                          Pendências
                        </Button>
                      )}
                      {infosClients.statusvenda === infoState.statusintegracao &&
                        infoState.tipointegracao !== 'Não possui' && (
                          <Button
                            sx={{ mr: 1 }}
                            variant="outlined"
                            color="primary"
                            component={RouterLink}
                            onClick={() => {
                              IntegrationClientsModal();
                            }}
                            startIcon={<Iconify icon="eva:cloud-upload-fill" />}>
                            Integração
                          </Button>
                        )}
                    </>
                  )}
                </>
              )}
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/clients"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            </Stack>
          </Stack>
        )}
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mb: 0 }}>
                  {infosClients.id && (
                    <>
                      <Grid item xs={12} md={1} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                        <TextField
                          name="id"
                          fullWidth
                          label="ID"
                          variant="outlined"
                          size="small"
                          required
                          disabled
                          value={infosClients.id || ''}
                        />
                      </Grid>
                      {infosClients.codclient && (
                        <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                          <TextField
                            name="codclient"
                            fullWidth
                            label="Código do cliente"
                            variant="outlined"
                            size="small"
                            required
                            disabled
                            value={infosClients.codclient || ''}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {infosClients.id && !infosClients.codsimulation && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                      <TextField
                        name="codsimulation"
                        fullWidth
                        label="Código da simulação"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        value="-"
                        onChange={(event) => HandleInputChange(event)}
                        onBlur={(event) => HandleGetSimulation(event)}
                      />
                    </Grid>
                  )}
                  {infosClients.id && infosClients.codsimulation && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                      <TextField
                        name="codsimulation"
                        fullWidth
                        label="Código da simulação"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        value={infosClients.codsimulation}
                        onChange={(event) => HandleInputChange(event)}
                        onBlur={(event) => HandleGetSimulation(event)}
                      />
                    </Grid>
                  )}
                  {!infosClients.id && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                      <TextField
                        name="codsimulation"
                        fullWidth
                        label="Código da simulação"
                        variant="outlined"
                        size="small"
                        disabled={false}
                        value={infosClients.codsimulation || ''}
                        onChange={(event) => HandleInputChange(event)}
                        onBlur={(event) => HandleGetSimulation(event)}
                      />
                    </Grid>
                  )}
                  {infoState.tipointegracao === 'Não possui' &&
                    infosClients.id &&
                    infosClients.statusvenda !== 'Aberta' &&
                    infoState.controlacontrato === 'Sim' && (
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                        <TextField
                          name="codcontrato"
                          fullWidth
                          label="Código do contrato"
                          variant="outlined"
                          size="small"
                          disabled={infoState.id_type_user === 1 ? false : true}
                          required
                          value={infosClients.codcontrato || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    )}
                  {infoState.tipointegracao !== 'Não possui' &&
                    infosClients.id &&
                    (infosClients.statusvenda === infoState.statusintegracao ||
                      infosClients.statusvenda !== 'Aberta') &&
                    infoState.controlacontrato === 'Sim' && (
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 4.2 }}>
                        <TextField
                          name="codcontrato"
                          fullWidth
                          label="Código do contrato"
                          variant="outlined"
                          size="small"
                          disabled={infoState.id_type_user === 1 ? false : true}
                          onBlur={(event) => HandleChangeCodContract(event)}
                          // required
                          value={infosClients.codcontrato || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2} sx={isMobile ? {} : { mt: 2 }}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Tipo de cliente *
                    </InputLabel>
                    <Select
                      name="id_type_client"
                      fullWidth
                      size="small"
                      required
                      disabled={infosClients.id ? true : false}
                      value={infosClients.id_type_client || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={1}>Físico</MenuItem>
                      <MenuItem value={2}>Jurídico</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px' } : { fontSize: '13px', mt: 2 }}>
                      Tipo de contratação *
                    </InputLabel>
                    <Select
                      name="tpcontrat_client"
                      fullWidth
                      size="small"
                      required
                      disabled={
                        (infosClients.statusvenda !== undefined &&
                          infosClients.statusvenda === 'Aberta' &&
                          infoState.id_type_user === 1) ||
                        (infosClients.statusvenda !== undefined &&
                          infosClients.statusvenda !== 'Aberta')
                          ? true
                          : false
                      }
                      value={infosClients.tpcontrat_client || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1-INDIVIDUAL/FAMILIAR'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                      <MenuItem value={'2-COLETIVO EMPRESARIAL'}>2-COLETIVO EMPRESARIAL</MenuItem>
                      <MenuItem value={'3-COLETIVO POR ADESAO'}>3-COLETIVO POR ADESÃO</MenuItem>
                    </Select>
                  </Grid>
                  {infoState.id_profile !== 1 && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 4.2 }}>
                      <TextField
                        name="nomevend"
                        fullWidth
                        label="Vendedor(a)"
                        variant="outlined"
                        size="small"
                        required
                        disabled
                        value={infosClients.labelvend || infoState.nome}
                      />
                    </Grid>
                  )}
                  {infoState.id_profile === 1 && infosClients.id && (
                    <>
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 4.2 }}>
                        <TextField
                          name="nomevend"
                          fullWidth
                          label="Corretora"
                          variant="outlined"
                          size="small"
                          required
                          disabled
                          value={infosClients.empresa_vendedor}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 4.2 }}>
                        <Autocomplete
                          options={infoSelectVend}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) => HandleInputChange(value, { vend: true })}
                          value={infosClients.labelvend || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendedor(a)"
                              name="nomevend"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {infoState.id_type_user === 2 && infosClients.statusvenda !== 'Negada' && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: -0.5 } : { mt: 1.7 }}>
                      <InputLabel id="label" sx={{ fontSize: '13px' }}>
                        Status da venda *
                      </InputLabel>
                      <Select
                        name="statusvenda"
                        fullWidth
                        size="small"
                        required
                        disabled={
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) ||
                          infosClients.statusvenda === 'Análise'
                            ? true
                            : false
                        }
                        value={infosClients.statusvenda || ''}
                        onChange={(event) =>
                          infosClients.id
                            ? HandleInputStatusSalesChange(event)
                            : HandleInputChange(event)
                        }>
                        <MenuItem value={'Aberta'}>Aberta</MenuItem>
                        {infosClients.id && <MenuItem value={'Análise'}>Análise</MenuItem>}
                        {infosClients.id &&
                          infoState.tipo !== 'Complexo' &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Rejeitada'}>Rejeitada</MenuItem>
                          )}
                        {infosClients.id &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Aguardando corretora'}>Aguardando corretora</MenuItem>
                          )}
                        {infosClients.id &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Aguardando cliente'}>Aguardando cliente</MenuItem>
                          )}
                        {infosClients.id &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Aguardando operadora'}>Aguardando operadora</MenuItem>
                          )}
                        {infosClients.id &&
                          infoState.tipo === 'Complexo' &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Aguardando administradora'}>
                              Aguardando administradora
                            </MenuItem>
                          )}
                        {infosClients.id &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Aprovada'}>Aprovada</MenuItem>
                          )}
                        {infosClients.id &&
                          infosDisabledStatusVenda.includes(infosClients.statusvenda) && (
                            <MenuItem value={'Negada'}>Negada</MenuItem>
                          )}
                      </Select>
                    </Grid>
                  )}
                  {infoState.id_type_user === 1 && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: -0.5 } : { mt: 1.7 }}>
                      <InputLabel id="label" sx={{ fontSize: '13px' }}>
                        Status da venda *
                      </InputLabel>
                      <Select
                        name="statusvenda"
                        fullWidth
                        size="small"
                        required
                        disabled={infosClients.statusvenda === 'Aprovada' ? true : false}
                        value={infosClients.statusvenda || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'Aberta'}>Aberta</MenuItem>
                        <MenuItem value={'Análise'}>Análise</MenuItem>
                        {infoState.tipo !== 'Complexo' && (
                          <MenuItem value={'Rejeitada'}>Rejeitada</MenuItem>
                        )}
                        <MenuItem value={'Aguardando cliente'}>Aguardando cliente</MenuItem>
                        <MenuItem value={'Aguardando corretora'}>Aguardando corretora</MenuItem>
                        <MenuItem value={'Aguardando operadora'}>Aguardando operadora</MenuItem>
                        {infoState.tipo === 'Complexo' && (
                          <MenuItem value={'Aguardando administradora'}>
                            Aguardando administradora
                          </MenuItem>
                        )}
                        <MenuItem value={'Aprovada'}>Aprovada</MenuItem>
                        <MenuItem value={'Negada'}>Negada</MenuItem>
                      </Select>
                    </Grid>
                  )}
                  <Grid item xs={12} md={2} sx={isMobile ? {} : { mt: 3.8 }}>
                    <TextField
                      name="dtvigencia"
                      label="Data da vigência"
                      fullWidth
                      type="date"
                      required
                      variant="outlined"
                      size="small"
                      value={infosClients.dtvigencia || ''}
                      onChange={(event) => HandleInputChange(event)}
                      // onBlur={() => validDtnascimento()}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
                {infosClients.id_type_client === 1 && (
                  <>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="nome"
                          fullWidth
                          label="Nome completo"
                          variant="outlined"
                          size="small"
                          required
                          value={infosClients.nome || ''}
                          inputProps={{ maxLength: 100 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="nomesocial"
                          fullWidth
                          label="Nome social"
                          variant="outlined"
                          size="small"
                          value={infosClients.nomesocial || ''}
                          inputProps={{ maxLength: 100 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="dtnascimento"
                          label="Data de nascimento"
                          fullWidth
                          type="date"
                          required
                          variant="outlined"
                          size="small"
                          value={infosClients.dtnascimento || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={() => validDtnascimento()}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <InputMask
                          mask="999.999.999-99"
                          value={infosClients.cpf_cnpj || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={(event) => validCPF(event)}>
                          {() => (
                            <TextField
                              name="cpf_cnpj"
                              fullWidth
                              label="CPF"
                              required
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="rg"
                          label="RG"
                          fullWidth
                          placeholder="Ex: 99.999.999-9"
                          required
                          variant="outlined"
                          size="small"
                          value={infosClients.rg || ''}
                          inputProps={{ maxLength: 20 }}
                          onChange={(event) => HandleInputChange(event)}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          options={infosOrgaEmissor}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) =>
                            HandleInputChange(value, { autocomplete: true })
                          }
                          value={infosClients.label || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Orgão emissor"
                              name="orgao_emissor"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputMask
                          mask="(99)99999-9999"
                          value={infosClients.celular || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          {() => (
                            <TextField
                              name="celular"
                              fullWidth
                              label="Celular"
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputMask
                          mask="(99)9999-9999"
                          onChange={(event) => HandleInputChange(event)}
                          value={infosClients.telefone || ''}>
                          {() => (
                            <TextField
                              name="telefone"
                              fullWidth
                              label="Telefone residencial"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="email"
                          fullWidth
                          label="Email"
                          type="email"
                          required
                          size="small"
                          variant="outlined"
                          value={infosClients.email || ''}
                          inputProps={{ maxLength: 50, style: { textTransform: 'lowerCase' } }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                      {infosClients.id_type_client === 1 && (
                        <>
                          <Grid item xs={12} md={3}>
                            <TextField
                              name="nomedamae"
                              fullWidth
                              label="Nome da mãe"
                              size="small"
                              variant="outlined"
                              required
                              inputProps={{ maxLength: 100 }}
                              value={infosClients.nomedamae || ''}
                              onChange={(event) => HandleInputChange(event)}
                            />
                          </Grid>
                        </>
                      )}
                      {infosClients.tpcontrat_client === '3-COLETIVO POR ADESAO' && (
                        <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                          <TextField
                            name="profissao"
                            fullWidth
                            required
                            label="Profissão"
                            size="small"
                            variant="outlined"
                            inputProps={{ maxLength: 100 }}
                            value={infosClients.profissao || ''}
                            onChange={(event) => HandleInputChange(event)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                        <TextField
                          name="cns"
                          fullWidth
                          required
                          label="Cartão nacional do SUS"
                          size="small"
                          variant="outlined"
                          value={infosClients.cns || ''}
                          inputProps={{ maxLength: 15 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                        <TextField
                          name="decnascidovivo"
                          fullWidth
                          label="Declaração de nascido vivo"
                          size="small"
                          variant="outlined"
                          inputProps={{ maxLength: 15 }}
                          value={infosClients.decnascidovivo || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      {infoState.id_type_user === 1 && (
                        <Grid item xs={8} md={2} sx={isMobile ? { mt: 0 } : { mt: 0 }}>
                          <TextField
                            name="password"
                            fullWidth
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(event) => HandleInputChange(event)}
                            value={infosClients.senha || ''}
                            inputProps={{ maxLength: 8 }}
                            required
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end">
                                    <Iconify
                                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      )}
                      {isMobile && infoState.id_type_user === 1 && (
                        <Grid item xs={4} md={1}>
                          <Stack direction="row" alignItems="center" justifyContent="end">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<LockResetIcon />}
                              onClick={GeneratePassword}>
                              Senha
                            </Button>
                          </Stack>
                        </Grid>
                      )}
                      {!isMobile && infoState.id_type_user === 1 && (
                        <Grid item xs={12} md={1}>
                          <Button
                            sx={{ mt: 0 }}
                            variant="contained"
                            color="primary"
                            startIcon={<LockResetIcon />}
                            onClick={GeneratePassword}>
                            Senha
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid item xs={12} md={infosClients.id ? 2 : 3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Beneficiário/titular do contrato? *
                        </InputLabel>
                        <Select
                          name="titbenef"
                          fullWidth
                          size="small"
                          disabled={
                            (infosDeps.length > 0 ? true : false) ||
                            infosClients.tpcontrat_client === '3-COLETIVO POR ADESAO'
                          }
                          required
                          value={infosClients.titbenef || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'S'}>Sim</MenuItem>
                          <MenuItem value={'N'}>Não</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={infosClients.id ? 2 : 3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Contato via email/sms? *
                        </InputLabel>
                        <Select
                          name="aceitesmsemail"
                          fullWidth
                          size="small"
                          disabled={infoState.id_type_user === 2 ? true : false}
                          required
                          value={infosClients.aceitesmsemail || 'S'}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'S'}>Sim</MenuItem>
                          <MenuItem value={'N'}>Não</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Sexo *
                        </InputLabel>
                        <Select
                          name="sexo"
                          fullWidth
                          size="small"
                          required
                          value={infosClients.sexo || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'Masculino'}>Masculino</MenuItem>
                          <MenuItem value={'Feminino'}>Feminino</MenuItem>
                          <MenuItem value={'Outros'}>Outros</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Estado civil *
                        </InputLabel>
                        <Select
                          name="estadocivil"
                          fullWidth
                          size="small"
                          required
                          value={infosClients.estadocivil || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'Solteiro(a)'}>Solteiro(a)</MenuItem>
                          <MenuItem value={'Casado(a)'}>Casado(a)</MenuItem>
                          <MenuItem value={'Divorciado(a)'}>Divorciado(a)</MenuItem>
                          <MenuItem value={'Viuvo(a)'}>Viuvo(a)</MenuItem>
                          {infoState.tipo !== 'Complexo' && (
                            <MenuItem value={'Companheiro(a)'}>Companheiro(a)</MenuItem>
                          )}
                        </Select>
                      </Grid>
                      {infosClients.id && (
                        <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 2 }}>
                          <TextField
                            name="valueTotal"
                            fullWidth
                            label="Valor total "
                            size="small"
                            variant="outlined"
                            inputProps={{ maxLength: 15 }}
                            value={`R$ ${
                              // eslint-disable-next-line prettier/prettier
                              (infosClients.valueTotal !== 'NaN' &&
                              // eslint-disable-next-line prettier/prettier
                                infosClients.valueTotal !== 'undefined')
                                ? infosClients.valueTotal
                                : '0.00'
                            }`}
                            sx={isMobile ? { mt: 1 } : { ml: 0.1 }}
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      {infosClients.tpcontrat_client === '3-COLETIVO POR ADESAO' && (
                        <Grid item xs={12} md={2}>
                          <InputLabel id="label" sx={{ fontSize: '13px' }}>
                            Associação para vinculação *
                          </InputLabel>
                          <Autocomplete
                            options={infosAssociations}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            isOptionEqualToValue={(option, value) =>
                              value ? option.label === value : option.label === ''
                            }
                            onChange={(event, value) =>
                              HandleInputChange(value, { association: true })
                            }
                            value={infosClients.labelassociation || ''}
                            defaultValue=""
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="id_association"
                                fullWidth
                                size="small"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        md={infosClients.tpcontrat_client === '3-COLETIVO POR ADESAO' ? 2.5 : 3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Produto/Plano *
                        </InputLabel>
                        <Autocomplete
                          options={infosPlans}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) => HandleInputChange(value, { plan: true })}
                          value={infosClients.labelplans || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="codplano"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={infosClients.tpcontrat_client === '3-COLETIVO POR ADESAO' ? 2.5 : 3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Tabela *
                        </InputLabel>
                        <Autocomplete
                          options={infosTablesTemp}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) => HandleInputChange(value, { table: true })}
                          value={infosClients.labeltables || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="codtab"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                      {infosClients.titbenef == 'S' && (
                        <>
                          <Grid item xs={12} md={2}>
                            <InputLabel id="label" sx={{ fontSize: '13px' }}>
                              Carência do beneficiário *
                            </InputLabel>
                            <Autocomplete
                              options={infosWaitingPeriod}
                              renderOption={(props, option) => <li {...props}>{option.label}</li>}
                              isOptionEqualToValue={(option, value) =>
                                value ? option.label === value : option.label === ''
                              }
                              onChange={(event, value) =>
                                HandleInputChange(value, { waitingPeriod: true })
                              }
                              value={infosClients.labelcarencia || ''}
                              defaultValue=""
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="codcarencia"
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={8} md={2}>
                            <InputLabel id="label" sx={{ fontSize: '13px' }}>
                              Valor *
                            </InputLabel>
                            <TextField
                              name="valclient"
                              fullWidth
                              variant="outlined"
                              size="small"
                              required
                              value={`R$ ${salesValue}`}
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </Grid>
                          {isMobile && (
                            <Grid item xs={4}>
                              <Stack direction="row" alignItems="center" justifyContent="end">
                                <Button
                                  sx={!isMobile ? { mt: 3 } : { mt: 2.5 }}
                                  variant="contained"
                                  color="success"
                                  onClick={CalculeValuesClient}
                                  startIcon={<Iconify icon="ant-design:calculator-outlined" />}>
                                  Calcular
                                </Button>
                              </Stack>
                            </Grid>
                          )}
                          {!isMobile && (
                            <Grid item xs={3} md={1}>
                              <Button
                                sx={!isMobile ? { mt: 3 } : { mt: 1 }}
                                variant="contained"
                                color="success"
                                onClick={CalculeValuesClient}
                                startIcon={<Iconify icon="ant-design:calculator-outlined" />}>
                                Calcular
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </>
                )}
                {infosClients.id_type_client === 2 && (
                  <>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="nome"
                          fullWidth
                          label="Razão social"
                          variant="outlined"
                          size="small"
                          required
                          value={infosClients.nome || ''}
                          inputProps={{ maxLength: 100 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                        <TextField
                          name="nomefantasia"
                          fullWidth
                          label="Nome fantasia"
                          variant="outlined"
                          size="small"
                          value={infosClients.nomefantasia || ''}
                          inputProps={{ maxLength: 100 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                        <InputMask
                          mask="99.999.999/0009-99"
                          value={infosClients.cpf_cnpj || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={(event) => validCNPJ(event)}>
                          {() => (
                            <TextField
                              name="cpf_cnpj"
                              fullWidth
                              label="CNPJ"
                              required
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                        <TextField
                          name="email"
                          fullWidth
                          label="Email"
                          type="email"
                          required
                          size="small"
                          variant="outlined"
                          value={infosClients.email || ''}
                          inputProps={{ maxLength: 50, style: { textTransform: 'lowerCase' } }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      {infoState.id_type_user === 1 && (
                        <Grid item xs={8} md={2} sx={isMobile ? { mt: 0 } : { mt: 0 }}>
                          <TextField
                            name="password"
                            fullWidth
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(event) => HandleInputChange(event)}
                            value={infosClients.senha || ''}
                            inputProps={{ maxLength: 8 }}
                            required
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end">
                                    <Iconify
                                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      )}
                      {isMobile && infoState.id_type_user === 1 && (
                        <Grid item xs={4} md={1}>
                          <Stack direction="row" alignItems="center" justifyContent="end">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<LockResetIcon />}
                              onClick={GeneratePassword}>
                              Senha
                            </Button>
                          </Stack>
                        </Grid>
                      )}
                      {!isMobile && infoState.id_type_user === 1 && (
                        <Grid item xs={12} md={1}>
                          <Button
                            sx={{ mt: 0 }}
                            variant="contained"
                            color="primary"
                            startIcon={<LockResetIcon />}
                            onClick={GeneratePassword}>
                            Senha
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                      <Grid item xs={12} md={2}>
                        <InputMask
                          mask="(99)99999-9999"
                          value={infosClients.celular || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          {() => (
                            <TextField
                              name="celular"
                              fullWidth
                              label="Celular"
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <InputMask
                          mask="(99)9999-9999"
                          onChange={(event) => HandleInputChange(event)}
                          value={infosClients.telefone || ''}>
                          {() => (
                            <TextField
                              name="telefone"
                              fullWidth
                              label="Telefone comercial"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="nome_resp"
                          fullWidth
                          label="Nome responsável"
                          required
                          size="small"
                          variant="outlined"
                          value={infosClients.nome_resp || ''}
                          inputProps={{ maxLength: 100 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <InputMask
                          mask="999.999.999-99"
                          value={infosClients.cpf_cnpj_resp || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={(event) => validCPF(event)}>
                          {() => (
                            <TextField
                              name="cpf_cnpj_resp"
                              fullWidth
                              label="CPF responsável"
                              required
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 0 }}>
                        <TextField
                          name="nome_contato"
                          label="Nome do contato"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={infosClients.nome_contato || ''}
                          inputProps={{ maxLength: 20 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 0 }}>
                        <TextField
                          name="email_contato"
                          label="Email contato direto"
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="email"
                          value={infosClients.email_contato || ''}
                          inputProps={{ maxLength: 50 }}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0.1 }}>
                      <Grid item xs={12} md={2}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Responsável incluído no plano? *
                        </InputLabel>
                        <Select
                          name="included_resp"
                          fullWidth
                          size="small"
                          required
                          disabled={infosDeps.length > 0 ? true : false}
                          value={infosClients.included_resp || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'S'}>Sim</MenuItem>
                          <MenuItem value={'N'}>Não</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Contato via email/sms? *
                        </InputLabel>
                        <Select
                          name="aceitesmsemail"
                          fullWidth
                          size="small"
                          disabled={infoState.id_type_user === 2 ? true : false}
                          required
                          value={infosClients.aceitesmsemail || 'S'}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'S'}>Sim</MenuItem>
                          <MenuItem value={'N'}>Não</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Produto/Plano *
                        </InputLabel>
                        <Autocomplete
                          options={infosPlans}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) => HandleInputChange(value, { plan: true })}
                          value={infosClients.labelplans || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="codplano"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          Tabela *
                        </InputLabel>
                        <Autocomplete
                          options={infosTablesTemp}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) => HandleInputChange(value, { table: true })}
                          value={infosClients.labeltables || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="codtab"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                      {infosClients.id && (
                        <Grid item xs={12} md={2}>
                          <TextField
                            name="valueTotal"
                            fullWidth
                            label="Valor total "
                            size="small"
                            variant="outlined"
                            inputProps={{ maxLength: 15 }}
                            value={`R$ ${
                              // eslint-disable-next-line prettier/prettier
                              (infosClients.valueTotal !== 'NaN' &&
                              // eslint-disable-next-line prettier/prettier
                                infosClients.valueTotal !== 'undefined')
                                ? infosClients.valueTotal
                                : '0.00'
                            }`}
                            sx={isMobile ? { mt: 1 } : { ml: 0.1, mt: 2.2 }}
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                {infosClients.statusvenda !== 'Negada' && (
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={12} md={12}>
                      <InputLabel id="label" sx={{ fontSize: '13px', ml: 0.3 }}>
                        Observações sobre a venda:
                      </InputLabel>
                      <TextareaAutosize
                        name="obs"
                        label="Observações sobre a venda"
                        disabled={infoState.id_type_user === 1 ? false : true}
                        size="small"
                        variant="outlined"
                        minRows={3}
                        value={infosClients.obs || ''}
                        onChange={(event) => HandleInputChange(event)}
                      />
                    </Grid>
                  </Grid>
                )}
                {infosClients.statusvenda === 'Negada' && infoState.id_type_user === 1 && (
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={12} md={12}>
                      <InputLabel id="label" sx={{ fontSize: '13px', ml: 0.3 }}>
                        Motivo da negativa *
                      </InputLabel>
                      <TextareaAutosize
                        name="motivo_negativa"
                        label="Motivo da negativa"
                        required
                        size="small"
                        variant="outlined"
                        minRows={3}
                        value={infosClients.motivo_negativa || ''}
                        onChange={(event) => HandleInputChange(event)}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
              {infosClients.id && (
                <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                  <TabContext value={values}>
                    <Box sx={{ mt: 1, p: 2 }}>
                      <TabList
                        onChange={handleTabsChange}
                        aria-label="ClientsTabs"
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab label="Endereços" value="1" />
                        <Tab
                          label={
                            infosClients.id_type_client === 1 ? 'Dependentes' : 'Beneficiários'
                          }
                          value="2"
                        />
                        <Tab label="Documentos/Uploads" value="3" />

                        {infosClients.statusvenda === 'Aguardando corretora' && (
                          <Tab label="Assinaturas" value="4" />
                        )}
                        {(infosClients.statusvenda === 'Aguardando operadora' ||
                          infosClients.statusvenda === 'Aprovada' ||
                          infosClients.statusvenda === 'Aguardando administradora') && (
                          <Tab label="Assinaturas" value="4" />
                        )}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm>Adicionar e/ou remover endereços</CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={AddAddressModal}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getAddress}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={AddAddressModal}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosAddress}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não foram encontrados endereços'}>
                        <Column dataField="rua" />
                        <Column dataField="numero" caption="Número" width={100} />
                        <Column dataField="bairro" />
                        <Column dataField="cidade" />
                        <Column dataField="estado" width={100} />
                        <Column dataField="cep" width={100} />
                        <Column dataField="desctype" caption="Tipo endereço" width={150} />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsAddress data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />
                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              {infosClients.id_type_client === 1 && (
                                <CustomInfosForm>
                                  Adicionar e/ou remover dependentes
                                </CustomInfosForm>
                              )}
                              {infosClients.id_type_client === 2 && (
                                <CustomInfosForm>
                                  Adicionar e/ou remover beneficiários
                                </CustomInfosForm>
                              )}
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={AddClientsDependentsModal}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            {!isMobile && (
                              <Button
                                variant="outlined"
                                onClick={getDependents}
                                sx={{ ml: 1 }}
                                startIcon={<Iconify icon="eva:refresh-outline" />}>
                                Atualizar
                              </Button>
                            )}
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={AddClientsDependentsModal}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosDeps}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não foram encontrados beneficiários'}>
                        <Column dataField="id" caption="Id" />
                        <Column dataField="nome" />
                        <Column dataField="email" />
                        <Column dataField="cpf_cnpj" caption="Cpf" />
                        <Column dataField="tpbenef" caption="Tipo de beneficiário" />
                        <Column dataField="valclient" caption="Valor R$" />
                        {infosClients.id_type_client === 2 && (
                          <Column
                            dataField="id_tit"
                            caption="Id. titular"
                            cellRender={(data) => <RenderidTit data={data} />}
                          />
                        )}
                        <Column
                          dataField="datacadastro"
                          caption="Data de cadastro"
                          cellRender={(data) => <FormatDate data={data} />}
                        />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtons data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />
                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                    <TabPanel value="3">
                      <Stack direction="row" alignItems="center" justifyContent="start">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm>
                                Adicionar, visualizar e/ou remover documentos
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ mb: 1 }}>
                          {!isMobile && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={downloadFilesUploads}
                                sx={{ mr: 1 }}
                                disabled={rowsSelected.length ? false : true}
                                startIcon={<Iconify icon="eva:cloud-download-outline" />}>
                                Download
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={getFilesUploads}
                                startIcon={<Iconify icon="eva:refresh-outline" />}>
                                Atualizar
                              </Button>
                            </>
                          )}
                        </Stack>
                      </Stack>
                      <DataGrid
                        dataSource={infosUploads}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        onSelectionChanged={selectedRows}
                        noDataText={'Não foram encontrados uploads'}>
                        <Selection mode="multiple" />
                        {/* <Column dataField="id" caption="Id" /> */}
                        <Column dataField="filename" caption="Nome do documento" />
                        <Column dataField="dtope" caption="Data de operação" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsUploads data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={5} />
                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                      <Grid container sx={{ mt: 1 }}>
                        <DropzoneArea
                          acceptedFiles={['image/*, application/pdf']}
                          maxFileSize={10000000}
                          onChange={(event) => HandleSubmitDocument(event)}
                          dropzoneText="Arraste os arquivos aqui ou clique para selecionar"
                          fullWidth={true}
                          showAlerts={false}
                          filesLimit={20}
                          showPreviewsInDropzone={false}
                          key={value}
                          getFileLimitExceedMessage={(filesLimit) =>
                            NotificationManager.warning(
                              `Ultrapassou o limit de ${filesLimit} arquivos`,
                              'Atenção'
                            )
                          }
                          getDropRejectMessage={() => {
                            NotificationManager.warning(
                              `Ultrapassou o limite de 10mb, tente outro arquivo!`,
                              'Atenção'
                            );
                          }}
                        />
                      </Grid>
                    </TabPanel>
                    <TabPanel value="4">
                      {isMobile && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="right"
                          sx={{ mb: 2 }}>
                          <Button
                            variant="contained"
                            onClick={showDeclarationHealthModal}
                            sx={{ mr: 1 }}
                            startIcon={<MonitorHeartIcon />}>
                            Visualizar
                          </Button>
                          {infosClients.statusvenda === 'Aguardando corretora' &&
                            infoState.id_type_user === 2 &&
                            infoState.id_profile === 3 && (
                              <DocumentsForSignature
                                functionGrid={getInfosContract}
                                infosClients={infosClients}
                                getInfosClient={getInfosClient}
                                formClient={true}
                              />
                            )}
                          {infosClients.statusvenda === 'Aguardando operadora' ||
                            (infosClients.statusvenda === 'Aguardando administradora' &&
                              infoState.id_type_user === 1 &&
                              infoState.id_profile !== 2 && (
                                <DocumentsForSignature
                                  functionGrid={getInfosContract}
                                  infosClients={infosClients}
                                  getInfosClient={getInfosClient}
                                  formClient={true}
                                />
                              ))}
                          {infosClients.statusvenda === 'Aprovada' && (
                            <DownloadDocumentsSigning
                              infosClients={infosClients}
                              tipo={infosClients.tpcontrat_client}
                              key={value}
                              formClient={true}
                            />
                          )}
                        </Stack>
                      )}
                      {!isMobile && (
                        <Stack direction="row" alignItems="center" justifyContent="start">
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm>
                                Visualizar declarações de saúde e assinar documentos
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="end"
                            sx={{ mb: 2 }}>
                            <Button
                              variant="contained"
                              onClick={showDeclarationHealthModal}
                              sx={{ mr: 0 }}
                              startIcon={<MonitorHeartIcon />}>
                              Visualizar
                            </Button>
                            {infosClients.statusvenda === 'Aguardando corretora' &&
                              infoState.id_type_user === 2 &&
                              infoState.id_profile === 3 && (
                                <DocumentsForSignature
                                  functionGrid={getInfosContract}
                                  infosClients={infosClients}
                                  getInfosClient={getInfosClient}
                                  formClient={true}
                                />
                              )}
                            {(infosClients.statusvenda === 'Aguardando operadora' ||
                              infosClients.statusvenda === 'Aguardando administradora') &&
                              infoState.id_type_user === 1 &&
                              infoState.id_profile !== 2 && (
                                <DocumentsForSignature
                                  functionGrid={getInfosContract}
                                  infosClients={infosClients}
                                  getInfosClient={getInfosClient}
                                  formClient={true}
                                />
                              )}
                            {infosClients.statusvenda === 'Aprovada' && (
                              <DownloadDocumentsSigning
                                infosClients={infosClients}
                                tipo={infosClients.tpcontrat_client}
                                key={value}
                                formClient={true}
                              />
                            )}
                          </Stack>
                        </Stack>
                      )}
                      <DataGrid
                        dataSource={infosContract}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        onSelectionChanged={selectedRow}
                        noDataText={'Não foram encontrados registros'}>
                        <Selection mode="single" />
                        <Column dataField="nome" caption="Nome" />
                        <Column dataField="cpf_cnpj" caption="Cpf" />
                        <Column dataField="tpbenef" caption="Tipo de beneficiário" />
                        <Column dataField="email" caption="Email" />
                        <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={30} />
                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  disabled={
                    (infoState.id_type_user === 2 && infosClients.statusvenda !== 'Aberta') ||
                    infosClients.statusvenda === 'Aprovada' ||
                    (infosClients.statusvenda !== 'Negada' && countSigningDocuments > 0)
                      ? true
                      : false
                  }
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosClients.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/clients"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default AddEditClients;
