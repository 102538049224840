/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
// import { selectLogin } from '../../../../store/login/loginSlice';
// import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { viaCep, validCep } from '../../../../@core/utils/utilsFunctions';
import axios from 'axios';

import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { validateCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Box,
  // Alert,
  Autocomplete
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import configAxios from '../../../../services/configAxios';
import LinkPlans from './LinkPlans';
import LinkAmenities from './LinkAmenities';
import LinkSocialMedia from './LinkSocialMedia';

import InputMask from 'react-input-mask';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import palette from 'src/@core/theme/palette';

const AddEditPartners = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');

  const [infosPartners, setInfosPartners] = useState(state ? { ...state.infosEdit } : {});
  const [infosCategories, setinfosCategories] = useState({});
  const [infosPlans, setInfosPlans] = useState({});
  const [infosAmenities, setInfosAmenities] = useState({});

  const [infosSocialMedia, setInfosSocialMedia] = useState({});
  const [openModalPlans, setOpenModalPlans] = useState(false);
  const [openModalAmenities, setOpenModalAmenities] = useState(false);
  const [openModalSocialMedia, setOpenModalSocialMedia] = useState(false);
  const [value, setValue] = useState('1');

  const [infoState] = useState({});

  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editBrokers') [navigation('/app/brokers')];
    if (infosPartners.id) {
      getPlans();
      getAmenitie();
      getSocialMedia();
    }
    getCategories();
  }, []);

  // Get de Categorias
  function getCategories() {
    configAxios
      .get(`/categorys/getCategorys`)
      .then((response) => {
        console.log(response.data);
        setinfosCategories(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as categorias no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  // funcoes do Planos
  function getPlans() {
    configAxios
      .get(`/establishments/listPlansEstablishment/${infosPartners.id}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function LinktemsModalPlans() {
    setOpenModalPlans(true);
  }

  function ActionsButtonsPlans(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/establishments/deletePlansEstablishment',
          deleteItem: 'o plano do parceiro',
          functionGrid: getPlans,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  // Funcoes de comodidades
  function getAmenitie() {
    configAxios
      .get(`/amenetie/getAmenities`)
      .then((response) => {
        console.log(response.data);
        setInfosAmenities(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as comodidades no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function LinkItemsModalAmenities() {
    setOpenModalAmenities(true);
  }

  function ActionsButtonsAmenities(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/amenetie/deleteAmenitie',
          deleteItem: 'a comodidade do parceiro',
          functionGrid: getAmenitie,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  //  Funcoes Rede Social
  function getSocialMedia() {
    configAxios
      .get(`/establishments/listPlansEstablishment/${infosPartners.id}`)
      .then((response) => {
        setInfosSocialMedia(response.data);
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function LinkItemsModalSocialMedia() {
    setOpenModalSocialMedia(true);
  }

  function ActionsButtonsSocialMedia(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/establishments/deletePlansEstablishment',
          deleteItem: 'o plano do parceiro',
          functionGrid: getPlans,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  // function getAddress() {
  //   configAxios
  //     .get(`/address/listAddress/${infosPartners.id}`)
  //     .then((response) => {
  //       setInfosAddress(response.data);
  //     })
  //     .catch(() => {
  //       NotificationManager.error(
  //         'Tivemos dificuldades para buscar os endereços no momento, tente novamente!',
  //         'Atenção'
  //       );
  //     });
  // }

  // function AddItemsModal(id) {
  //   if (id) {
  //     const valuesEdit = infosAddress.filter((element) => element.id === id);
  //     setInfosAddressEdit(valuesEdit);
  //   }
  //   setOpenModal(true);
  // }

  function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosPartners({ ...infosPartners, cnpj: '' });
        return;
      }
      setInfosPartners({ ...infosPartners, [event.target.name]: event.target.value });
    }
  }

  function handleTabsChange(event, newValue) {
    setValue(newValue);
  }

  async function HandleInputCep(event) {
    if (event.target.value) {
      if (validCep(event.target.value)) {
        let infosCep;
        infosCep = await viaCep(event.target.value);

        if (infosCep) {
          // HandleInputChange({ ...infosCep }, true);
          getLocation(infosCep);

          return;
        }
        NotificationManager.danger(
          'Tivemos dificuldades para buscar as informações do endereço, tente novamente!',
          'Atenção'
        );
      } else {
        NotificationManager.warning('Cep inválido, verifique e tente novamente!', 'Atenção');
      }
    }
  }

  async function getLocation(items) {
    await axios
      .get(
        `https://geocode.search.hereapi.com/v1/geocode?q=${items.rua}+${items.bairro}+${items.cidade}+${items.estado}&apiKey=xYEA9OokpHBPn-BHpLyq-t6Aiq6vKlEW6edBA-sEAR8`
      )
      .then((response) => {
        HandleInputChange({ ...response.data.items[0].position, ...items }, true);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os endereços no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, cep, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosPartners({
          ...infosPartners,
          categoria: event.label,
          codcategory: event.codcategory
        });
        return;
      }
      return;
    }

    if (cep) {
      const data = {
        endest: event.rua,
        bairroest: event.bairro,
        cidest: event.cidade,
        ufest: event.estado,
        latitude: event.lat,
        longitude: event.lng
      };
      setInfosPartners({ ...infosPartners, ...data });
      return;
    }

    setInfosPartners({ ...infosPartners, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosPartners.id
      ? '/establishments/updateEstablishments'
      : '/establishments/addEstablishments';
    configAxios
      .post(router, infosPartners)
      .then((response) => {
        const message = infosPartners.id
          ? 'Parceiro atualizado com sucesso!'
          : 'Parceiro inserido com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        setInfosPartners({
          ...infosPartners,
          id: response.data.id
        });
        const nav = infosPartners.id
          ? `/app/editPartners/${infosPartners.id}`
          : `/app/editPartners/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosPartners,
              id: infosPartners.id ? infosPartners.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Parceiros" id="partners">
      <>
        <LinkPlans
          openModal={openModalPlans}
          setOpenModal={setOpenModalPlans}
          functionGrid={getPlans}
          otherInfos={infosPartners}
        />
        <LinkAmenities
          openModal={openModalAmenities}
          setOpenModal={setOpenModalAmenities}
          functionGrid={getAmenitie}
          otherInfos={infosPartners}
        />
        <LinkSocialMedia
          openModal={openModalSocialMedia}
          setOpenModal={setOpenModalSocialMedia}
          functionGrid={getSocialMedia}
          otherInfos={infosPartners}
        />
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Parceiros
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar os Parceiros
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/partners"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>

        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                  Campos obrigatórios possuem asterisco (*)
                </CustomInfosForm>

                {/* Dados de ID  */}
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  {infosPartners.id ? (
                    <>
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="id"
                          fullWidth
                          label="ID"
                          variant="outlined"
                          size="small"
                          disabled
                          value={infosPartners.id || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.5}>
                        <TextField
                          name="codest"
                          fullWidth
                          label="Cod. Parceiro"
                          variant="outlined"
                          size="small"
                          disabled
                          value={infosPartners.codest || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="status"
                      fullWidth
                      size="small"
                      required
                      value={infosPartners.status || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'ATIVO'}>
                        Ativo
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'INATIVO'}>
                        Inativo
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="99/99/9999"
                      value={infosPartners.datacad || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="datacad"
                          fullWidth
                          label="Data de início"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  {infosPartners.status === 'INATIVO' ? (
                    <Grid item xs={12} md={2.5}>
                      <InputMask
                        mask="99/99/9999"
                        value={infosPartners.datafim || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        {() => (
                          <TextField
                            name="datafim"
                            fullWidth
                            label="Data de Inativação"
                            variant="outlined"
                            size="small"
                            required
                          />
                        )}
                      </InputMask>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>

                {/* Dados de Identificação */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Dados do Parceiro
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="fantasiaest"
                      fullWidth
                      label="Nome fantasia"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.fantasiaest || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="razaosocial"
                      fullWidth
                      label="Razão social"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.razaosocial || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="99.999.999/0009-99"
                      value={infosPartners.cnpjcpf || ''}
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => validCNPJ(event)}>
                      {() => (
                        <TextField
                          name="cnpjcpf"
                          fullWidth
                          label="CNPJ"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="999.999.999.999"
                      value={infosPartners.ie || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="ie"
                          fullWidth
                          label="Insc. Est."
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      sx={{ color: palette.primary.contrastText }}
                      options={infosCategories}
                      renderOption={(props, option) => (
                        <li style={{ color: palette.primary.contrastText }} {...props}>
                          {option.label}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange(value, { autocomplete: true })}
                      value={infosPartners.label || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: palette.primary.contrastText }}
                          {...params}
                          label="Categoria"
                          name="categoria"
                          fullWidth
                          size="small"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* Dados de Contato  */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Contatos do Parceiro
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)9999-9999"
                      value={infosPartners.telefone || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="telefone"
                          fullWidth
                          label="Telefone"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)99999-9999"
                      value={infosPartners.celular || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="celular"
                          fullWidth
                          label="Celular"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="small"
                      type="email"
                      required
                      value={infosPartners.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="nomeresponsavel"
                      fullWidth
                      label="Nome Responsável"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.nomeresponsavel || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="999.999.999-99"
                      value={infosPartners.cpfresponsavel || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="cpfresponsavel"
                          fullWidth
                          label="CPF Responsável"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)99999-9999"
                      value={infosPartners.telefoneresponsavel || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="telefoneresponsavel"
                          fullWidth
                          label="Telefone do Responsável"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                {/* Endereço de Contato  */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Endereço do Parceiro
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="99999-999"
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => HandleInputCep(event)}
                      value={infosPartners.cepest || ''}>
                      {() => (
                        <TextField
                          name="cepest"
                          fullWidth
                          label="CEP"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="endest"
                      fullWidth
                      label="Endereço"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.endest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="numres"
                      fullWidth
                      label="Número"
                      variant="outlined"
                      size="small"
                      type="number"
                      required
                      value={infosPartners.numres || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="compleres"
                      fullWidth
                      label="Complemento"
                      variant="outlined"
                      size="small"
                      value={infosPartners.compleres || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="bairroest"
                      fullWidth
                      label="Bairro"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.bairroest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="cidest"
                      fullWidth
                      label="Cidade"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.cidest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="ufest"
                      fullWidth
                      label="estado"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.ufest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="latitude"
                      fullWidth
                      label="latitude"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.latitude || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="longitude"
                      fullWidth
                      label="Longitude"
                      variant="outlined"
                      size="small"
                      required
                      value={infosPartners.longitude || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>

                {/* Endereço de Contato  */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Dados financeiro do Parceiro
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Forma de Recebimento *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="forma_pagto"
                      fullWidth
                      size="small"
                      required
                      value={infosPartners.forma_pagto || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'1'}>
                        PIX
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'2'}>
                        Transferência
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'3'}>
                        Boleto
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Dia Pagto *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="diapagto"
                      fullWidth
                      size="small"
                      required
                      value={infosPartners.diapagto || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'5'}>
                        5
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'10'}>
                        10
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'15'}>
                        15
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'20'}>
                        20
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'25'}>
                        25
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  {infosPartners.forma_pagto === '1' ? (
                    <>
                      <Grid item xs={12} md={7}>
                        <TextField
                          name="pix"
                          fullWidth
                          label="Chave PIX"
                          variant="outlined"
                          size="small"
                          required
                          value={infosPartners.pix || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextField
                          name="nameresppix"
                          fullWidth
                          label="Nome Resp. PIX"
                          variant="outlined"
                          size="small"
                          required
                          value={infosPartners.nameresppix || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    </>
                  ) : infosPartners.forma_pagto === '2' ? (
                    <>
                      <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 3 : 4}>
                        <TextField
                          name="agencia"
                          fullWidth
                          label="Agência"
                          type="number"
                          variant="outlined"
                          size="small"
                          required
                          value={infosPartners.agencia || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <TextField
                          name="conta"
                          fullWidth
                          label="Num. Conta"
                          type="number"
                          variant="outlined"
                          size="small"
                          required
                          value={infosPartners.conta || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>

                      <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 3 : 4}>
                        <TextField
                          name="banco"
                          fullWidth
                          label="Banco"
                          type="number"
                          variant="outlined"
                          size="small"
                          required
                          value={infosPartners.banco || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>

                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 15 }}>
                  Outros Dados do Parceiro
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="obs"
                      fullWidth
                      label="Observações do Parceiro"
                      variant="outlined"
                      size="small"
                      value={infosPartners.obs || ''}
                      inputProps={{ maxLength: 300 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* Links do parceiro */}
              {infosPartners.id && (
                <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                  <TabContext value={value} variant="scrollable" scrollButtons="auto">
                    <Box sx={{ mt: 3, p: 1 }}>
                      <TabList onChange={handleTabsChange} aria-label="BrokersTabs">
                        <Tab label="Planos" value="1" />
                        <Tab label="Comodidades" value="2" />
                        <Tab label="Redes Sociais" value="3" />
                      </TabList>
                    </Box>
                    {/* Cadastro de planos */}

                    <TabPanel value="1">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover planos
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinktemsModalPlans}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getPlans}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinktemsModalPlans}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosPlans}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há planos vinculados'}>
                        <Column dataField="id" width={70} />
                        <Column dataField="codplano" caption="Código do plano" />
                        <Column dataField="descplano" caption="Descrição do plano" />
                        <Column dataField="tpcontrat" caption="Tipo de contratação" />
                        <Column dataField="status" caption="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsPlans data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>

                    {/* Cadastro de comodidades */}
                    <TabPanel value="2">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover comodidades
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkItemsModalAmenities}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getAmenitie}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkItemsModalAmenities}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosAmenities}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há comodidades cadastradas'}>
                        <Column dataField="id" caption="id" width={70} />
                        <Column
                          dataField="codamenitie"
                          caption="Código da comodidade"
                          width={200}
                        />
                        <Column dataField="descamenitie" caption="Descriçāo" />
                        <Column dataField="status" caption="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsAmenities data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>

                    {/* Cadastro de rede social */}
                    <TabPanel value="3">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover redes sociais
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkItemsModalSocialMedia}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getSocialMedia}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkItemsModalSocialMedia}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosSocialMedia}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há endereços cadastrados'}>
                        <Column dataField="id" width={70} />
                        <Column dataField="Cod. Rede Social" />
                        <Column dataField="Descriçāo" />
                        <Column dataField="Link" />
                        <Column dataField="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsSocialMedia data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosPartners.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/brokers"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default AddEditPartners;
