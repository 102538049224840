import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Badge } from '@mui/material';
import { AccountStyle } from '../../assets/style/globalStyle';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../components/multiplesComponents/Logo';
import Scrollbar from '../components/multiplesComponents/Scrollbar';
import NavSection from '../components/multiplesComponents/NavSection';
import navConfig from './NavConfig';

import palette from 'src/@core/theme/palette';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  }
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const user = useSelector((state) => state.login.authenticatedUser);
  const permissions = useSelector((state) => state.login.permissions);

  const photoURL = user.userLoggedIn[0].avatar
    ? user.userLoggedIn[0].avatar
    : '/static/media/avatar1.68d7bc8804ba2762d8bf.jpeg';

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        backgroundColor: `${palette.primary.darker}`,
        '& .simplebar-content': {
          backgroundColor: `${palette.primary.darker}`,
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}>
      <Logo />
      <Box sx={{ mb: 2, mx: 2.5, mt: 3 }}>
        <Link underline="none" component={RouterLink} to="/app/myAccount">
          {permissions[0].id_type_user !== 3 && (
            <AccountStyle>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot">
                <Avatar src={photoURL} alt="avatar" sx={{ width: 72, height: 72 }} />
              </StyledBadge>
              <Box sx={{ ml: 2, mt: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user.userLoggedIn[0].nome}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                  {user.userLoggedIn[0].desc_perfil}
                </Typography>
                <Typography variant="subtitle3" sx={{ color: 'text.secondary' }}>
                  {user.userLoggedIn[0].fantasiaest}
                </Typography>
              </Box>
            </AccountStyle>
          )}
        </Link>
      </Box>
      <NavSection navConfig={navConfig(permissions)} />
      <Box />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}>
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'primary',
              borderRightStyle: 'dashed'
            }
          }}>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
