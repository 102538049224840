import PropTypes from 'prop-types';
import { Popover } from '@mui/material';
import { ArrowStyle } from '../../../assets/style/globalStyle';

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object
};

export default function MenuPopover({ children, sx, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          ...sx
        }
      }}
      {...other}>
      <ArrowStyle className="arrow" />
      {children}
    </Popover>
  );
}
