import React from 'react';
import { Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useMemo } from 'react';
import { Box } from '@mui/material';

import imglogo from '../../assets/images/multiples/logo_reduz.png';

function Copyright() {
  const img = imglogo;
  const isLogoTemp = useMemo(() => img, []);
  const isLogo = <Box component="img" src={`${isLogoTemp}`} sx={{ width: '1%', mr: 1 }} />;
  const logo = isLogo;

  return (
    <Typography component={'span'} variant="body2" color="text.secondary" align="left">
      <Stack>
        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
          {logo} Phorm © {new Date().getFullYear()} - Your health is important!
        </Typography>
      </Stack>
    </Typography>
  );
}

function CopyrightMobile() {
  const img = imglogo;
  const isLogoTemp = useMemo(() => img, []);
  const isLogo = <Box component="img" src={`${isLogoTemp}`} sx={{ width: '1%', mr: 1 }} />;
  const logo = isLogo;

  return (
    <Typography component={'span'} variant="body2" color="text.secondary" align="center">
      <Stack>
        <Typography variant="subtitle3" sx={{ color: 'text.primary' }}>
          {logo} Phorm © {new Date().getFullYear()} - Your health is important!
        </Typography>
      </Stack>
    </Typography>
  );
}

export default function Footer() {
  return (
    <>
      {isMobile && <CopyrightMobile />}
      {!isMobile && <Copyright />}
    </>
  );
}
